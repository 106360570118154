/*
 * Mariva CSS - RED theme
 */
/**
 * Mariva Fonts
 *
 * Generated with the help of: https://google-webfonts-helper.herokuapp.com/
 */
/* open-sans-300 - latin_latin-ext */
@import url("https://fonts.googleapis.com/css?family=Raleway");
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local("Open Sans Light"), local("OpenSans-Light"), url("../fonts/open-sans-v13-latin_latin-ext-300.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-300.woff") format("woff"); }
/* open-sans-300italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 300;
  src: local("Open Sans Light Italic"), local("OpenSansLight-Italic"), url("../fonts/open-sans-v13-latin_latin-ext-300italic.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-300italic.woff") format("woff"); }
/* open-sans-regular - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local("Open Sans"), local("OpenSans"), url("../fonts/open-sans-v13-latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-regular.woff") format("woff"); }
/* open-sans-italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  src: local("Open Sans Italic"), local("OpenSans-Italic"), url("../fonts/open-sans-v13-latin_latin-ext-italic.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-italic.woff") format("woff"); }
/* open-sans-600 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local("Open Sans Semibold"), local("OpenSans-Semibold"), url("../fonts/open-sans-v13-latin_latin-ext-600.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-600.woff") format("woff"); }
/* open-sans-600italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 600;
  src: local("Open Sans Semibold Italic"), local("OpenSans-SemiboldItalic"), url("../fonts/open-sans-v13-latin_latin-ext-600italic.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-600italic.woff") format("woff"); }
/* open-sans-700 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local("Open Sans Bold"), local("OpenSans-Bold"), url("../fonts/open-sans-v13-latin_latin-ext-700.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-700.woff") format("woff"); }
/* open-sans-700italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 700;
  src: local("Open Sans Bold Italic"), local("OpenSans-BoldItalic"), url("../fonts/open-sans-v13-latin_latin-ext-700italic.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-700italic.woff") format("woff"); }
/* open-sans-800 - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 800;
  src: local("Open Sans Extrabold"), local("OpenSans-Extrabold"), url("../fonts/open-sans-v13-latin_latin-ext-800.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-800.woff") format("woff"); }
/* open-sans-800italic - latin_latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 800;
  src: local("Open Sans Extrabold Italic"), local("OpenSans-ExtraboldItalic"), url("../fonts/open-sans-v13-latin_latin-ext-800italic.woff2") format("woff2"), url("../fonts/open-sans-v13-latin_latin-ext-800italic.woff") format("woff"); }
/* prompt-300 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 300;
  src: local("Prompt Light"), local("Prompt-Light"), url("../fonts/prompt-v1-latin-ext_latin-300.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-300.woff") format("woff"); }
/* prompt-300italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 300;
  src: local("Prompt Light Italic"), local("Prompt-LightItalic"), url("../fonts/prompt-v1-latin-ext_latin-300italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-300italic.woff") format("woff"); }
/* prompt-regular - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 400;
  src: local("Prompt"), local("Prompt-Regular"), url("../fonts/prompt-v1-latin-ext_latin-regular.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-regular.woff") format("woff"); }
/* prompt-italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 400;
  src: local("Prompt Italic"), local("Prompt-Italic"), url("../fonts/prompt-v1-latin-ext_latin-italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-italic.woff") format("woff"); }
/* prompt-500 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 500;
  src: local("Prompt Medium"), local("Prompt-Medium"), url("../fonts/prompt-v1-latin-ext_latin-500.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-500.woff") format("woff"); }
/* prompt-500italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 500;
  src: local("Prompt Medium Italic"), local("Prompt-MediumItalic"), url("../fonts/prompt-v1-latin-ext_latin-500italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-500italic.woff") format("woff"); }
/* prompt-600 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 600;
  src: local("Prompt SemiBold"), local("Prompt-SemiBold"), url("../fonts/prompt-v1-latin-ext_latin-600.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-600.woff") format("woff"); }
/* prompt-600italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 600;
  src: local("Prompt SemiBold Italic"), local("Prompt-SemiBoldItalic"), url("../fonts/prompt-v1-latin-ext_latin-600italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-600italic.woff") format("woff"); }
/* prompt-700 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 700;
  src: local("Prompt Bold"), local("Prompt-Bold"), url("../fonts/prompt-v1-latin-ext_latin-700.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-700.woff") format("woff"); }
/* prompt-700italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 700;
  src: local("Prompt Bold Italic"), local("Prompt-BoldItalic"), url("../fonts/prompt-v1-latin-ext_latin-700italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-700italic.woff") format("woff"); }
/* prompt-800 - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: normal;
  font-weight: 800;
  src: local("Prompt ExtraBold"), local("Prompt-ExtraBold"), url("../fonts/prompt-v1-latin-ext_latin-800.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-800.woff") format("woff"); }
/* prompt-800italic - latin-ext_latin */
@font-face {
  font-family: 'Prompt';
  font-style: italic;
  font-weight: 800;
  src: local("Prompt ExtraBold Italic"), local("Prompt-ExtraBoldItalic"), url("../fonts/prompt-v1-latin-ext_latin-800italic.woff2") format("woff2"), url("../fonts/prompt-v1-latin-ext_latin-800italic.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  src: local("Lato Black"), local("Lato-Black"), url("../fonts/lato/lato-black-webfont.woff2") format("woff2"), url("../fonts/lato/lato-black-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 500;
  src: local("Lato Black Italic"), local("Lato-BlackItalic"), url("../fonts/lato/lato-blackitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-blackitalic-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  src: local("Lato Bold"), local("Lato-Bold"), url("../fonts/lato/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato/lato-bold-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: bold;
  src: local("Lato Bold Italic"), local("Lato-BoldItalic"), url("../fonts/lato/lato-bolditalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-bolditalic-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 200;
  src: local("Lato Hairline"), local("Lato-Hariline"), url("../fonts/lato/lato-hairline-webfont.woff2") format("woff2"), url("../fonts/lato/lato-hairline-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 200;
  src: local("Lato Hairline Italic"), local("Lato-HairlineItalic"), url("../fonts/lato/lato-hairlineitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-hairlineitalic-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: normal;
  src: local("Lato Regular"), local("Lato-Regular"), url("../fonts/lato/lato-regular-webfont.woff2") format("woff2"), url("../fonts/lato/lato-regular-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: normal;
  src: local("Lato Italic"), local("Lato-Italic"), url("../fonts/lato/lato-italic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-italic-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local("Lato Light"), local("Lato-Light"), url("../fonts/lato/lato-light-webfont.woff2") format("woff2"), url("../fonts/lato/lato-light-webfont.woff") format("woff"); }
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: local("Lato Light Italic"), local("Lato-LightItalic"), url("../fonts/lato/lato-lightitalic-webfont.woff2") format("woff2"), url("../fonts/lato/lato-lightitalic-webfont.woff") format("woff"); }
/* Empty container markers.
----------------------------------------------------------------------------- */
.oc-container {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  overflow: hidden;
  white-space: normal;
  border-radius: 10px;
  -webkit-border-radius: 10px; }
  .oc-container .oc-container-head {
    font-size: 22px;
    font-weight: 400;
    margin: 5px; }
  .oc-container .oc-container-text {
    margin: 5px;
    font-size: 14px;
    color: #7f8691;
    line-height: 1.2; }

.oc-label {
  display: inline;
  font-size: 12px;
  font-weight: 400;
  position: relative;
  top: -5px;
  padding: 2px 5px;
  margin-left: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  white-space: nowrap;
  color: #ffffff; }

.oc-container-area {
  background-color: rgba(255, 201, 210, 0.75); }

.oc-container-segment {
  background-color: rgba(255, 227, 231, 0.75); }

.oc-container-grid {
  background-color: rgba(255, 218, 199, 0.75); }

.oc-container-row {
  background-color: rgba(255, 229, 191, 0.75); }

.oc-container-element {
  background-color: rgba(232, 243, 248, 0.75); }

.oc-container-special {
  background-color: rgba(239, 250, 180, 0.75); }

.oc-label-developer {
  background-color: #b31b34; }

.oc-label-editor {
  background-color: #ffa826; }

.oc-label-author {
  background-color: #144382; }

.oc-label-detail {
  background-color: #1bb39a; }

.oc-label-detailonly {
  background-color: #1bb39a; }

.oc-label-special {
  background-color: #ff264a; }

.oc-modelinfo {
  border: 2px solid #a8adb4;
  padding: 4px;
  margin-bottom: 10px; }
  .oc-modelinfo .alert {
    margin: 0 0 10px 0; }

/* Basic CSS selectors that can define the default look of various elements
-----------------------------------------------------------------------------*/
* {
  border-radius: 0; }

body {
  font-family: "Lato", sans-serif;
  font-size: 100%;
  color: #555;
  font-size: 13px;
  line-height: 1.5;
  background-color: #e2e3e6; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #555;
  margin-top: 5px;
  text-shadow: none; }

h1, .h1 {
  font-size: 28px;
  line-height: 35px; }

h2, .h2 {
  font-size: 24px;
  line-height: 30px; }

h3, .h3 {
  font-size: 20px;
  line-height: 25px; }

h4, .h4 {
  font-size: 18px;
  line-height: 23px; }

h5, .h5 {
  font-size: 18px;
  line-height: 23px; }

h6, .h6 {
  font-size: 18px;
  line-height: 23px; }

/* Headline with underline effect */
.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e2e3e6; }

.headline h2,
.headline h3,
.headline h4 {
  display: inline-block;
  margin: 0 0 -2px 0;
  padding-bottom: 5px;
  border-bottom: 2px solid #b31b34; }

a, a:active {
  color: #b31b34;
  outline: 0 !important; }
a:focus, a:hover {
  color: #ff264a;
  outline: 0 !important;
  -webkit-transition: all 0.25s;
  transition: all 0.25s; }

a, a:focus {
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

button,
button:hover {
  outline: 0 !important; }

hr {
  margin: 30px 0px; }

/* Footer */
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #7f8691;
  color: #010101; }
  .footer p {
    color: #010101; }
  .footer div,
  .footer h1, .footer h2, .footer h3, .footer h4 {
    color: #e2e3e6; }
  .footer.light {
    background: #e2e3e6;
    color: #7f8691; }
    .footer.light p, .footer.light div,
    .footer.light h1, .footer.light h2, .footer.light h3, .footer.light h4 {
      color: #5a6069; }

/**
 * Variables for more variation in animation curves.
 * Values taken from: http://http://easings.net/
 **/
/**
 * Shadow animation effect.
 * HTML structure:
 * 
 * <div class="ap-shadow-animation">      <-- Effect trigger box
 *     <div classs="animated-box">        <-- Animated box
 *         ...
 *     </div>
 *     Other content
 * </div>
 * 
 * This effect does two animations at once.
 * The effect trigger box gets the shadow, and the animated box is shrunk.
 **/
.ap-shadow-animation {
  -webkit-transition: box-shadow 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition: box-shadow 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  .ap-shadow-animation:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3); }
  .ap-shadow-animation:hover.animated-box .text-box, .ap-shadow-animation:hover .animated-box .text-box {
    -webkit-transform: scale(0.925);
    transform: scale(0.925); }
  .ap-shadow-animation.animated-box .text-box,
  .ap-shadow-animation .animated-box .text-box {
    box-shadow: none;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 5; }

/**
 * Shadow inset animation effect ('sunken shadow').
 * HTML structure:
 * 
 * <div class="ap-inset-animation">      <-- Effect trigger box
 *     <div classs="animated-box">       <-- Animated box
 *         ...
 *     </div>
 *     Other content
 * </div>
 * 
 * This effect does two animations at once.
 * The effect trigger box gets the inset shadow, and the animated box is shrunk.
 **/
.ap-inset-animation {
  -webkit-transition: box-shadow 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53);
  transition: box-shadow 0.35s cubic-bezier(0.55, 0.085, 0.68, 0.53); }
  .ap-inset-animation:hover {
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3) inset; }
  .ap-inset-animation:hover .animated-box {
    -webkit-transform: scale(0.925);
    transform: scale(0.925); }
  .ap-inset-animation .animated-box {
    box-shadow: none;
    -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    z-index: 5; }

/**
 * Raise box effect.
 * HTML structure:
 * 
 * <div class="ap-raise-animation">      <-- Effect trigger box
 *     <div classs="animated-box">       <-- Animated box
 *         ...
 *     </div>
 *     Other content
 * </div>
 **/
.ap-raise-animation:hover.animated-box, .ap-raise-animation:hover .animated-box {
  -webkit-transform: scale(1.075);
  transform: scale(1.075);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3); }
.ap-raise-animation:hover .text-box {
  -webkit-transform: scale(0.93);
  transform: scale(0.93); }
.ap-raise-animation .text-box {
  border: 0 solid #fff;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
.ap-raise-animation.animated-box,
.ap-raise-animation .animated-box {
  box-shadow: none;
  -webkit-transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 5; }

/**
 * Button animation effect.
 * HTML structure:
 * 
 * <div class="ap-button-animation">      <-- Effect trigger box
 *     <div classs="button-box">          <-- Place marker box
 *         <a href="..."></a>             <-- Animated link button
 *     </div>
 *     Other content
 * </div>
 **/
.ap-button-animation:hover .button-box a {
  right: 10px; }
.ap-button-animation .button-box a {
  bottom: 10px;
  position: absolute;
  display: inline-block;
  right: -10px;
  -webkit-transition: right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
.ap-button-animation .button-box {
  position: relative; }

/**
 * Ken Burns animation effect.
 * HTML structure:
 * 
 * <div class="ap-kenburns-animation">    <-- Effect trigger box
 *     <div classs="image-outer-box">     <-- Efect limitation box
 *         <div class="image-inner-box">  <-- Animated box, should be directly in limitation box
 *             ...
 *         </div>
 *     </div>
 *     Other content
 * </div>
 **/
.ap-kenburns-animation:hover .image-inner-box {
  -webkit-transform: scale(1.25) rotate(2deg);
  transform: scale(1.25) rotate(2deg); }
.ap-kenburns-animation .image-inner-box {
  -webkit-transition: -webkit-transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: transform 1s cubic-bezier(0.25, 0.46, 0.45, 0.94); }
.ap-kenburns-animation.image-outer-box,
.ap-kenburns-animation .image-outer-box {
  overflow: hidden; }

/**
 * Spinner animation.
 * Displays a "currently loading" type spinner symbol.
 * Used e.g. by the list and the image gallery.
 */
@keyframes spinner {
  0% {
    transform: rotate(0deg); }
  50% {
    transform: rotate(180deg); }
  100% {
    transform: rotate(360deg); } }
.spinner {
  width: 30px;
  height: auto;
  font-size: 30px;
  position: absolute;
  transform: translateY(-50%);
  z-index: 1000;
  left: 50%; }
  .spinner, a .spinner {
    color: #fff; }
  .spinner i {
    animation: spinner 700ms infinite;
    animation-timing-function: steps(4, end); }

.spinnerInnerBox {
  position: relative;
  left: -150%;
  background-color: black;
  width: 300%;
  padding: 100%;
  border-radius: 12px;
  line-height: 0;
  opacity: 0.65; }

.spinnerBottom {
  bottom: 80px; }

/**
 * Image gallery image enter animation. 
 */
.comein {
  transform: translateY(120px);
  animation: come-in 0.8s ease forwards; }
  .comein:nth-child(even) {
    transform: translateY(150px);
    animation-duration: 1.0s; }

@keyframes come-in {
  to {
    transform: translateY(0); } }
/**
 * Zoom overlay effect used by image gallery. 
 */
.zoom:hover .zoom-overlay .zoom-icon {
  opacity: 1;
  transform: scale(1); }

.zoom-overlay {
  cursor: pointer;
  position: relative;
  display: block;
  height: 100%;
  width: 100%;
  transition: all 0.1s ease-in-out 0s; }

.zoom-overlay .zoom-icon {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.4s ease-in-out 0s;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5); }
  .zoom-overlay .zoom-icon:before {
    background: transparent; }
  .zoom-overlay .zoom-icon:after {
    background: transparent; }

.zoom-overlay .zoom-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 30px;
  color: #fff; }

/* Extend Bootstrap forms, adjustment corners and colors */
.form-control {
  box-shadow: none;
  border-radius: 0; }

.input-group-addon {
  border-radius: 0;
  color: #555;
  background: #fff; }

/* Extend Bootstrap alert, removes the round corners. */
.alert {
  border-radius: 0; }

/* Extend Bootstrap label, style used in the Release notes. */
span.label {
  font-size: 11px;
  font-weight: 400;
  padding: 4px 7px;
  border-radius: 0; }

/* Font Awesome Icon Styles */
i.icon-detail {
  color: #b31b34;
  width: 35px;
  height: 35px;
  font-size: 20px;
  line-height: 35px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;
  border: solid 1px #b31b34; }

i.icon-box {
  width: 60px;
  height: 60px;
  font-size: 32px;
  line-height: 60px;
  margin-bottom: 10px; }

.btn {
  padding: 8px 16px;
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 0;
  text-align: center;
  box-shadow: none;
  border: none;
  border-radius: 0;
  text-transform: none;
  margin: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  background-color: #b31b34;
  color: #fff !important;
  border-color: #ff264a; }
  .btn:hover, .btn:focus, .btn:active, .btn.active, .open .dropdown-toggle .btn {
    color: #fff !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  .btn.disabled, .btn[disabled], fieldset[disabled] .btn {
    background-color: #ff264a;
    border-color: #ff264a; }

.btn:focus,
.btn:active {
  outline: none;
  box-shadow: none; }

div.ap-btn-lg a.btn,
.btn.ap-btn-lg,
.btn.btn-lg {
  padding: 10px 24px;
  font-size: 18px;
  min-width: 0; }

div.ap-btn-sm a.btn,
.btn.ap-btn-sm,
.btn.btn-sm {
  padding: 6px 12px;
  min-width: 0; }

div.ap-btn-xs a.btn,
.btn.ap-btn-xs,
.btn.btn-xs {
  font-size: 12px;
  padding: 2px 10px;
  min-width: 0; }

.btn-default {
  background-color: #b31b34;
  color: #fff !important;
  border-color: #ff264a; }
  .btn-default:hover, .btn-default:focus, .btn-default:active, .btn-default.active, .open .dropdown-toggle .btn-default {
    color: #fff !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  .btn-default.disabled, .btn-default[disabled], fieldset[disabled] .btn-default {
    background-color: #ff264a;
    border-color: #ff264a; }

div.color-white a.btn,
div.ap-btn-white a.btn,
.btn.ap-btn-white {
  background-color: #fff;
  color: #555 !important;
  border-color: #ff264a; }
  div.color-white a.btn:hover, div.color-white a.btn:focus, div.color-white a.btn:active, div.color-white a.btn.active, .open .dropdown-toggle div.color-white a.btn,
  div.ap-btn-white a.btn:hover,
  div.ap-btn-white a.btn:focus,
  div.ap-btn-white a.btn:active,
  div.ap-btn-white a.btn.active, .open .dropdown-toggle
  div.ap-btn-white a.btn,
  .btn.ap-btn-white:hover,
  .btn.ap-btn-white:focus,
  .btn.ap-btn-white:active,
  .btn.ap-btn-white.active, .open .dropdown-toggle
  .btn.ap-btn-white {
    color: #555 !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  div.color-white a.btn.disabled, div.color-white a.btn[disabled], fieldset[disabled] div.color-white a.btn,
  div.ap-btn-white a.btn.disabled,
  div.ap-btn-white a.btn[disabled], fieldset[disabled]
  div.ap-btn-white a.btn,
  .btn.ap-btn-white.disabled,
  .btn.ap-btn-white[disabled], fieldset[disabled]
  .btn.ap-btn-white {
    background-color: #ff264a;
    border-color: #ff264a; }

div.color-grey-lighter a.btn,
div.ap-btn-grey-lighter a.btn,
.btn.ap-btn-grey-lighter {
  background-color: #f7f8f8;
  color: #555 !important;
  border-color: #e2e3e6; }
  div.color-grey-lighter a.btn:hover, div.color-grey-lighter a.btn:focus, div.color-grey-lighter a.btn:active, div.color-grey-lighter a.btn.active, .open .dropdown-toggle div.color-grey-lighter a.btn,
  div.ap-btn-grey-lighter a.btn:hover,
  div.ap-btn-grey-lighter a.btn:focus,
  div.ap-btn-grey-lighter a.btn:active,
  div.ap-btn-grey-lighter a.btn.active, .open .dropdown-toggle
  div.ap-btn-grey-lighter a.btn,
  .btn.ap-btn-grey-lighter:hover,
  .btn.ap-btn-grey-lighter:focus,
  .btn.ap-btn-grey-lighter:active,
  .btn.ap-btn-grey-lighter.active, .open .dropdown-toggle
  .btn.ap-btn-grey-lighter {
    color: #555 !important;
    background-color: #e2e3e6;
    border-color: #e2e3e6; }
  div.color-grey-lighter a.btn.disabled, div.color-grey-lighter a.btn[disabled], fieldset[disabled] div.color-grey-lighter a.btn,
  div.ap-btn-grey-lighter a.btn.disabled,
  div.ap-btn-grey-lighter a.btn[disabled], fieldset[disabled]
  div.ap-btn-grey-lighter a.btn,
  .btn.ap-btn-grey-lighter.disabled,
  .btn.ap-btn-grey-lighter[disabled], fieldset[disabled]
  .btn.ap-btn-grey-lighter {
    background-color: #e2e3e6;
    border-color: #e2e3e6; }

div.color-grey-light a.btn,
div.ap-btn-grey-light a.btn,
.btn.ap-btn-grey-light {
  background-color: #e2e3e6;
  color: #555 !important;
  border-color: #f7f8f8; }
  div.color-grey-light a.btn:hover, div.color-grey-light a.btn:focus, div.color-grey-light a.btn:active, div.color-grey-light a.btn.active, .open .dropdown-toggle div.color-grey-light a.btn,
  div.ap-btn-grey-light a.btn:hover,
  div.ap-btn-grey-light a.btn:focus,
  div.ap-btn-grey-light a.btn:active,
  div.ap-btn-grey-light a.btn.active, .open .dropdown-toggle
  div.ap-btn-grey-light a.btn,
  .btn.ap-btn-grey-light:hover,
  .btn.ap-btn-grey-light:focus,
  .btn.ap-btn-grey-light:active,
  .btn.ap-btn-grey-light.active, .open .dropdown-toggle
  .btn.ap-btn-grey-light {
    color: #555 !important;
    background-color: #f7f8f8;
    border-color: #f7f8f8; }
  div.color-grey-light a.btn.disabled, div.color-grey-light a.btn[disabled], fieldset[disabled] div.color-grey-light a.btn,
  div.ap-btn-grey-light a.btn.disabled,
  div.ap-btn-grey-light a.btn[disabled], fieldset[disabled]
  div.ap-btn-grey-light a.btn,
  .btn.ap-btn-grey-light.disabled,
  .btn.ap-btn-grey-light[disabled], fieldset[disabled]
  .btn.ap-btn-grey-light {
    background-color: #f7f8f8;
    border-color: #f7f8f8; }

div.color-grey a.btn,
div.ap-btn-grey a.btn,
.btn.ap-btn-grey {
  background-color: #a8adb4;
  color: #fff !important;
  border-color: #e2e3e6; }
  div.color-grey a.btn:hover, div.color-grey a.btn:focus, div.color-grey a.btn:active, div.color-grey a.btn.active, .open .dropdown-toggle div.color-grey a.btn,
  div.ap-btn-grey a.btn:hover,
  div.ap-btn-grey a.btn:focus,
  div.ap-btn-grey a.btn:active,
  div.ap-btn-grey a.btn.active, .open .dropdown-toggle
  div.ap-btn-grey a.btn,
  .btn.ap-btn-grey:hover,
  .btn.ap-btn-grey:focus,
  .btn.ap-btn-grey:active,
  .btn.ap-btn-grey.active, .open .dropdown-toggle
  .btn.ap-btn-grey {
    color: #fff !important;
    background-color: #e2e3e6;
    border-color: #e2e3e6; }
  div.color-grey a.btn.disabled, div.color-grey a.btn[disabled], fieldset[disabled] div.color-grey a.btn,
  div.ap-btn-grey a.btn.disabled,
  div.ap-btn-grey a.btn[disabled], fieldset[disabled]
  div.ap-btn-grey a.btn,
  .btn.ap-btn-grey.disabled,
  .btn.ap-btn-grey[disabled], fieldset[disabled]
  .btn.ap-btn-grey {
    background-color: #e2e3e6;
    border-color: #e2e3e6; }

div.color-grey-dark a.btn,
div.ap-btn-grey-dark a.btn,
.btn.ap-btn-grey-dark {
  background-color: #7f8691;
  color: #fff !important;
  border-color: #a8adb4; }
  div.color-grey-dark a.btn:hover, div.color-grey-dark a.btn:focus, div.color-grey-dark a.btn:active, div.color-grey-dark a.btn.active, .open .dropdown-toggle div.color-grey-dark a.btn,
  div.ap-btn-grey-dark a.btn:hover,
  div.ap-btn-grey-dark a.btn:focus,
  div.ap-btn-grey-dark a.btn:active,
  div.ap-btn-grey-dark a.btn.active, .open .dropdown-toggle
  div.ap-btn-grey-dark a.btn,
  .btn.ap-btn-grey-dark:hover,
  .btn.ap-btn-grey-dark:focus,
  .btn.ap-btn-grey-dark:active,
  .btn.ap-btn-grey-dark.active, .open .dropdown-toggle
  .btn.ap-btn-grey-dark {
    color: #fff !important;
    background-color: #a8adb4;
    border-color: #a8adb4; }
  div.color-grey-dark a.btn.disabled, div.color-grey-dark a.btn[disabled], fieldset[disabled] div.color-grey-dark a.btn,
  div.ap-btn-grey-dark a.btn.disabled,
  div.ap-btn-grey-dark a.btn[disabled], fieldset[disabled]
  div.ap-btn-grey-dark a.btn,
  .btn.ap-btn-grey-dark.disabled,
  .btn.ap-btn-grey-dark[disabled], fieldset[disabled]
  .btn.ap-btn-grey-dark {
    background-color: #a8adb4;
    border-color: #a8adb4; }

div.color-grey-darker a.btn,
div.ap-btn-grey-darker a.btn,
.btn.ap-btn-grey-darker {
  background-color: #5a6069;
  color: #fff !important;
  border-color: #7f8691; }
  div.color-grey-darker a.btn:hover, div.color-grey-darker a.btn:focus, div.color-grey-darker a.btn:active, div.color-grey-darker a.btn.active, .open .dropdown-toggle div.color-grey-darker a.btn,
  div.ap-btn-grey-darker a.btn:hover,
  div.ap-btn-grey-darker a.btn:focus,
  div.ap-btn-grey-darker a.btn:active,
  div.ap-btn-grey-darker a.btn.active, .open .dropdown-toggle
  div.ap-btn-grey-darker a.btn,
  .btn.ap-btn-grey-darker:hover,
  .btn.ap-btn-grey-darker:focus,
  .btn.ap-btn-grey-darker:active,
  .btn.ap-btn-grey-darker.active, .open .dropdown-toggle
  .btn.ap-btn-grey-darker {
    color: #fff !important;
    background-color: #7f8691;
    border-color: #7f8691; }
  div.color-grey-darker a.btn.disabled, div.color-grey-darker a.btn[disabled], fieldset[disabled] div.color-grey-darker a.btn,
  div.ap-btn-grey-darker a.btn.disabled,
  div.ap-btn-grey-darker a.btn[disabled], fieldset[disabled]
  div.ap-btn-grey-darker a.btn,
  .btn.ap-btn-grey-darker.disabled,
  .btn.ap-btn-grey-darker[disabled], fieldset[disabled]
  .btn.ap-btn-grey-darker {
    background-color: #7f8691;
    border-color: #7f8691; }

div.color-black a.btn,
div.ap-btn-black a.btn,
.btn.ap-btn-black {
  background-color: #000;
  color: #fff !important;
  border-color: #7f8691; }
  div.color-black a.btn:hover, div.color-black a.btn:focus, div.color-black a.btn:active, div.color-black a.btn.active, .open .dropdown-toggle div.color-black a.btn,
  div.ap-btn-black a.btn:hover,
  div.ap-btn-black a.btn:focus,
  div.ap-btn-black a.btn:active,
  div.ap-btn-black a.btn.active, .open .dropdown-toggle
  div.ap-btn-black a.btn,
  .btn.ap-btn-black:hover,
  .btn.ap-btn-black:focus,
  .btn.ap-btn-black:active,
  .btn.ap-btn-black.active, .open .dropdown-toggle
  .btn.ap-btn-black {
    color: #fff !important;
    background-color: #7f8691;
    border-color: #7f8691; }
  div.color-black a.btn.disabled, div.color-black a.btn[disabled], fieldset[disabled] div.color-black a.btn,
  div.ap-btn-black a.btn.disabled,
  div.ap-btn-black a.btn[disabled], fieldset[disabled]
  div.ap-btn-black a.btn,
  .btn.ap-btn-black.disabled,
  .btn.ap-btn-black[disabled], fieldset[disabled]
  .btn.ap-btn-black {
    background-color: #7f8691;
    border-color: #7f8691; }

div.color-blue-light a.btn,
div.ap-btn-blue-light a.btn,
.btn.ap-btn-blue-light {
  background-color: #2197db;
  color: #fff !important;
  border-color: #144382; }
  div.color-blue-light a.btn:hover, div.color-blue-light a.btn:focus, div.color-blue-light a.btn:active, div.color-blue-light a.btn.active, .open .dropdown-toggle div.color-blue-light a.btn,
  div.ap-btn-blue-light a.btn:hover,
  div.ap-btn-blue-light a.btn:focus,
  div.ap-btn-blue-light a.btn:active,
  div.ap-btn-blue-light a.btn.active, .open .dropdown-toggle
  div.ap-btn-blue-light a.btn,
  .btn.ap-btn-blue-light:hover,
  .btn.ap-btn-blue-light:focus,
  .btn.ap-btn-blue-light:active,
  .btn.ap-btn-blue-light.active, .open .dropdown-toggle
  .btn.ap-btn-blue-light {
    color: #fff !important;
    background-color: #144382;
    border-color: #144382; }
  div.color-blue-light a.btn.disabled, div.color-blue-light a.btn[disabled], fieldset[disabled] div.color-blue-light a.btn,
  div.ap-btn-blue-light a.btn.disabled,
  div.ap-btn-blue-light a.btn[disabled], fieldset[disabled]
  div.ap-btn-blue-light a.btn,
  .btn.ap-btn-blue-light.disabled,
  .btn.ap-btn-blue-light[disabled], fieldset[disabled]
  .btn.ap-btn-blue-light {
    background-color: #144382;
    border-color: #144382; }

div.color-blue a.btn,
div.ap-btn-blue a.btn,
.btn.ap-btn-blue {
  background-color: #144382;
  color: #fff !important;
  border-color: #2197db; }
  div.color-blue a.btn:hover, div.color-blue a.btn:focus, div.color-blue a.btn:active, div.color-blue a.btn.active, .open .dropdown-toggle div.color-blue a.btn,
  div.ap-btn-blue a.btn:hover,
  div.ap-btn-blue a.btn:focus,
  div.ap-btn-blue a.btn:active,
  div.ap-btn-blue a.btn.active, .open .dropdown-toggle
  div.ap-btn-blue a.btn,
  .btn.ap-btn-blue:hover,
  .btn.ap-btn-blue:focus,
  .btn.ap-btn-blue:active,
  .btn.ap-btn-blue.active, .open .dropdown-toggle
  .btn.ap-btn-blue {
    color: #fff !important;
    background-color: #2197db;
    border-color: #2197db; }
  div.color-blue a.btn.disabled, div.color-blue a.btn[disabled], fieldset[disabled] div.color-blue a.btn,
  div.ap-btn-blue a.btn.disabled,
  div.ap-btn-blue a.btn[disabled], fieldset[disabled]
  div.ap-btn-blue a.btn,
  .btn.ap-btn-blue.disabled,
  .btn.ap-btn-blue[disabled], fieldset[disabled]
  .btn.ap-btn-blue {
    background-color: #2197db;
    border-color: #2197db; }

div.color-blue-dark a.btn,
div.ap-btn-blue-dark a.btn,
.btn.ap-btn-blue-dark {
  background-color: #0d2c56;
  color: #fff !important;
  border-color: #144382; }
  div.color-blue-dark a.btn:hover, div.color-blue-dark a.btn:focus, div.color-blue-dark a.btn:active, div.color-blue-dark a.btn.active, .open .dropdown-toggle div.color-blue-dark a.btn,
  div.ap-btn-blue-dark a.btn:hover,
  div.ap-btn-blue-dark a.btn:focus,
  div.ap-btn-blue-dark a.btn:active,
  div.ap-btn-blue-dark a.btn.active, .open .dropdown-toggle
  div.ap-btn-blue-dark a.btn,
  .btn.ap-btn-blue-dark:hover,
  .btn.ap-btn-blue-dark:focus,
  .btn.ap-btn-blue-dark:active,
  .btn.ap-btn-blue-dark.active, .open .dropdown-toggle
  .btn.ap-btn-blue-dark {
    color: #fff !important;
    background-color: #144382;
    border-color: #144382; }
  div.color-blue-dark a.btn.disabled, div.color-blue-dark a.btn[disabled], fieldset[disabled] div.color-blue-dark a.btn,
  div.ap-btn-blue-dark a.btn.disabled,
  div.ap-btn-blue-dark a.btn[disabled], fieldset[disabled]
  div.ap-btn-blue-dark a.btn,
  .btn.ap-btn-blue-dark.disabled,
  .btn.ap-btn-blue-dark[disabled], fieldset[disabled]
  .btn.ap-btn-blue-dark {
    background-color: #144382;
    border-color: #144382; }

div.color-blue-darker a.btn,
div.ap-btn-blue-darker a.btn,
.btn.ap-btn-blue-darker {
  background-color: #0a2140;
  color: #fff !important;
  border-color: #0d2c56; }
  div.color-blue-darker a.btn:hover, div.color-blue-darker a.btn:focus, div.color-blue-darker a.btn:active, div.color-blue-darker a.btn.active, .open .dropdown-toggle div.color-blue-darker a.btn,
  div.ap-btn-blue-darker a.btn:hover,
  div.ap-btn-blue-darker a.btn:focus,
  div.ap-btn-blue-darker a.btn:active,
  div.ap-btn-blue-darker a.btn.active, .open .dropdown-toggle
  div.ap-btn-blue-darker a.btn,
  .btn.ap-btn-blue-darker:hover,
  .btn.ap-btn-blue-darker:focus,
  .btn.ap-btn-blue-darker:active,
  .btn.ap-btn-blue-darker.active, .open .dropdown-toggle
  .btn.ap-btn-blue-darker {
    color: #fff !important;
    background-color: #0d2c56;
    border-color: #0d2c56; }
  div.color-blue-darker a.btn.disabled, div.color-blue-darker a.btn[disabled], fieldset[disabled] div.color-blue-darker a.btn,
  div.ap-btn-blue-darker a.btn.disabled,
  div.ap-btn-blue-darker a.btn[disabled], fieldset[disabled]
  div.ap-btn-blue-darker a.btn,
  .btn.ap-btn-blue-darker.disabled,
  .btn.ap-btn-blue-darker[disabled], fieldset[disabled]
  .btn.ap-btn-blue-darker {
    background-color: #0d2c56;
    border-color: #0d2c56; }

div.color-cyan a.btn,
div.ap-btn-cyan a.btn,
.btn.ap-btn-cyan {
  background-color: #1bb39a;
  color: #fff !important;
  border-color: #187b8e; }
  div.color-cyan a.btn:hover, div.color-cyan a.btn:focus, div.color-cyan a.btn:active, div.color-cyan a.btn.active, .open .dropdown-toggle div.color-cyan a.btn,
  div.ap-btn-cyan a.btn:hover,
  div.ap-btn-cyan a.btn:focus,
  div.ap-btn-cyan a.btn:active,
  div.ap-btn-cyan a.btn.active, .open .dropdown-toggle
  div.ap-btn-cyan a.btn,
  .btn.ap-btn-cyan:hover,
  .btn.ap-btn-cyan:focus,
  .btn.ap-btn-cyan:active,
  .btn.ap-btn-cyan.active, .open .dropdown-toggle
  .btn.ap-btn-cyan {
    color: #fff !important;
    background-color: #187b8e;
    border-color: #187b8e; }
  div.color-cyan a.btn.disabled, div.color-cyan a.btn[disabled], fieldset[disabled] div.color-cyan a.btn,
  div.ap-btn-cyan a.btn.disabled,
  div.ap-btn-cyan a.btn[disabled], fieldset[disabled]
  div.ap-btn-cyan a.btn,
  .btn.ap-btn-cyan.disabled,
  .btn.ap-btn-cyan[disabled], fieldset[disabled]
  .btn.ap-btn-cyan {
    background-color: #187b8e;
    border-color: #187b8e; }

div.color-red-light a.btn,
div.ap-btn-red-light a.btn,
.btn.ap-btn-red-light {
  background-color: #ff264a;
  color: #fff !important;
  border-color: #b31b34; }
  div.color-red-light a.btn:hover, div.color-red-light a.btn:focus, div.color-red-light a.btn:active, div.color-red-light a.btn.active, .open .dropdown-toggle div.color-red-light a.btn,
  div.ap-btn-red-light a.btn:hover,
  div.ap-btn-red-light a.btn:focus,
  div.ap-btn-red-light a.btn:active,
  div.ap-btn-red-light a.btn.active, .open .dropdown-toggle
  div.ap-btn-red-light a.btn,
  .btn.ap-btn-red-light:hover,
  .btn.ap-btn-red-light:focus,
  .btn.ap-btn-red-light:active,
  .btn.ap-btn-red-light.active, .open .dropdown-toggle
  .btn.ap-btn-red-light {
    color: #fff !important;
    background-color: #b31b34;
    border-color: #b31b34; }
  div.color-red-light a.btn.disabled, div.color-red-light a.btn[disabled], fieldset[disabled] div.color-red-light a.btn,
  div.ap-btn-red-light a.btn.disabled,
  div.ap-btn-red-light a.btn[disabled], fieldset[disabled]
  div.ap-btn-red-light a.btn,
  .btn.ap-btn-red-light.disabled,
  .btn.ap-btn-red-light[disabled], fieldset[disabled]
  .btn.ap-btn-red-light {
    background-color: #b31b34;
    border-color: #b31b34; }

div.color-red a.btn,
div.ap-btn-red a.btn,
.btn.ap-btn-red {
  background-color: #b31b34;
  color: #fff !important;
  border-color: #ff264a; }
  div.color-red a.btn:hover, div.color-red a.btn:focus, div.color-red a.btn:active, div.color-red a.btn.active, .open .dropdown-toggle div.color-red a.btn,
  div.ap-btn-red a.btn:hover,
  div.ap-btn-red a.btn:focus,
  div.ap-btn-red a.btn:active,
  div.ap-btn-red a.btn.active, .open .dropdown-toggle
  div.ap-btn-red a.btn,
  .btn.ap-btn-red:hover,
  .btn.ap-btn-red:focus,
  .btn.ap-btn-red:active,
  .btn.ap-btn-red.active, .open .dropdown-toggle
  .btn.ap-btn-red {
    color: #fff !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  div.color-red a.btn.disabled, div.color-red a.btn[disabled], fieldset[disabled] div.color-red a.btn,
  div.ap-btn-red a.btn.disabled,
  div.ap-btn-red a.btn[disabled], fieldset[disabled]
  div.ap-btn-red a.btn,
  .btn.ap-btn-red.disabled,
  .btn.ap-btn-red[disabled], fieldset[disabled]
  .btn.ap-btn-red {
    background-color: #ff264a;
    border-color: #ff264a; }

div.color-red-dark a.btn,
div.ap-btn-red-dark a.btn,
.btn.ap-btn-red-dark {
  background-color: #8b1528;
  color: #fff !important;
  border-color: #b31b34; }
  div.color-red-dark a.btn:hover, div.color-red-dark a.btn:focus, div.color-red-dark a.btn:active, div.color-red-dark a.btn.active, .open .dropdown-toggle div.color-red-dark a.btn,
  div.ap-btn-red-dark a.btn:hover,
  div.ap-btn-red-dark a.btn:focus,
  div.ap-btn-red-dark a.btn:active,
  div.ap-btn-red-dark a.btn.active, .open .dropdown-toggle
  div.ap-btn-red-dark a.btn,
  .btn.ap-btn-red-dark:hover,
  .btn.ap-btn-red-dark:focus,
  .btn.ap-btn-red-dark:active,
  .btn.ap-btn-red-dark.active, .open .dropdown-toggle
  .btn.ap-btn-red-dark {
    color: #fff !important;
    background-color: #b31b34;
    border-color: #b31b34; }
  div.color-red-dark a.btn.disabled, div.color-red-dark a.btn[disabled], fieldset[disabled] div.color-red-dark a.btn,
  div.ap-btn-red-dark a.btn.disabled,
  div.ap-btn-red-dark a.btn[disabled], fieldset[disabled]
  div.ap-btn-red-dark a.btn,
  .btn.ap-btn-red-dark.disabled,
  .btn.ap-btn-red-dark[disabled], fieldset[disabled]
  .btn.ap-btn-red-dark {
    background-color: #b31b34;
    border-color: #b31b34; }

div.color-red-darker a.btn,
div.ap-btn-red-darker a.btn,
.btn.ap-btn-red-darker {
  background-color: #560d19;
  color: #fff !important;
  border-color: #8b1528; }
  div.color-red-darker a.btn:hover, div.color-red-darker a.btn:focus, div.color-red-darker a.btn:active, div.color-red-darker a.btn.active, .open .dropdown-toggle div.color-red-darker a.btn,
  div.ap-btn-red-darker a.btn:hover,
  div.ap-btn-red-darker a.btn:focus,
  div.ap-btn-red-darker a.btn:active,
  div.ap-btn-red-darker a.btn.active, .open .dropdown-toggle
  div.ap-btn-red-darker a.btn,
  .btn.ap-btn-red-darker:hover,
  .btn.ap-btn-red-darker:focus,
  .btn.ap-btn-red-darker:active,
  .btn.ap-btn-red-darker.active, .open .dropdown-toggle
  .btn.ap-btn-red-darker {
    color: #fff !important;
    background-color: #8b1528;
    border-color: #8b1528; }
  div.color-red-darker a.btn.disabled, div.color-red-darker a.btn[disabled], fieldset[disabled] div.color-red-darker a.btn,
  div.ap-btn-red-darker a.btn.disabled,
  div.ap-btn-red-darker a.btn[disabled], fieldset[disabled]
  div.ap-btn-red-darker a.btn,
  .btn.ap-btn-red-darker.disabled,
  .btn.ap-btn-red-darker[disabled], fieldset[disabled]
  .btn.ap-btn-red-darker {
    background-color: #8b1528;
    border-color: #8b1528; }

div.color-orange-light a.btn,
div.ap-btn-orange-light a.btn,
.btn.ap-btn-orange-light {
  background-color: #ffcc26;
  color: #555 !important;
  border-color: #ffa826; }
  div.color-orange-light a.btn:hover, div.color-orange-light a.btn:focus, div.color-orange-light a.btn:active, div.color-orange-light a.btn.active, .open .dropdown-toggle div.color-orange-light a.btn,
  div.ap-btn-orange-light a.btn:hover,
  div.ap-btn-orange-light a.btn:focus,
  div.ap-btn-orange-light a.btn:active,
  div.ap-btn-orange-light a.btn.active, .open .dropdown-toggle
  div.ap-btn-orange-light a.btn,
  .btn.ap-btn-orange-light:hover,
  .btn.ap-btn-orange-light:focus,
  .btn.ap-btn-orange-light:active,
  .btn.ap-btn-orange-light.active, .open .dropdown-toggle
  .btn.ap-btn-orange-light {
    color: #555 !important;
    background-color: #ffa826;
    border-color: #ffa826; }
  div.color-orange-light a.btn.disabled, div.color-orange-light a.btn[disabled], fieldset[disabled] div.color-orange-light a.btn,
  div.ap-btn-orange-light a.btn.disabled,
  div.ap-btn-orange-light a.btn[disabled], fieldset[disabled]
  div.ap-btn-orange-light a.btn,
  .btn.ap-btn-orange-light.disabled,
  .btn.ap-btn-orange-light[disabled], fieldset[disabled]
  .btn.ap-btn-orange-light {
    background-color: #ffa826;
    border-color: #ffa826; }

div.color-orange a.btn,
div.ap-btn-orange a.btn,
.btn.ap-btn-orange {
  background-color: #ffa826;
  color: #fff !important;
  border-color: #ffcc26; }
  div.color-orange a.btn:hover, div.color-orange a.btn:focus, div.color-orange a.btn:active, div.color-orange a.btn.active, .open .dropdown-toggle div.color-orange a.btn,
  div.ap-btn-orange a.btn:hover,
  div.ap-btn-orange a.btn:focus,
  div.ap-btn-orange a.btn:active,
  div.ap-btn-orange a.btn.active, .open .dropdown-toggle
  div.ap-btn-orange a.btn,
  .btn.ap-btn-orange:hover,
  .btn.ap-btn-orange:focus,
  .btn.ap-btn-orange:active,
  .btn.ap-btn-orange.active, .open .dropdown-toggle
  .btn.ap-btn-orange {
    color: #fff !important;
    background-color: #ffcc26;
    border-color: #ffcc26; }
  div.color-orange a.btn.disabled, div.color-orange a.btn[disabled], fieldset[disabled] div.color-orange a.btn,
  div.ap-btn-orange a.btn.disabled,
  div.ap-btn-orange a.btn[disabled], fieldset[disabled]
  div.ap-btn-orange a.btn,
  .btn.ap-btn-orange.disabled,
  .btn.ap-btn-orange[disabled], fieldset[disabled]
  .btn.ap-btn-orange {
    background-color: #ffcc26;
    border-color: #ffcc26; }

div.color-orange-dark a.btn,
div.ap-btn-orange-dark a.btn,
.btn.ap-btn-orange-dark {
  background-color: #fc9700;
  color: #fff !important;
  border-color: #ffa826; }
  div.color-orange-dark a.btn:hover, div.color-orange-dark a.btn:focus, div.color-orange-dark a.btn:active, div.color-orange-dark a.btn.active, .open .dropdown-toggle div.color-orange-dark a.btn,
  div.ap-btn-orange-dark a.btn:hover,
  div.ap-btn-orange-dark a.btn:focus,
  div.ap-btn-orange-dark a.btn:active,
  div.ap-btn-orange-dark a.btn.active, .open .dropdown-toggle
  div.ap-btn-orange-dark a.btn,
  .btn.ap-btn-orange-dark:hover,
  .btn.ap-btn-orange-dark:focus,
  .btn.ap-btn-orange-dark:active,
  .btn.ap-btn-orange-dark.active, .open .dropdown-toggle
  .btn.ap-btn-orange-dark {
    color: #fff !important;
    background-color: #ffa826;
    border-color: #ffa826; }
  div.color-orange-dark a.btn.disabled, div.color-orange-dark a.btn[disabled], fieldset[disabled] div.color-orange-dark a.btn,
  div.ap-btn-orange-dark a.btn.disabled,
  div.ap-btn-orange-dark a.btn[disabled], fieldset[disabled]
  div.ap-btn-orange-dark a.btn,
  .btn.ap-btn-orange-dark.disabled,
  .btn.ap-btn-orange-dark[disabled], fieldset[disabled]
  .btn.ap-btn-orange-dark {
    background-color: #ffa826;
    border-color: #ffa826; }

div.color-orange-darker a.btn,
div.ap-btn-orange-darker a.btn,
.btn.ap-btn-orange-darker {
  background-color: #d37f00;
  color: #fff !important;
  border-color: #fc9700; }
  div.color-orange-darker a.btn:hover, div.color-orange-darker a.btn:focus, div.color-orange-darker a.btn:active, div.color-orange-darker a.btn.active, .open .dropdown-toggle div.color-orange-darker a.btn,
  div.ap-btn-orange-darker a.btn:hover,
  div.ap-btn-orange-darker a.btn:focus,
  div.ap-btn-orange-darker a.btn:active,
  div.ap-btn-orange-darker a.btn.active, .open .dropdown-toggle
  div.ap-btn-orange-darker a.btn,
  .btn.ap-btn-orange-darker:hover,
  .btn.ap-btn-orange-darker:focus,
  .btn.ap-btn-orange-darker:active,
  .btn.ap-btn-orange-darker.active, .open .dropdown-toggle
  .btn.ap-btn-orange-darker {
    color: #fff !important;
    background-color: #fc9700;
    border-color: #fc9700; }
  div.color-orange-darker a.btn.disabled, div.color-orange-darker a.btn[disabled], fieldset[disabled] div.color-orange-darker a.btn,
  div.ap-btn-orange-darker a.btn.disabled,
  div.ap-btn-orange-darker a.btn[disabled], fieldset[disabled]
  div.ap-btn-orange-darker a.btn,
  .btn.ap-btn-orange-darker.disabled,
  .btn.ap-btn-orange-darker[disabled], fieldset[disabled]
  .btn.ap-btn-orange-darker {
    background-color: #fc9700;
    border-color: #fc9700; }

div.color-blue-cyan a.btn,
div.ap-btn-blue-cyan a.btn,
.btn.ap-btn-blue-cyan {
  background-color: #187b8e;
  color: #fff !important;
  border-color: #1bb39a; }
  div.color-blue-cyan a.btn:hover, div.color-blue-cyan a.btn:focus, div.color-blue-cyan a.btn:active, div.color-blue-cyan a.btn.active, .open .dropdown-toggle div.color-blue-cyan a.btn,
  div.ap-btn-blue-cyan a.btn:hover,
  div.ap-btn-blue-cyan a.btn:focus,
  div.ap-btn-blue-cyan a.btn:active,
  div.ap-btn-blue-cyan a.btn.active, .open .dropdown-toggle
  div.ap-btn-blue-cyan a.btn,
  .btn.ap-btn-blue-cyan:hover,
  .btn.ap-btn-blue-cyan:focus,
  .btn.ap-btn-blue-cyan:active,
  .btn.ap-btn-blue-cyan.active, .open .dropdown-toggle
  .btn.ap-btn-blue-cyan {
    color: #fff !important;
    background-color: #1bb39a;
    border-color: #1bb39a; }
  div.color-blue-cyan a.btn.disabled, div.color-blue-cyan a.btn[disabled], fieldset[disabled] div.color-blue-cyan a.btn,
  div.ap-btn-blue-cyan a.btn.disabled,
  div.ap-btn-blue-cyan a.btn[disabled], fieldset[disabled]
  div.ap-btn-blue-cyan a.btn,
  .btn.ap-btn-blue-cyan.disabled,
  .btn.ap-btn-blue-cyan[disabled], fieldset[disabled]
  .btn.ap-btn-blue-cyan {
    background-color: #1bb39a;
    border-color: #1bb39a; }

.dropdown-white > .active > a, .dropdown-white > .active > a:hover, .dropdown-white > .active > a:focus {
  color: #555;
  text-decoration: none;
  background-color: #fff;
  outline: 0; }

.dropdown-grey-lighter > .active > a, .dropdown-grey-lighter > .active > a:hover, .dropdown-grey-lighter > .active > a:focus {
  color: #555;
  text-decoration: none;
  background-color: #f7f8f8;
  outline: 0; }

.dropdown-grey-light > .active > a, .dropdown-grey-light > .active > a:hover, .dropdown-grey-light > .active > a:focus {
  color: #555;
  text-decoration: none;
  background-color: #e2e3e6;
  outline: 0; }

.dropdown-grey > .active > a, .dropdown-grey > .active > a:hover, .dropdown-grey > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #a8adb4;
  outline: 0; }

.dropdown-grey-dark > .active > a, .dropdown-grey-dark > .active > a:hover, .dropdown-grey-dark > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #7f8691;
  outline: 0; }

.dropdown-grey-darker > .active > a, .dropdown-grey-darker > .active > a:hover, .dropdown-grey-darker > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #5a6069;
  outline: 0; }

.dropdown-black > .active > a, .dropdown-black > .active > a:hover, .dropdown-black > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #000;
  outline: 0; }

.dropdown-blue-light > .active > a, .dropdown-blue-light > .active > a:hover, .dropdown-blue-light > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #2197db;
  outline: 0; }

.dropdown-blue > .active > a, .dropdown-blue > .active > a:hover, .dropdown-blue > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #144382;
  outline: 0; }

.dropdown-blue-dark > .active > a, .dropdown-blue-dark > .active > a:hover, .dropdown-blue-dark > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0d2c56;
  outline: 0; }

.dropdown-blue-darker > .active > a, .dropdown-blue-darker > .active > a:hover, .dropdown-blue-darker > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #0a2140;
  outline: 0; }

.dropdown-cyan > .active > a, .dropdown-cyan > .active > a:hover, .dropdown-cyan > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #1bb39a;
  outline: 0; }

.dropdown-red-light > .active > a, .dropdown-red-light > .active > a:hover, .dropdown-red-light > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ff264a;
  outline: 0; }

.dropdown-red > .active > a, .dropdown-red > .active > a:hover, .dropdown-red > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #b31b34;
  outline: 0; }

.dropdown-red-dark > .active > a, .dropdown-red-dark > .active > a:hover, .dropdown-red-dark > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #8b1528;
  outline: 0; }

.dropdown-red-darker > .active > a, .dropdown-red-darker > .active > a:hover, .dropdown-red-darker > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #560d19;
  outline: 0; }

.dropdown-orange-light > .active > a, .dropdown-orange-light > .active > a:hover, .dropdown-orange-light > .active > a:focus {
  color: #555;
  text-decoration: none;
  background-color: #ffcc26;
  outline: 0; }

.dropdown-orange > .active > a, .dropdown-orange > .active > a:hover, .dropdown-orange > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #ffa826;
  outline: 0; }

.dropdown-orange-dark > .active > a, .dropdown-orange-dark > .active > a:hover, .dropdown-orange-dark > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #fc9700;
  outline: 0; }

.dropdown-orange-darker > .active > a, .dropdown-orange-darker > .active > a:hover, .dropdown-orange-darker > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #d37f00;
  outline: 0; }

.dropdown-blue-cyan > .active > a, .dropdown-blue-cyan > .active > a:hover, .dropdown-blue-cyan > .active > a:focus {
  color: #fff;
  text-decoration: none;
  background-color: #187b8e;
  outline: 0; }

section.btn-group .btn-group:not(:last-child) {
  margin-right: 10px; }

.btn-group-white .btn {
  background-color: #fff;
  color: #555 !important;
  border-color: #ff264a; }
  .btn-group-white .btn:hover, .btn-group-white .btn:focus, .btn-group-white .btn:active, .btn-group-white .btn.active, .open .dropdown-toggle .btn-group-white .btn {
    color: #555 !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  .btn-group-white .btn.disabled, .btn-group-white .btn[disabled], fieldset[disabled] .btn-group-white .btn {
    background-color: #ff264a;
    border-color: #ff264a; }

.btn-group-select-white .btn-group .dropdown-menu > .active > a, .btn-group-select-white .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-white .btn-group .dropdown-menu > .active > a:focus {
  background-color: #fff; }

.btn-group-grey-lighter .btn {
  background-color: #f7f8f8;
  color: #555 !important;
  border-color: #e2e3e6; }
  .btn-group-grey-lighter .btn:hover, .btn-group-grey-lighter .btn:focus, .btn-group-grey-lighter .btn:active, .btn-group-grey-lighter .btn.active, .open .dropdown-toggle .btn-group-grey-lighter .btn {
    color: #555 !important;
    background-color: #e2e3e6;
    border-color: #e2e3e6; }
  .btn-group-grey-lighter .btn.disabled, .btn-group-grey-lighter .btn[disabled], fieldset[disabled] .btn-group-grey-lighter .btn {
    background-color: #e2e3e6;
    border-color: #e2e3e6; }

.btn-group-select-grey-lighter .btn-group .dropdown-menu > .active > a, .btn-group-select-grey-lighter .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-grey-lighter .btn-group .dropdown-menu > .active > a:focus {
  background-color: #f7f8f8; }

.btn-group-grey-light .btn {
  background-color: #e2e3e6;
  color: #555 !important;
  border-color: #f7f8f8; }
  .btn-group-grey-light .btn:hover, .btn-group-grey-light .btn:focus, .btn-group-grey-light .btn:active, .btn-group-grey-light .btn.active, .open .dropdown-toggle .btn-group-grey-light .btn {
    color: #555 !important;
    background-color: #f7f8f8;
    border-color: #f7f8f8; }
  .btn-group-grey-light .btn.disabled, .btn-group-grey-light .btn[disabled], fieldset[disabled] .btn-group-grey-light .btn {
    background-color: #f7f8f8;
    border-color: #f7f8f8; }

.btn-group-select-grey-light .btn-group .dropdown-menu > .active > a, .btn-group-select-grey-light .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-grey-light .btn-group .dropdown-menu > .active > a:focus {
  background-color: #e2e3e6; }

.btn-group-grey .btn {
  background-color: #a8adb4;
  color: #fff !important;
  border-color: #e2e3e6; }
  .btn-group-grey .btn:hover, .btn-group-grey .btn:focus, .btn-group-grey .btn:active, .btn-group-grey .btn.active, .open .dropdown-toggle .btn-group-grey .btn {
    color: #fff !important;
    background-color: #e2e3e6;
    border-color: #e2e3e6; }
  .btn-group-grey .btn.disabled, .btn-group-grey .btn[disabled], fieldset[disabled] .btn-group-grey .btn {
    background-color: #e2e3e6;
    border-color: #e2e3e6; }

.btn-group-select-grey .btn-group .dropdown-menu > .active > a, .btn-group-select-grey .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-grey .btn-group .dropdown-menu > .active > a:focus {
  background-color: #a8adb4; }

.btn-group-grey-dark .btn {
  background-color: #7f8691;
  color: #fff !important;
  border-color: #a8adb4; }
  .btn-group-grey-dark .btn:hover, .btn-group-grey-dark .btn:focus, .btn-group-grey-dark .btn:active, .btn-group-grey-dark .btn.active, .open .dropdown-toggle .btn-group-grey-dark .btn {
    color: #fff !important;
    background-color: #a8adb4;
    border-color: #a8adb4; }
  .btn-group-grey-dark .btn.disabled, .btn-group-grey-dark .btn[disabled], fieldset[disabled] .btn-group-grey-dark .btn {
    background-color: #a8adb4;
    border-color: #a8adb4; }

.btn-group-select-grey-dark .btn-group .dropdown-menu > .active > a, .btn-group-select-grey-dark .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-grey-dark .btn-group .dropdown-menu > .active > a:focus {
  background-color: #7f8691; }

.btn-group-grey-darker .btn {
  background-color: #5a6069;
  color: #fff !important;
  border-color: #7f8691; }
  .btn-group-grey-darker .btn:hover, .btn-group-grey-darker .btn:focus, .btn-group-grey-darker .btn:active, .btn-group-grey-darker .btn.active, .open .dropdown-toggle .btn-group-grey-darker .btn {
    color: #fff !important;
    background-color: #7f8691;
    border-color: #7f8691; }
  .btn-group-grey-darker .btn.disabled, .btn-group-grey-darker .btn[disabled], fieldset[disabled] .btn-group-grey-darker .btn {
    background-color: #7f8691;
    border-color: #7f8691; }

.btn-group-select-grey-darker .btn-group .dropdown-menu > .active > a, .btn-group-select-grey-darker .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-grey-darker .btn-group .dropdown-menu > .active > a:focus {
  background-color: #5a6069; }

.btn-group-black .btn {
  background-color: #000;
  color: #fff !important;
  border-color: #7f8691; }
  .btn-group-black .btn:hover, .btn-group-black .btn:focus, .btn-group-black .btn:active, .btn-group-black .btn.active, .open .dropdown-toggle .btn-group-black .btn {
    color: #fff !important;
    background-color: #7f8691;
    border-color: #7f8691; }
  .btn-group-black .btn.disabled, .btn-group-black .btn[disabled], fieldset[disabled] .btn-group-black .btn {
    background-color: #7f8691;
    border-color: #7f8691; }

.btn-group-select-black .btn-group .dropdown-menu > .active > a, .btn-group-select-black .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-black .btn-group .dropdown-menu > .active > a:focus {
  background-color: #000; }

.btn-group-blue-light .btn {
  background-color: #2197db;
  color: #fff !important;
  border-color: #144382; }
  .btn-group-blue-light .btn:hover, .btn-group-blue-light .btn:focus, .btn-group-blue-light .btn:active, .btn-group-blue-light .btn.active, .open .dropdown-toggle .btn-group-blue-light .btn {
    color: #fff !important;
    background-color: #144382;
    border-color: #144382; }
  .btn-group-blue-light .btn.disabled, .btn-group-blue-light .btn[disabled], fieldset[disabled] .btn-group-blue-light .btn {
    background-color: #144382;
    border-color: #144382; }

.btn-group-select-blue-light .btn-group .dropdown-menu > .active > a, .btn-group-select-blue-light .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-blue-light .btn-group .dropdown-menu > .active > a:focus {
  background-color: #2197db; }

.btn-group-blue .btn {
  background-color: #144382;
  color: #fff !important;
  border-color: #2197db; }
  .btn-group-blue .btn:hover, .btn-group-blue .btn:focus, .btn-group-blue .btn:active, .btn-group-blue .btn.active, .open .dropdown-toggle .btn-group-blue .btn {
    color: #fff !important;
    background-color: #2197db;
    border-color: #2197db; }
  .btn-group-blue .btn.disabled, .btn-group-blue .btn[disabled], fieldset[disabled] .btn-group-blue .btn {
    background-color: #2197db;
    border-color: #2197db; }

.btn-group-select-blue .btn-group .dropdown-menu > .active > a, .btn-group-select-blue .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-blue .btn-group .dropdown-menu > .active > a:focus {
  background-color: #144382; }

.btn-group-blue-dark .btn {
  background-color: #0d2c56;
  color: #fff !important;
  border-color: #144382; }
  .btn-group-blue-dark .btn:hover, .btn-group-blue-dark .btn:focus, .btn-group-blue-dark .btn:active, .btn-group-blue-dark .btn.active, .open .dropdown-toggle .btn-group-blue-dark .btn {
    color: #fff !important;
    background-color: #144382;
    border-color: #144382; }
  .btn-group-blue-dark .btn.disabled, .btn-group-blue-dark .btn[disabled], fieldset[disabled] .btn-group-blue-dark .btn {
    background-color: #144382;
    border-color: #144382; }

.btn-group-select-blue-dark .btn-group .dropdown-menu > .active > a, .btn-group-select-blue-dark .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-blue-dark .btn-group .dropdown-menu > .active > a:focus {
  background-color: #0d2c56; }

.btn-group-blue-darker .btn {
  background-color: #0a2140;
  color: #fff !important;
  border-color: #0d2c56; }
  .btn-group-blue-darker .btn:hover, .btn-group-blue-darker .btn:focus, .btn-group-blue-darker .btn:active, .btn-group-blue-darker .btn.active, .open .dropdown-toggle .btn-group-blue-darker .btn {
    color: #fff !important;
    background-color: #0d2c56;
    border-color: #0d2c56; }
  .btn-group-blue-darker .btn.disabled, .btn-group-blue-darker .btn[disabled], fieldset[disabled] .btn-group-blue-darker .btn {
    background-color: #0d2c56;
    border-color: #0d2c56; }

.btn-group-select-blue-darker .btn-group .dropdown-menu > .active > a, .btn-group-select-blue-darker .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-blue-darker .btn-group .dropdown-menu > .active > a:focus {
  background-color: #0a2140; }

.btn-group-cyan .btn {
  background-color: #1bb39a;
  color: #fff !important;
  border-color: #187b8e; }
  .btn-group-cyan .btn:hover, .btn-group-cyan .btn:focus, .btn-group-cyan .btn:active, .btn-group-cyan .btn.active, .open .dropdown-toggle .btn-group-cyan .btn {
    color: #fff !important;
    background-color: #187b8e;
    border-color: #187b8e; }
  .btn-group-cyan .btn.disabled, .btn-group-cyan .btn[disabled], fieldset[disabled] .btn-group-cyan .btn {
    background-color: #187b8e;
    border-color: #187b8e; }

.btn-group-select-cyan .btn-group .dropdown-menu > .active > a, .btn-group-select-cyan .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-cyan .btn-group .dropdown-menu > .active > a:focus {
  background-color: #1bb39a; }

.btn-group-red-light .btn {
  background-color: #ff264a;
  color: #fff !important;
  border-color: #b31b34; }
  .btn-group-red-light .btn:hover, .btn-group-red-light .btn:focus, .btn-group-red-light .btn:active, .btn-group-red-light .btn.active, .open .dropdown-toggle .btn-group-red-light .btn {
    color: #fff !important;
    background-color: #b31b34;
    border-color: #b31b34; }
  .btn-group-red-light .btn.disabled, .btn-group-red-light .btn[disabled], fieldset[disabled] .btn-group-red-light .btn {
    background-color: #b31b34;
    border-color: #b31b34; }

.btn-group-select-red-light .btn-group .dropdown-menu > .active > a, .btn-group-select-red-light .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-red-light .btn-group .dropdown-menu > .active > a:focus {
  background-color: #ff264a; }

.btn-group-red .btn {
  background-color: #b31b34;
  color: #fff !important;
  border-color: #ff264a; }
  .btn-group-red .btn:hover, .btn-group-red .btn:focus, .btn-group-red .btn:active, .btn-group-red .btn.active, .open .dropdown-toggle .btn-group-red .btn {
    color: #fff !important;
    background-color: #ff264a;
    border-color: #ff264a; }
  .btn-group-red .btn.disabled, .btn-group-red .btn[disabled], fieldset[disabled] .btn-group-red .btn {
    background-color: #ff264a;
    border-color: #ff264a; }

.btn-group-select-red .btn-group .dropdown-menu > .active > a, .btn-group-select-red .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-red .btn-group .dropdown-menu > .active > a:focus {
  background-color: #b31b34; }

.btn-group-red-dark .btn {
  background-color: #8b1528;
  color: #fff !important;
  border-color: #b31b34; }
  .btn-group-red-dark .btn:hover, .btn-group-red-dark .btn:focus, .btn-group-red-dark .btn:active, .btn-group-red-dark .btn.active, .open .dropdown-toggle .btn-group-red-dark .btn {
    color: #fff !important;
    background-color: #b31b34;
    border-color: #b31b34; }
  .btn-group-red-dark .btn.disabled, .btn-group-red-dark .btn[disabled], fieldset[disabled] .btn-group-red-dark .btn {
    background-color: #b31b34;
    border-color: #b31b34; }

.btn-group-select-red-dark .btn-group .dropdown-menu > .active > a, .btn-group-select-red-dark .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-red-dark .btn-group .dropdown-menu > .active > a:focus {
  background-color: #8b1528; }

.btn-group-red-darker .btn {
  background-color: #560d19;
  color: #fff !important;
  border-color: #8b1528; }
  .btn-group-red-darker .btn:hover, .btn-group-red-darker .btn:focus, .btn-group-red-darker .btn:active, .btn-group-red-darker .btn.active, .open .dropdown-toggle .btn-group-red-darker .btn {
    color: #fff !important;
    background-color: #8b1528;
    border-color: #8b1528; }
  .btn-group-red-darker .btn.disabled, .btn-group-red-darker .btn[disabled], fieldset[disabled] .btn-group-red-darker .btn {
    background-color: #8b1528;
    border-color: #8b1528; }

.btn-group-select-red-darker .btn-group .dropdown-menu > .active > a, .btn-group-select-red-darker .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-red-darker .btn-group .dropdown-menu > .active > a:focus {
  background-color: #560d19; }

.btn-group-orange-light .btn {
  background-color: #ffcc26;
  color: #555 !important;
  border-color: #ffa826; }
  .btn-group-orange-light .btn:hover, .btn-group-orange-light .btn:focus, .btn-group-orange-light .btn:active, .btn-group-orange-light .btn.active, .open .dropdown-toggle .btn-group-orange-light .btn {
    color: #555 !important;
    background-color: #ffa826;
    border-color: #ffa826; }
  .btn-group-orange-light .btn.disabled, .btn-group-orange-light .btn[disabled], fieldset[disabled] .btn-group-orange-light .btn {
    background-color: #ffa826;
    border-color: #ffa826; }

.btn-group-select-orange-light .btn-group .dropdown-menu > .active > a, .btn-group-select-orange-light .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-orange-light .btn-group .dropdown-menu > .active > a:focus {
  background-color: #ffcc26; }

.btn-group-orange .btn {
  background-color: #ffa826;
  color: #fff !important;
  border-color: #ffcc26; }
  .btn-group-orange .btn:hover, .btn-group-orange .btn:focus, .btn-group-orange .btn:active, .btn-group-orange .btn.active, .open .dropdown-toggle .btn-group-orange .btn {
    color: #fff !important;
    background-color: #ffcc26;
    border-color: #ffcc26; }
  .btn-group-orange .btn.disabled, .btn-group-orange .btn[disabled], fieldset[disabled] .btn-group-orange .btn {
    background-color: #ffcc26;
    border-color: #ffcc26; }

.btn-group-select-orange .btn-group .dropdown-menu > .active > a, .btn-group-select-orange .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-orange .btn-group .dropdown-menu > .active > a:focus {
  background-color: #ffa826; }

.btn-group-orange-dark .btn {
  background-color: #fc9700;
  color: #fff !important;
  border-color: #ffa826; }
  .btn-group-orange-dark .btn:hover, .btn-group-orange-dark .btn:focus, .btn-group-orange-dark .btn:active, .btn-group-orange-dark .btn.active, .open .dropdown-toggle .btn-group-orange-dark .btn {
    color: #fff !important;
    background-color: #ffa826;
    border-color: #ffa826; }
  .btn-group-orange-dark .btn.disabled, .btn-group-orange-dark .btn[disabled], fieldset[disabled] .btn-group-orange-dark .btn {
    background-color: #ffa826;
    border-color: #ffa826; }

.btn-group-select-orange-dark .btn-group .dropdown-menu > .active > a, .btn-group-select-orange-dark .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-orange-dark .btn-group .dropdown-menu > .active > a:focus {
  background-color: #fc9700; }

.btn-group-orange-darker .btn {
  background-color: #d37f00;
  color: #fff !important;
  border-color: #fc9700; }
  .btn-group-orange-darker .btn:hover, .btn-group-orange-darker .btn:focus, .btn-group-orange-darker .btn:active, .btn-group-orange-darker .btn.active, .open .dropdown-toggle .btn-group-orange-darker .btn {
    color: #fff !important;
    background-color: #fc9700;
    border-color: #fc9700; }
  .btn-group-orange-darker .btn.disabled, .btn-group-orange-darker .btn[disabled], fieldset[disabled] .btn-group-orange-darker .btn {
    background-color: #fc9700;
    border-color: #fc9700; }

.btn-group-select-orange-darker .btn-group .dropdown-menu > .active > a, .btn-group-select-orange-darker .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-orange-darker .btn-group .dropdown-menu > .active > a:focus {
  background-color: #d37f00; }

.btn-group-blue-cyan .btn {
  background-color: #187b8e;
  color: #fff !important;
  border-color: #1bb39a; }
  .btn-group-blue-cyan .btn:hover, .btn-group-blue-cyan .btn:focus, .btn-group-blue-cyan .btn:active, .btn-group-blue-cyan .btn.active, .open .dropdown-toggle .btn-group-blue-cyan .btn {
    color: #fff !important;
    background-color: #1bb39a;
    border-color: #1bb39a; }
  .btn-group-blue-cyan .btn.disabled, .btn-group-blue-cyan .btn[disabled], fieldset[disabled] .btn-group-blue-cyan .btn {
    background-color: #1bb39a;
    border-color: #1bb39a; }

.btn-group-select-blue-cyan .btn-group .dropdown-menu > .active > a, .btn-group-select-blue-cyan .btn-group .dropdown-menu > .active > a:hover, .btn-group-select-blue-cyan .btn-group .dropdown-menu > .active > a:focus {
  background-color: #187b8e; }

.contact-body {
  margin-top: 60px;
  margin-bottom: 2%; }

.contact-title .ap-plain h1 {
  color: black;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 50px;
  line-height: 45px; }
  @media (max-width: 768px) {
    .contact-title .ap-plain h1 {
      font-size: 50px; } }
  .contact-title .ap-plain h1 strong {
    font-weight: 400;
    letter-spacing: 1px; }

.contact-info p {
  font-size: 16px; }
@media (max-width: 768px) {
  .contact-info {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px; } }

.contact-dir strong {
  color: black; }
.contact-dir p {
  font-size: 16px; }
@media (min-width: 768px) {
  .contact-dir a {
    pointer-events: none;
    cursor: default; } }
@media (max-width: 768px) {
  .contact-dir {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px; } }

.contact-email p {
  margin-left: 30px; }
.contact-email.email p::before {
  content: '';
  background-image: url("../img/mail.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  display: inline;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  margin-top: 5px; }
  @media (max-width: 768px) {
    .contact-email.email p::before {
      left: 35px; } }
.contact-email.phone p::before {
  content: '';
  background-image: url("../img/phone.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  display: inline;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 15px;
  margin-top: 5px;
  margin-top: 0; }
  @media (max-width: 768px) {
    .contact-email.phone p::before {
      left: 35px; } }
@media (max-width: 768px) {
  .contact-email {
    padding-left: 20px;
    padding-right: 20px; } }

.footer img {
  margin-top: -15px; }

.map-height {
  width: 100%; }
  @media (min-width: 768px) {
    .map-height {
      /* map height + footer height + margin*/
      height: 350px; } }

.for-map {
  margin-left: 0;
  margin-right: 0; }

#map {
  height: 300px;
  width: 100%; }
  @media (min-width: 768px) {
    #map {
      position: fixed !important;
      bottom: 35px; } }
  @media (max-width: 768px) {
    #map {
      height: 250px;
      margin-top: 20px; } }

.ap-linksequence ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }

.ap-linksequence ul.ap-bullets {
  list-style-type: disc;
  padding: inherit; }

.ap-linksequence-boxed li a {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  border: 1px solid #e2e3e6;
  text-decoration: none; }

.ap-linksequence li a span {
  margin-right: 8px; }

.ap-linksequence-hf {
  width: 100%;
  min-height: 25px; }
  .ap-linksequence-hf.linksasblock .links {
    padding: 8px 0 0 0; }
  .ap-linksequence-hf .links ul {
    margin: 0;
    padding-left: 0; }
    .ap-linksequence-hf .links ul li {
      display: inline;
      list-style: outside none none;
      padding-bottom: 15px;
      position: relative; }
    .ap-linksequence-hf .links ul li.divider {
      border-right: 1px solid #a8adb4;
      font-family: Tahoma;
      font-size: 8px;
      margin: 0 9px 0 5px;
      padding: 0;
      position: relative;
      top: -1px; }
    .ap-linksequence-hf .links ul li > a,
    .ap-linksequence-hf .links ul li > a:hover {
      font-size: xx-small;
      text-transform: capitalize;
      color: #010101;
      text-decoration: underline; }
  .ap-linksequence-hf ul.languages {
    top: 25px;
    left: -5px;
    display: none;
    padding: 4px 0;
    padding-left: 0;
    list-style: none;
    min-width: 100px;
    position: absolute;
    background: #e2e3e6;
    z-index: 101; }
    .ap-linksequence-hf ul.languages:after {
      top: -4px;
      width: 0;
      height: 0;
      left: 8px;
      content: " ";
      display: block;
      position: absolute;
      border-bottom: 6px solid #e2e3e6;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-left-style: inset;
      /*FF fixes*/
      border-right-style: inset;
      /*FF fixes*/ }
    .ap-linksequence-hf ul.languages li a {
      color: #5a6069;
      display: block;
      font-size: 10px;
      padding: 2px 12px;
      margin-bottom: 1px;
      text-transform: uppercase; }
    .ap-linksequence-hf ul.languages li.active a i {
      color: #7f8691;
      float: right;
      margin-top: 2px; }
    .ap-linksequence-hf ul.languages li a:hover,
    .ap-linksequence-hf ul.languages li.active a {
      background: #f7f8f8; }
    .ap-linksequence-hf ul.languages li a:hover {
      text-decoration: none;
      font-size: 10px; }
  .ap-linksequence-hf li:hover ul.languages {
    display: block; }

/* Variables
------------------------------------*/
/* The Mariva header
------------------------------------*/
.ap-header {
  z-index: 99;
  background: #fff;
  position: relative;
  /* Head background container
  ------------------------------------*/
  /* Head navbar container, the nav will be pulled over the head container / content
  ------------------------------------*/
  /* The button used to show / hide the nav on small screens
  ------------------------------------*/
  /* Responsive navbar
  ------------------------------------*/
  /* Dropdown menus
  ------------------------------------*/ }
  .ap-header .navbar-collapse {
    position: relative;
    border-top: 0; }
  @media (max-width: 991px) {
    .ap-header .navbar-header {
      float: none; }
    .ap-header .navbar-collapse {
      margin-top: 0;
      padding-left: 0;
      padding-right: 0; }
      .ap-header .navbar-collapse .container {
        padding-left: 0;
        padding-right: 0; }
      .ap-header .navbar-collapse.collapse {
        display: none !important; }
      .ap-header .navbar-collapse.collapse.in {
        display: block !important;
        overflow-y: auto !important; } }
  @media (max-width: 991px) {
    .ap-header .head-container .col-xs-12,
    .ap-header .head-container .row {
      margin-left: 0;
      margin-right: 0; }
    .ap-header .head-container .col-xs-12,
    .ap-header .head-container .container {
      padding-left: 0;
      padding-right: 0; } }
  .ap-header .head-navbar {
    border-bottom: solid 2px #e2e3e6;
    pointer-events: none; }
    @media (min-width: 992px) {
      .ap-header .head-navbar {
        margin-top: -40px; } }
  @media (min-width: 992px) {
    .ap-header .head-nopull .head-navbar {
      margin-top: 0; } }
  .ap-header .editor .head-inline,
  .ap-header .editor .head-navbar {
    border-top: solid 1px #e2e3e6;
    margin-top: 0px; }
  .ap-header .navbar-toggle {
    border: none;
    border-radius: 0;
    background: #b31b34;
    padding: 2px 8px 2px 8px;
    color: #fff;
    z-index: 100;
    position: relative;
    float: right; }
    @media (min-width: 992px) {
      .ap-header .navbar-toggle {
        display: none; } }
    @media (max-width: 991px) {
      .ap-header .navbar-toggle {
        margin: -40px 0 6px 0;
        display: block; } }
    .ap-header .navbar-toggle .fa {
      font-size: 28px; }
    @media (max-width: 496px) {
      .ap-header .navbar-toggle {
        margin: -30px 0 6px 0; }
        .ap-header .navbar-toggle .fa {
          font-size: 14px; } }
    .ap-header .navbar-toggle:hover {
      background: #144382; }
  .ap-header .navbar-nav {
    pointer-events: all; }
    .ap-header .navbar-nav > .active > a {
      color: #b31b34;
      border-bottom-color: #b31b34; }
      .ap-header .navbar-nav > .active > a:hover {
        color: #b31b34; }
    .ap-header .navbar-nav > li {
      position: static;
      transition: all 1s; }
      .ap-header .navbar-nav > li:hover > a {
        color: #b31b34;
        border-bottom-color: #b31b34; }
      .ap-header .navbar-nav > li > a {
        color: #5a6069;
        background: none;
        text-transform: uppercase;
        font-size: 15px;
        font-weight: 400;
        position: relative;
        bottom: -2px;
        border-bottom: solid 2px transparent;
        padding: 9px 20px 9px 20px; }
        .ap-header .navbar-nav > li > a:after {
          font-size: 12px;
          content: "\f107";
          position: absolute;
          top: 11px;
          right: 6px;
          display: inline-block;
          font-family: FontAwesome; }
      .ap-header .navbar-nav > li > .search {
        color: #5a6069;
        background: none;
        cursor: pointer;
        font-size: 15px;
        min-width: 35px;
        text-align: center;
        display: inline-block;
        position: relative;
        bottom: -2px;
        border-bottom: solid 2px transparent;
        padding: 11px 0 12px; }
        .ap-header .navbar-nav > li > .search:focus, .ap-header .navbar-nav > li > .search:hover {
          color: #b31b34;
          border-bottom-color: #b31b34; }
    @media (min-width: 992px) {
      .ap-header .navbar-nav {
        float: right; }
        .ap-header .navbar-nav li.dropdown:hover > .dropdown-menu {
          display: block; }
        .ap-header .navbar-nav > .dropdown .dropdown-menu {
          border-top: solid 2px #b31b34;
          border-bottom: solid 2px #b31b34; }
          .ap-header .navbar-nav > .dropdown .dropdown-menu > li:last-child > a {
            border-bottom: none; }
        .ap-header .navbar-nav .open > .dropdown-menu {
          display: none; }
        .ap-header .navbar-nav .open > a {
          color: #b31b34;
          background-color: transparent;
          border-color: transparent; }
        .ap-header .navbar-nav .open > a:hover {
          color: #b31b34; } }
    @media (max-width: 991px) {
      .ap-header .navbar-nav {
        margin: 0;
        float: none !important; }
        .ap-header .navbar-nav > .dropdown {
          padding: 0 1px 0 0; }
        .ap-header .navbar-nav > .dropdown .dropdown-menu {
          -webkit-transition: all 3s;
          transition: all 0.5s ease-in-out;
          max-height: 0;
          display: block;
          overflow: hidden; }
        .ap-header .navbar-nav > .dropdown.open .dropdown-menu {
          max-height: 5000px; }
        .ap-header .navbar-nav .open > a,
        .ap-header .navbar-nav .open > a:hover {
          color: #5a6069;
          border-color: #e2e3e6; }
        .ap-header .navbar-nav > li.dropdown.open:nth-last-child(2) > a, .ap-header .navbar-nav > li.dropdown.open:last-child > a {
          border-bottom: 1px solid #e2e3e6; }
        .ap-header .navbar-nav > li {
          float: none; }
          .ap-header .navbar-nav > li > a {
            background: #fff;
            font-size: 14px;
            padding: 9px 10px;
            bottom: 0px;
            border-bottom: solid 1px #e2e3e6; }
            .ap-header .navbar-nav > li > a:after {
              content: "\f105";
              top: 9px;
              right: 10px; }
          .ap-header .navbar-nav > li.active > a {
            color: #fff;
            background: #b31b34;
            border-color: #e2e3e6; }
            .ap-header .navbar-nav > li.active > a:hover {
              color: #b31b34;
              background: #e2e3e6;
              border-color: #e2e3e6; }
          .ap-header .navbar-nav > li.open > a:after {
            content: "\f107";
            top: 9px;
            right: 10px; }
          .ap-header .navbar-nav > li:hover > a {
            color: #b31b34;
            background: #e2e3e6;
            border-bottom: solid 1px #e2e3e6; }
          .ap-header .navbar-nav > li:nth-last-child(2) > a, .ap-header .navbar-nav > li:last-child > a {
            border-bottom: none; }
        .ap-header .navbar-nav #searchContentHeader,
        .ap-header .navbar-nav #searchButtonHeader {
          display: none; } }
    .ap-header .navbar-nav #searchContentHeader > span > button {
      padding-top: 6px;
      padding-bottom: 6px; }
    @media (min-width: 768px) {
      .ap-header .navbar-nav .form-inline .input-group .form-control {
        width: auto; } }
    .ap-header .navbar-nav .search-open {
      right: 0;
      top: 40px;
      display: none;
      padding: 14px;
      position: absolute;
      background: #fff;
      border-top: solid 2px #b31b34;
      border-bottom: solid 2px #b31b34;
      z-index: 100;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); }
      .ap-header .navbar-nav .search-open form {
        margin: 0; }
  .ap-header .dropdown-menu {
    border: none;
    padding: 0;
    border-radius: 0; }
    .ap-header .dropdown-menu > li > a {
      border-bottom: 1px solid #e2e3e6;
      background-color: #fff;
      color: #5a6069;
      padding: 5px 25px; }
      .ap-header .dropdown-menu > li > a:focus, .ap-header .dropdown-menu > li > a:hover {
        background-color: #e2e3e6;
        color: #b31b34; }
    .ap-header .dropdown-menu > li.focus > a, .ap-header .dropdown-menu > li.active > a {
      background-color: #e2e3e6;
      color: #5a6069; }
      .ap-header .dropdown-menu > li.focus > a:focus, .ap-header .dropdown-menu > li.focus > a:hover, .ap-header .dropdown-menu > li.active > a:focus, .ap-header .dropdown-menu > li.active > a:hover {
        color: #b31b34; }
    .ap-header .dropdown-menu .dropdown-submenu {
      position: relative; }
      .ap-header .dropdown-menu .dropdown-submenu > .dropdown-menu {
        top: 3px;
        left: 100%;
        margin-top: -5px;
        margin-left: 0px; }
      .ap-header .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
        display: block; }
    .ap-header .dropdown-menu .dropdown > a:after,
    .ap-header .dropdown-menu .dropdown-submenu > a:after {
      top: 8px;
      right: 9px;
      font-size: 11px;
      content: "\f105";
      position: absolute;
      font-weight: normal;
      display: inline-block;
      font-family: FontAwesome; }
    .ap-header .dropdown-menu .dropdown > a:after {
      top: 9px;
      right: 15px;
      content: "\f107"; }
    @media (min-width: 992px) {
      .ap-header .dropdown-menu {
        min-width: 200px;
        z-index: 9999 !important;
        -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3); }
        .ap-header .dropdown-menu li a {
          font-size: 13px;
          font-weight: 400;
          padding: 6px 15px; }
        .ap-header .dropdown-menu > li a {
          border-bottom: solid 1px #e2e3e6; } }
    @media (max-width: 991px) {
      .ap-header .dropdown-menu {
        position: static;
        box-shadow: none;
        float: none;
        width: auto; }
        .ap-header .dropdown-menu .open {
          display: block; }
        .ap-header .dropdown-menu .dropdown-submenu > .dropdown-menu {
          display: block;
          margin-top: 0px;
          margin-left: 15px;
          border-left: 0;
          border-right: 0; }
        .ap-header .dropdown-menu .dropdown-submenu > a:after {
          content: "\f107"; }
        .ap-header .dropdown-menu.pull-right {
          float: none !important; } }

/* Megamenu
------------------------------------*/
#megamenu-editor .container {
  padding: 0; }
  #megamenu-editor .container .ap-header .mega-menu .container {
    width: inherit; }
#megamenu-editor .dropdown .dropdown-menu {
  max-height: none;
  display: block;
  overflow: visible; }

.mega-menu .dropdown {
  position: static; }
.mega-menu .container {
  position: relative; }
.mega-menu .dropdown-menu {
  left: auto; }
.mega-menu .dropdown-megamenu .ap-sec {
  margin-bottom: 0; }
@media (min-width: 992px) {
  .mega-menu div.dropdown-menu {
    width: 100%;
    position: absolute;
    left: 0; }
  .mega-menu .dropdown-megamenu {
    padding: 15px; } }
.mega-menu .ap-linksequence-boxed li > a {
  border: 1px solid #e2e3e6;
  border-top: none;
  background-color: #fff;
  color: #5a6069;
  padding: 5px 25px;
  margin: 0; }
  .mega-menu .ap-linksequence-boxed li > a:focus, .mega-menu .ap-linksequence-boxed li > a:hover {
    background-color: #e2e3e6;
    color: #b31b34; }
.mega-menu .ap-linksequence-boxed li:first-child > a {
  border-top: 1px solid #e2e3e6; }
@media (max-width: 992px) {
  .mega-menu .ap-linksequence-boxed li > a {
    border-right: none;
    border-left: none; } }

/* Breadcrumbs
------------------------------------*/
.breadcrumbs {
  overflow: hidden;
  padding: 10px 0 6px;
  border-bottom: solid 1px #e2e3e6;
  background: #f7f8f8; }
  .breadcrumbs h1 {
    color: #5a6069;
    font-size: 22px;
    margin-top: 8px; }
  .breadcrumbs .breadcrumb {
    top: 10px;
    padding-right: 0;
    background: none;
    position: relative; }
    .breadcrumbs .breadcrumb a {
      color: #5a6069; }
    .breadcrumbs .breadcrumb li.active,
    .breadcrumbs .breadcrumb li a:hover {
      color: #b31b34;
      text-decoration: none; }
  .breadcrumbs .badge-user {
    color: #fff;
    background: #5a6069;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    font-size: 12px;
    padding: 3px 7px;
    top: -9px;
    left: 4px;
    position: relative;
    border-radius: 4px; }
  @media (max-width: 991px) {
    .breadcrumbs .breadcrumb {
      display: none; } }
  @media (max-width: 496px) {
    .breadcrumbs h1.pull-left,
    .breadcrumbs ul.pull-right {
      width: 100%;
      text-align: center; }
    .breadcrumbs h1.pull-left {
      margin-top: 0;
      margin-bottom: 0; } }

/* "Back to top" icon on the lower right of display.
------------------------------------*/
#topcontrol {
  color: #fff;
  background: #555;
  z-index: 1500;
  width: 30px;
  height: 30px;
  font-size: 20px;
  position: relative;
  right: 14px !important;
  bottom: 11px !important;
  border-radius: 3px !important; }
  @media (max-width: 768px) {
    #topcontrol {
      bottom: 85px !important; } }

#topcontrol:hover {
  background-color: #b31b34;
  -webkit-transition: all 0.3s;
  transition: all 0.3s; }

#topcontrol:after {
  top: -2px;
  left: 8.5px;
  content: "\f106";
  position: absolute;
  text-align: center;
  font-family: FontAwesome; }

/* Sidebar navigation
------------------------------------*/
.ap-sidebar-nav {
  margin-bottom: 20px; }

.sidebar-nav {
  border: 1px solid #e2e3e6; }
  .sidebar-nav > li.active:first-child,
  .sidebar-nav > li:first-child {
    border-top: none; }
  .sidebar-nav li.active,
  .sidebar-nav li {
    background: #fff;
    border-top: 1px solid #e2e3e6;
    border-bottom: none;
    border-left: none;
    border-right: none;
    margin-bottom: 0;
    font-size: 13px;
    padding: 0; }
    .sidebar-nav li.active a,
    .sidebar-nav li a {
      color: #5a6069;
      display: block;
      padding: 6px 20px 6px 10px; }
      .sidebar-nav li.active a:focus,
      .sidebar-nav li a:focus {
        color: #5a6069; }
      .sidebar-nav li.active a:hover,
      .sidebar-nav li a:hover {
        color: #b31b34;
        background: #e2e3e6;
        text-decoration: none; }
    .sidebar-nav li.active ul,
    .sidebar-nav li ul {
      padding: 0;
      list-style: none; }
      .sidebar-nav li.active ul li.active,
      .sidebar-nav li.active ul li,
      .sidebar-nav li ul li.active,
      .sidebar-nav li ul li {
        border: none;
        border-top: 1px solid #e2e3e6; }
    .sidebar-nav li.active:first-child, .sidebar-nav li.active:last-child,
    .sidebar-nav li:first-child,
    .sidebar-nav li:last-child {
      border-radius: 0; }
    .sidebar-nav li.active > ul li.active a,
    .sidebar-nav li.active > ul li a,
    .sidebar-nav li > ul li.active a,
    .sidebar-nav li > ul li a {
      padding-left: 20px; }
    .sidebar-nav li.active > ul > li.list-group-item > ul li.active a,
    .sidebar-nav li.active > ul > li.list-group-item > ul li a,
    .sidebar-nav li > ul > li.list-group-item > ul li.active a,
    .sidebar-nav li > ul > li.list-group-item > ul li a {
      padding-left: 40px; }
    .sidebar-nav li.active > ul > li.list-group-item > ul > li.list-group-item > ul li.active a,
    .sidebar-nav li.active > ul > li.list-group-item > ul > li.list-group-item > ul li a,
    .sidebar-nav li > ul > li.list-group-item > ul > li.list-group-item > ul li.active a,
    .sidebar-nav li > ul > li.list-group-item > ul > li.list-group-item > ul li a {
      padding-left: 60px; }
  .sidebar-nav li.active:hover {
    border-color: #e2e3e6;
    background: #fff; }
  .sidebar-nav li.currentpage {
    background: #e2e3e6; }
    .sidebar-nav li.currentpage:hover {
      border-color: #e2e3e6;
      background: #e2e3e6; }
  .sidebar-nav .list-toggle:after {
    top: 7px;
    right: 10px;
    color: #5a6069;
    font-size: 14px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
    pointer-events: none; }
  .sidebar-nav .list-toggle.active:after {
    pointer-events: none;
    color: #5a6069;
    content: "\f107"; }
  @media (max-width: 767px) {
    .sidebar-nav {
      margin-left: -15px;
      margin-right: -15px;
      border-left: none;
      border-right: none; } }

/** customs **/
.navbar-space {
  margin: 10px; }

.ap-search .panel-default > .panel-heading {
  padding: 5px 15px; }

.ap-search hr {
  margin: 10px 0; }

.ap-sec,
.ap-section {
  margin-bottom: 20px; }
  .ap-sec h3.subtitle,
  .ap-section h3.subtitle {
    margin-top: 0;
    padding-top: 5px; }
    .ap-sec h3.subtitle a,
    .ap-section h3.subtitle a {
      color: #555; }
    .ap-sec h3.subtitle a:hover,
    .ap-section h3.subtitle a:hover {
      text-decoration: none;
      color: #b31b34 !important; }
  .ap-sec.ap-image-zoom h3.subtitle,
  .ap-section.ap-image-zoom h3.subtitle {
    font-family: "Lato", sans-serif;
    font-size: 13px;
    text-align: center;
    font-weight: 600; }
  .ap-sec .copyright,
  .ap-section .copyright {
    position: relative; }
    .ap-sec .copyright div,
    .ap-section .copyright div {
      padding: 2px 5px;
      font-size: 0.7em;
      background: rgba(255, 255, 255, 0.6);
      color: black;
      position: absolute;
      bottom: 0; }

.ap-iconbox {
  text-align: center;
  margin-bottom: 20px; }

.ap-img {
  margin-bottom: 20px; }
  .ap-img a {
    color: #555; }
    .ap-img a:hover, .ap-img a:active, .ap-img a:focus {
      color: #555;
      text-decoration: none; }
    .ap-img a .ap-img-desc {
      color: #b31b34; }
      .ap-img a .ap-img-desc:hover, .ap-img a .ap-img-desc:active, .ap-img a .ap-img-desc:focus {
        text-decoration: underline; }
  .ap-img .info {
    text-align: right; }
    .ap-img .info .copyright {
      font-size: 10px;
      color: #a8adb4; }
  .ap-img.ap-img-v1 .ap-img-title {
    text-align: center;
    font-weight: 700; }
  .ap-img.ap-img-v1 .ap-img-desc {
    margin-top: 5px; }

.ap-img-txt .ap-img-title {
  margin-top: 10px; }
.ap-img-txt .ap-img-desc {
  margin-top: 5px; }

a.ap-img-link {
  color: #555; }
  a.ap-img-link:hover, a.ap-img-link:active, a.ap-img-link:focus {
    color: #555;
    text-decoration: none; }
  a.ap-img-link .ap-img-desc {
    color: #b31b34; }
    a.ap-img-link .ap-img-desc:hover, a.ap-img-link .ap-img-desc:active, a.ap-img-link .ap-img-desc:focus {
      text-decoration: underline; }

.ap-tile .ap-sec,
.ap-tile .ap-section,
.ap-tile .ap-sec .ap-img,
.ap-tile .ap-section .ap-img {
  margin: 0; }

.ap-sec-imagebox {
  overflow: hidden;
  position: relative; }

.ap-sec-imagebox .ap-sec-imagebox-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 15px 10px 0;
  opacity: 0.7;
  padding: 4px 8px;
  z-index: 5; }

.ap-sec-imagebox .ap-img .info .copyright {
  position: absolute;
  bottom: 10px;
  right: 2px; }

.ap-sec-imagebox .ap-sec-imagebox-title {
  margin-right: 26px;
  text-transform: uppercase; }

.ap-sec-imagebox .ap-sec-imagebox-icon {
  position: absolute;
  top: 2px;
  right: 10px;
  background-color: #fff;
  padding: 0 3px 3px;
  border: 1px solid #a8adb4;
  box-shadow: 0 3px 5px #7f8691; }

.ap-sec-imagebox .ap-sec-imagebox-text {
  margin-top: 20px; }

.ap-sec-imagebox-title,
.ap-sec-imagebox-icon,
.ap-sec-imagebox-text,
.ap-sec-imagebox
.ap-img
.info
.copyright {
  color: #a8adb4; }

.overflow-hidden {
  overflow: hidden; }

.overflow-fix {
  position: relative;
  z-index: 10; }

/* Jumbotron headline.
----------------------------------------------------------------------------- */
.jumbotron h1, .jumbotron .h1 {
  line-height: 1.0; }

/* Sitemap.
----------------------------------------------------------------------------- */
.sitemap ul,
.sitemap li {
  margin: 0;
  padding: 0;
  list-style: none;
  padding: 5px;
  background: transparent; }
.sitemap ul > ul li {
  color: #fff;
  background: #b31b34;
  padding: 10px;
  font-weight: bold;
  font-size: 1em;
  line-height: 1; }
  .sitemap ul > ul li.parent {
    cursor: pointer; }
  .sitemap ul > ul li a {
    color: #fff;
    width: 100%;
    display: block;
    height: 100%; }
  .sitemap ul > ul li span {
    float: left; }
  .sitemap ul > ul li i {
    transform: rotate(-90deg);
    transition: transform 1s;
    float: right; }
    .sitemap ul > ul li i.open {
      transform: rotate(0);
      transition: transform 1s; }
.sitemap ul > ul > ul li {
  color: #fff;
  background: #144382;
  padding: 5px 10px;
  margin: 0;
  font-weight: normal; }
  .sitemap ul > ul > ul li a {
    color: #fff; }
  .sitemap ul > ul > ul li:hover {
    background: #b31b34;
    color: #fff; }
    .sitemap ul > ul > ul li:hover a {
      color: #fff;
      text-decoration: none; }

.sitemap-white ul > ul li {
  color: #555;
  background: #fff; }
  .sitemap-white ul > ul li a {
    color: #555; }
.sitemap-white ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-white ul > ul > ul li a {
    color: #555; }

.sitemap-grey-lighter ul > ul li {
  color: #555;
  background: #f7f8f8; }
  .sitemap-grey-lighter ul > ul li a {
    color: #555; }
.sitemap-grey-lighter ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-grey-lighter ul > ul > ul li a {
    color: #555; }

.sitemap-grey-light ul > ul li {
  color: #555;
  background: #e2e3e6; }
  .sitemap-grey-light ul > ul li a {
    color: #555; }
.sitemap-grey-light ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-grey-light ul > ul > ul li a {
    color: #555; }

.sitemap-grey ul > ul li {
  color: #fff;
  background: #a8adb4; }
  .sitemap-grey ul > ul li a {
    color: #fff; }
.sitemap-grey ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-grey ul > ul > ul li a {
    color: #555; }

.sitemap-grey-dark ul > ul li {
  color: #fff;
  background: #7f8691; }
  .sitemap-grey-dark ul > ul li a {
    color: #fff; }
.sitemap-grey-dark ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-grey-dark ul > ul > ul li a {
    color: #555; }

.sitemap-grey-darker ul > ul li {
  color: #fff;
  background: #5a6069; }
  .sitemap-grey-darker ul > ul li a {
    color: #fff; }
.sitemap-grey-darker ul > ul > ul li {
  color: #555;
  background: #dfe1e3; }
  .sitemap-grey-darker ul > ul > ul li a {
    color: #555; }

.sitemap-black ul > ul li {
  color: #fff;
  background: #000; }
  .sitemap-black ul > ul li a {
    color: #fff; }
.sitemap-black ul > ul > ul li {
  color: #fff;
  background: gray; }
  .sitemap-black ul > ul > ul li a {
    color: #fff; }

.sitemap-blue-light ul > ul li {
  color: #fff;
  background: #2197db; }
  .sitemap-blue-light ul > ul li a {
    color: #fff; }
.sitemap-blue-light ul > ul > ul li {
  color: #555;
  background: #fcfeff; }
  .sitemap-blue-light ul > ul > ul li a {
    color: #555; }

.sitemap-blue ul > ul li {
  color: #fff;
  background: #144382; }
  .sitemap-blue ul > ul li a {
    color: #fff; }
.sitemap-blue ul > ul > ul li {
  color: #555;
  background: #a4c5f1; }
  .sitemap-blue ul > ul > ul li a {
    color: #555; }

.sitemap-blue-dark ul > ul li {
  color: #fff;
  background: #0d2c56; }
  .sitemap-blue-dark ul > ul li a {
    color: #fff; }
.sitemap-blue-dark ul > ul > ul li {
  color: #555;
  background: #78a9ea; }
  .sitemap-blue-dark ul > ul > ul li a {
    color: #555; }

.sitemap-blue-darker ul > ul li {
  color: #fff;
  background: #0a2140; }
  .sitemap-blue-darker ul > ul li a {
    color: #fff; }
.sitemap-blue-darker ul > ul > ul li {
  color: #fff;
  background: #629be7; }
  .sitemap-blue-darker ul > ul > ul li a {
    color: #fff; }

.sitemap-cyan ul > ul li {
  color: #fff;
  background: #1bb39a; }
  .sitemap-cyan ul > ul li a {
    color: #fff; }
.sitemap-cyan ul > ul > ul li {
  color: #555;
  background: #d4f9f3; }
  .sitemap-cyan ul > ul > ul li a {
    color: #555; }

.sitemap-red-light ul > ul li {
  color: #fff;
  background: #ff264a; }
  .sitemap-red-light ul > ul li a {
    color: #fff; }
.sitemap-red-light ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-red-light ul > ul > ul li a {
    color: #555; }

.sitemap-red ul > ul li {
  color: #fff;
  background: #b31b34; }
  .sitemap-red ul > ul li a {
    color: #fff; }
.sitemap-red ul > ul > ul li {
  color: #555;
  background: #f9d4da; }
  .sitemap-red ul > ul > ul li a {
    color: #555; }

.sitemap-red-dark ul > ul li {
  color: #fff;
  background: #8b1528; }
  .sitemap-red-dark ul > ul li a {
    color: #fff; }
.sitemap-red-dark ul > ul > ul li {
  color: #555;
  background: #f3adb8; }
  .sitemap-red-dark ul > ul > ul li a {
    color: #555; }

.sitemap-red-darker ul > ul li {
  color: #fff;
  background: #560d19; }
  .sitemap-red-darker ul > ul li a {
    color: #fff; }
.sitemap-red-darker ul > ul > ul li {
  color: #555;
  background: #eb778a; }
  .sitemap-red-darker ul > ul > ul li a {
    color: #555; }

.sitemap-orange-light ul > ul li {
  color: #fff;
  background: #ffcc26; }
  .sitemap-orange-light ul > ul li a {
    color: #fff; }
.sitemap-orange-light ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-orange-light ul > ul > ul li a {
    color: #555; }

.sitemap-orange ul > ul li {
  color: #fff;
  background: #ffa826; }
  .sitemap-orange ul > ul li a {
    color: #fff; }
.sitemap-orange ul > ul > ul li {
  color: #555;
  background: white; }
  .sitemap-orange ul > ul > ul li a {
    color: #555; }

.sitemap-orange-dark ul > ul li {
  color: #fff;
  background: #fc9700; }
  .sitemap-orange-dark ul > ul li a {
    color: #fff; }
.sitemap-orange-dark ul > ul > ul li {
  color: #555;
  background: #fffefc; }
  .sitemap-orange-dark ul > ul > ul li a {
    color: #555; }

.sitemap-orange-darker ul > ul li {
  color: #fff;
  background: #d37f00; }
  .sitemap-orange-darker ul > ul li a {
    color: #fff; }
.sitemap-orange-darker ul > ul > ul li {
  color: #555;
  background: #ffeed3; }
  .sitemap-orange-darker ul > ul > ul li a {
    color: #555; }

.sitemap-blue-cyan ul > ul li {
  color: #fff;
  background: #187b8e; }
  .sitemap-blue-cyan ul > ul li a {
    color: #fff; }
.sitemap-blue-cyan ul > ul > ul li {
  color: #555;
  background: #b3e8f2; }
  .sitemap-blue-cyan ul > ul > ul li a {
    color: #555; }

/* Slider (Play/Pause Button, caption, copyright).
----------------------------------------------------------------------------- */
.slider-reduced {
  height: 60vh !important; }

.slider .control {
  margin: -40px 20px;
  position: relative;
  z-index: 999;
  opacity: 0;
  transition: opacity 1s; }
.slider:hover > .control {
  opacity: 1;
  transition: opacity 1s; }
@media (max-width: 768px) {
  .slider li:nth-child(1) .tp-bgimg {
    background-position-x: 59% !important; }
  .slider li:nth-child(2) .tp-bgimg {
    background-position-x: 39% !important; }
  .slider li:nth-child(4) .tp-bgimg {
    background-position-x: 36% !important; }
  .slider li:nth-child(5) .tp-bgimg {
    background-position-x: 45% !important; } }
.slider .caption {
  opacity: 0.7; }
  .slider .caption p {
    font-size: 17px;
    font-weight: 600;
    white-space: normal;
    line-height: normal; }
  .slider .caption a.btn {
    font-weight: 600;
    border-radius: 33px;
    margin-top: 90px;
    font-size: small;
    padding: 10px 23px;
    background: #0b3e9c; }
    .slider .caption a.btn span {
      text-transform: uppercase; }
    .slider .caption a.btn img {
      height: 15px !important;
      margin-left: 10px; }
  .slider .caption h2.home-slide {
    margin-top: -20px; }
  @media (max-width: 768px) {
    .slider .caption {
      left: 50px;
      padding: 5px 35px !important;
      width: 100%;
      text-align: center; }
      .slider .caption.left-zero {
        left: 0 !important;
        margin: 0;
        text-align: left; }
      .slider .caption a.btn {
        font-size: 20px;
        padding: 10px;
        width: 100%; }
        .slider .caption a.btn img {
          display: none; }
      .slider .caption h1, .slider .caption h2 {
        text-align: left;
        font-size: 30px; }
        .slider .caption h1.home-slide, .slider .caption h2.home-slide {
          font-size: 41px; }
      .slider .caption h2 {
        margin-top: -20px;
        white-space: normal; }
      .slider .caption img {
        width: 110px !important;
        height: inherit !important; } }
  @media (min-width: 768px) {
    .slider .caption {
      padding: 5px 10px !important; }
      .slider .caption h1 {
        font-weight: 300; }
      .slider .caption h2 {
        margin-top: -20px;
        font-weight: normal; }
      .slider .caption h1, .slider .caption h2 {
        font-size: 55px; } }
  @media (min-width: 1200px) {
    .slider .caption {
      margin-left: -100px !important;
      width: 45vw; } }
  .slider .caption h3 {
    font-size: 15px; }
  .slider .caption h1, .slider .caption h2, .slider .caption p {
    color: #d9d9d9; }
  .slider .caption h2, .slider .caption h3 {
    margin: 0;
    padding: 6px 0 0 0;
    line-height: 1.1;
    font-weight: 400; }
  @media (min-width: 992px) {
    .slider .caption h3 {
      font-size: 25px; } }
  .slider .caption.copyright {
    color: #fff;
    font-size: 10px !important;
    font-style: italic; }

.slider.fullwidthbanner {
  z-index: 0;
  /* prevents overlapping of timeline and main navigation */ }

.slider.revslider-initialised.tp-simpleresponsive {
  position: relative; }

.caption {
  font-family: "Open Sans", sans-serif !important; }

/* Slider additional styles.
----------------------------------------------------------------------------- */
.tp-caption.mediumthickblackbg, .tp-caption.mediumthickgreybg, .tp-caption.mediumthickwhitebg {
  font-size: 36px;
  line-height: 36px;
  font-weight: 700;
  font-family: "Open Sans";
  text-decoration: none;
  text-shadow: none;
  border-width: 0px none #fff;
  padding: 0 4px; }

.tp-caption.mediumthickblackbg {
  color: white;
  background-color: black; }

.tp-caption.mediumthickgreybg {
  color: white;
  background-color: gray; }

.tp-caption.blackbgtransparent {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 7px; }

.tp-caption.mediumthickwhitebg {
  color: black;
  background-color: white; }

/* Carousel
------------------------------------*/
.ap-carousel .carousel-caption {
  padding: 2px 10px 8px 10px;
  opacity: 0.7;
  filter: alpha(opacity=70); }

.ap-carousel .carousel-caption p {
  font-size: 15px; }
.ap-carousel .carousel-copyright {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  padding: 1px 10px;
  z-Index: 2;
  font-size: 10px;
  font-style: italic;
  text-align: center;
  line-height: 11px;
  opacity: 0.7;
  filter: alpha(opacity=70); }
  .ap-carousel .carousel-copyright span {
    opacity: 0.6;
    filter: alpha(opacity=60); }
.ap-carousel .carousel-caption {
  padding: 2px 10px 8px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7); }
  .ap-carousel .carousel-caption.carousel-caption-copyright {
    bottom: 13px; }
  .ap-carousel .carousel-caption p {
    color: #fff;
    margin-bottom: 0; }
.ap-carousel .carousel-arrow a.carousel-control {
  opacity: 1;
  font-size: 30px;
  height: inherit;
  width: inherit;
  background: none;
  text-shadow: none;
  position: inherit; }
  .ap-carousel .carousel-arrow a.carousel-control:hover {
    color: #b31b34; }
.ap-carousel .carousel-arrow a i {
  top: 50%;
  opacity: 0.6;
  background: #000;
  margin-top: -18px;
  padding: 2px 12px;
  position: absolute; }
  .ap-carousel .carousel-arrow a i:hover {
    opacity: 0.8; }
.ap-carousel .carousel-arrow a.left i {
  left: 0; }
.ap-carousel .carousel-arrow a.right i {
  right: 0; }

.carousel-caption h3, .ap-carousel .carousel-caption p {
  color: #fff;
  line-height: 1.1;
  margin: 0;
  padding: 6px 0 0 0; }

.carousel-caption h3 {
  font-size: 20px;
  color: #fff;
  line-height: 1.1; }

.slider-text {
  margin-top: 50px; }

.social-links {
  list-style: none;
  font-size: 0px;
  padding: 0px;
  margin: 10px 0px 20px; }
  .social-links li {
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 4px; }
    .social-links li a {
      width: 40px;
      height: 40px;
      display: block;
      text-align: center;
      border: none;
      font-size: 20px;
      color: #555;
      background: #a8adb4; }
  .social-links li a:hover {
    color: #fff;
    background-color: transparent; }
  .social-links li a i {
    line-height: 40px; }

.social-links li.facebook a:hover {
  background-color: #3b5998; }

.social-links li.googleplus a:hover {
  background-color: #dd4b39; }

.social-links li.youtube a:hover {
  background-color: #b31217; }

.social-links li.twitter a:hover {
  background-color: #55acee; }

.social-links li.github a:hover {
  background-color: #777777; }

.social-links li.xing a:hover {
  background-color: #126567; }

.social-links li.rss a:hover {
  background-color: #ee802f; }

.social-links li.slideshare a:hover {
  background-color: #e98325; }

.ap-tab-section {
  margin-bottom: 20px; }

.ap-tab-pane .ap-section {
  margin-bottom: 0; }

.ap-tab .nav-tabs {
  border: none;
  background: none;
  border-bottom: solid 2px #b31b34; }

.ap-tab .nav-tabs > li > a:hover,
.ap-tab .nav-tabs > .active > a,
.ap-tab .nav-tabs > .active > a:hover,
.ap-tab .nav-tabs > .active > a:focus {
  color: #fff;
  border: none;
  background: #b31b34; }

.ap-tab .nav-tabs a {
  font-size: 14px;
  padding: 5px 15px; }

.ap-tab .nav-tabs > li > a {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  color: #555;
  border: none;
  text-transform: none;
  border-radius: 0;
  transition: all .2s ease-in-out; }

.ap-tab .tab-content {
  padding: 10px 0; }

div.ap-tab-grey-lightest .ap-tab .nav-tabs {
  border-bottom: solid 2px #eee; }

div.ap-tab-grey-lightest .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-grey-lightest .ap-tab .nav-tabs > .active > a,
div.ap-tab-grey-lightest .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-grey-lightest .ap-tab .nav-tabs > .active > a:focus {
  background: #eee;
  color: #555; }

div.ap-tab-grey-light .ap-tab .nav-tabs {
  border-bottom: solid 2px #e2e3e6; }

div.ap-tab-grey-light .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-grey-light .ap-tab .nav-tabs > .active > a,
div.ap-tab-grey-light .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-grey-light .ap-tab .nav-tabs > .active > a:focus {
  background: #e2e3e6;
  color: #555; }

div.ap-tab-grey .ap-tab .nav-tabs {
  border-bottom: solid 2px #a8adb4; }

div.ap-tab-grey .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-grey .ap-tab .nav-tabs > .active > a,
div.ap-tab-grey .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-grey .ap-tab .nav-tabs > .active > a:focus {
  background: #a8adb4;
  color: #fff; }

div.ap-tab-blue .ap-tab .nav-tabs {
  border-bottom: solid 2px #144382; }

div.ap-tab-blue .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-blue .ap-tab .nav-tabs > .active > a,
div.ap-tab-blue .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-blue .ap-tab .nav-tabs > .active > a:focus {
  background: #144382;
  color: #fff; }

div.ap-tab-red .ap-tab .nav-tabs {
  border-bottom: solid 2px #b31b34; }

div.ap-tab-red .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-red .ap-tab .nav-tabs > .active > a,
div.ap-tab-red .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-red .ap-tab .nav-tabs > .active > a:focus {
  background: #b31b34;
  color: #fff; }

div.ap-tab-cyan .ap-tab .nav-tabs {
  border-bottom: solid 2px #1bb39a; }

div.ap-tab-cyan .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-cyan .ap-tab .nav-tabs > .active > a,
div.ap-tab-cyan .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-cyan .ap-tab .nav-tabs > .active > a:focus {
  background: #1bb39a;
  color: #fff; }

div.ap-tab-orange .ap-tab .nav-tabs {
  border-bottom: solid 2px #ffa826; }

div.ap-tab-orange .ap-tab .nav-tabs > li > a:hover,
div.ap-tab-orange .ap-tab .nav-tabs > .active > a,
div.ap-tab-orange .ap-tab .nav-tabs > .active > a:hover,
div.ap-tab-orange .ap-tab .nav-tabs > .active > a:focus {
  background: #ffa826;
  color: #fff; }

.ap-panel {
  margin-bottom: 20px; }
  .ap-panel.color-grey-lightest .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-grey-lightest .panel-heading h4.panel-title a:hover {
    color: #555;
    background: #f7f8f8; }
  .ap-panel.color-grey-lightest .panel-heading h4.panel-title a,
  .ap-panel.color-grey-lightest .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #555;
    background: #eee; }
  .ap-panel.color-grey-light .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-grey-light .panel-heading h4.panel-title a:hover {
    color: #555;
    background: #f7f8f8; }
  .ap-panel.color-grey-light .panel-heading h4.panel-title a,
  .ap-panel.color-grey-light .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #555;
    background: #e2e3e6; }
  .ap-panel.color-grey .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-grey .panel-heading h4.panel-title a:hover {
    color: #fff;
    background: #e2e3e6; }
  .ap-panel.color-grey .panel-heading h4.panel-title a,
  .ap-panel.color-grey .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #fff;
    background: #a8adb4; }
  .ap-panel.color-blue .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-blue .panel-heading h4.panel-title a:hover {
    color: #fff;
    background: #2197db; }
  .ap-panel.color-blue .panel-heading h4.panel-title a,
  .ap-panel.color-blue .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #fff;
    background: #144382; }
  .ap-panel.color-red .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-red .panel-heading h4.panel-title a:hover {
    color: #fff;
    background: #ff264a; }
  .ap-panel.color-red .panel-heading h4.panel-title a,
  .ap-panel.color-red .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #fff;
    background: #b31b34; }
  .ap-panel.color-cyan .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-cyan .panel-heading h4.panel-title a:hover {
    color: #fff;
    background: #187b8e; }
  .ap-panel.color-cyan .panel-heading h4.panel-title a,
  .ap-panel.color-cyan .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #fff;
    background: #1bb39a; }
  .ap-panel.color-orange .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
  .ap-panel.color-orange .panel-heading h4.panel-title a:hover {
    color: #fff;
    background: #b31b34; }
  .ap-panel.color-orange .panel-heading h4.panel-title a,
  .ap-panel.color-orange .panel-heading h4.panel-title a.accordion-toggle.collapsed {
    color: #fff;
    background: #ffa826; }

.ap-panel-body .ap-section {
  margin-bottom: 0; }

.panel-group .panel + .panel {
  margin-top: 0; }

.panel-group .panel {
  margin-bottom: 5px; }

.panel,
.panel-heading,
.panel-group .panel {
  border-radius: 0; }

.panel {
  border-color: #e2e3e6; }

.panel-heading {
  color: #fff;
  padding: 5px 15px; }

.panel-default > .panel-heading {
  padding: 0;
  color: inherit;
  border-color: #e2e3e6; }

.panel-heading h4.panel-title {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 14px;
  font-weight: normal;
  line-height: 25px;
  text-transform: none; }

.panel-heading h4.panel-title a,
.panel-heading h4.panel-title a.accordion-toggle.collapsed {
  color: #555;
  background: #f7f8f8;
  transition: all .2s ease-in-out; }

.panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
.panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #b31b34;
  text-decoration: none; }

.ap-panel-title {
  padding: 5px 15px;
  background: inherit; }

div.ap-tab-grey-lightest div.panel-heading {
  color: #555;
  background: #eee; }

div.ap-tab-grey-lightest .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-grey-lightest .panel-heading h4.panel-title a:hover {
  color: #555;
  background: #f7f8f8; }

div.ap-tab-grey-light div.panel-heading {
  color: #555;
  background: #e2e3e6; }

div.ap-tab-grey-light .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-grey-light .panel-heading h4.panel-title a:hover {
  color: #555;
  background: #f7f8f8; }

div.ap-tab-grey div.panel-heading {
  color: #fff;
  background: #a8adb4; }

div.ap-tab-grey .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-grey .panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #e2e3e6; }

div.ap-tab-blue div.panel-heading {
  color: #fff;
  background: #144382; }

div.ap-tab-blue .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-blue .panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #2197db; }

div.ap-tab-red div.panel-heading {
  color: #fff;
  background: #b31b34; }

div.ap-tab-red .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-red .panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #ff264a; }

div.ap-tab-cyan div.panel-heading {
  color: #fff;
  background: #1bb39a; }

div.ap-tab-cyan .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-cyan .panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #187b8e; }

div.ap-tab-orange div.panel-heading {
  color: #fff;
  background: #ffa826; }

div.ap-tab-orange .panel-heading h4.panel-title a.accordion-toggle.collapsed:hover,
div.ap-tab-orange .panel-heading h4.panel-title a:hover {
  color: #fff;
  background: #b31b34; }

.ap-square {
  float: left;
  position: relative;
  width: 100%;
  padding: 0 0 100% 0 !important; }

.ap-square .ap-square-section {
  position: absolute !important;
  height: 100%;
  width: 100%;
  margin: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; }

.ap-square.square-m-0 .ap-square-section {
  height: calc(100% - 0px);
  width: calc(100% - 0px);
  margin: 0px; }

.ap-square.square-m-1 .ap-square-section {
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  margin: 1px; }

.ap-square.square-m-2 .ap-square-section {
  height: calc(100% - 4px);
  width: calc(100% - 4px);
  margin: 2px; }

.ap-square.square-m-3 .ap-square-section {
  height: calc(100% - 6px);
  width: calc(100% - 6px);
  margin: 3px; }

.ap-square.square-m-4 .ap-square-section {
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  margin: 4px; }

.ap-square.square-m-5 .ap-square-section {
  height: calc(100% - 10px);
  width: calc(100% - 10px);
  margin: 5px; }

.ap-square.square-m-10 .ap-square-section {
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  margin: 10px; }

.ap-square.square-m-15 .ap-square-section {
  height: calc(100% - 30px);
  width: calc(100% - 30px);
  margin: 15px; }

.ap-square.square-m-20 .ap-square-section {
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  margin: 20px; }

.ap-square.square-m-25 .ap-square-section {
  height: calc(100% - 50px);
  width: calc(100% - 50px);
  margin: 25px; }

.ap-square.square-m-30 .ap-square-section {
  height: calc(100% - 60px);
  width: calc(100% - 60px);
  margin: 30px; }

.ap-square.square-m-40 .ap-square-section {
  height: calc(100% - 80px);
  width: calc(100% - 80px);
  margin: 40px; }

.ap-square.square-m-50 .ap-square-section {
  height: calc(100% - 100px);
  width: calc(100% - 100px);
  margin: 50px; }

.ap-square.square-m-75 .ap-square-section {
  height: calc(100% - 150px);
  width: calc(100% - 150px);
  margin: 75px; }

.ap-square.square-m-100 .ap-square-section {
  height: calc(100% - 200px);
  width: calc(100% - 200px);
  margin: 100px; }

.ap-square.square-m-500 .ap-square-section {
  height: calc(100% - 1000px);
  width: calc(100% - 1000px);
  margin: 500px; }

.ap-sq-square {
  width: 100%;
  height: 100%;
  overflow: hidden; }

.ap-sq-table {
  display: table;
  height: 100%;
  width: 100%; }

.ap-sq-cell {
  display: table-cell;
  vertical-align: middle;
  height: 100%;
  width: 100%; }

.ap-tile {
  float: left;
  position: relative;
  min-height: 1px;
  white-space: nowrap;
  width: calc(100% - 0px);
  margin: 0px; }

.fixcol, .fixcol-xs-50,
.fixcol-xs-50-rest, .fixcol-sm-50,
.fixcol-sm-50-rest, .fixcol-md-50,
.fixcol-md-50-rest, .fixcol-lg-50,
.fixcol-lg-50-rest, .fixcol-xs-100,
.fixcol-xs-100-rest, .fixcol-sm-100,
.fixcol-sm-100-rest, .fixcol-md-100,
.fixcol-md-100-rest, .fixcol-lg-100,
.fixcol-lg-100-rest, .fixcol-xs-125,
.fixcol-xs-125-rest, .fixcol-sm-125,
.fixcol-sm-125-rest, .fixcol-md-125,
.fixcol-md-125-rest, .fixcol-lg-125,
.fixcol-lg-125-rest, .fixcol-xs-150,
.fixcol-xs-150-rest, .fixcol-sm-150,
.fixcol-sm-150-rest, .fixcol-md-150,
.fixcol-md-150-rest, .fixcol-lg-150,
.fixcol-lg-150-rest, .fixcol-xs-200,
.fixcol-xs-200-rest, .fixcol-sm-200,
.fixcol-sm-200-rest, .fixcol-md-200,
.fixcol-md-200-rest, .fixcol-lg-200,
.fixcol-lg-200-rest, .fixcol-xs-250,
.fixcol-xs-250-rest, .fixcol-sm-250,
.fixcol-sm-250-rest, .fixcol-md-250,
.fixcol-md-250-rest, .fixcol-lg-250,
.fixcol-lg-250-rest {
  float: left;
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  width: 100%; }

.square-xs-12 {
  width: 100%;
  padding: 0 0 100% 0 !important; }
  .square-xs-12-h-0 o16 {
    width: 100%;
    padding: 0 0 16.6% 0 !important; }
  .square-xs-12-h-0 o25 {
    width: 100%;
    padding: 0 0 25% 0 !important; }
  .square-xs-12-h-0 o33 {
    width: 100%;
    padding: 0 0 33.2% 0 !important; }
  .square-xs-12-h-0 o5 {
    width: 100%;
    padding: 0 0 50% 0 !important; }
  .square-xs-12-h-1 o5 {
    width: 100%;
    padding: 0 0 149.9% 0 !important; }
  .square-xs-12-h-2 {
    width: 100%;
    padding: 0 0 200% 0 !important; }

.square-xs-6 {
  width: 50%;
  padding: 0 0 50% 0 !important; }
  .square-xs-6-h-0 o16 {
    width: 50%;
    padding: 0 0 8.3% 0 !important; }
  .square-xs-6-h-0 o25 {
    width: 50%;
    padding: 0 0 12.5% 0 !important; }
  .square-xs-6-h-0 o33 {
    width: 50%;
    padding: 0 0 16.6% 0 !important; }
  .square-xs-6-h-0 o5 {
    width: 50%;
    padding: 0 0 25% 0 !important; }
  .square-xs-6-h-1 o5 {
    width: 50%;
    padding: 0 0 74.95% 0 !important; }
  .square-xs-6-h-2 {
    width: 50%;
    padding: 0 0 100% 0 !important; }

.square-xs-4 {
  width: 33.33333%;
  padding: 0 0 33.33333% 0 !important; }
  .square-xs-4-h-0 o16 {
    width: 33.33333%;
    padding: 0 0 5.53333% 0 !important; }
  .square-xs-4-h-0 o25 {
    width: 33.33333%;
    padding: 0 0 8.33333% 0 !important; }
  .square-xs-4-h-0 o33 {
    width: 33.33333%;
    padding: 0 0 11.06667% 0 !important; }
  .square-xs-4-h-0 o5 {
    width: 33.33333%;
    padding: 0 0 16.66667% 0 !important; }
  .square-xs-4-h-1 o5 {
    width: 33.33333%;
    padding: 0 0 49.96667% 0 !important; }
  .square-xs-4-h-2 {
    width: 33.33333%;
    padding: 0 0 66.66667% 0 !important; }

.square-xs-3 {
  width: 25%;
  padding: 0 0 25% 0 !important; }
  .square-xs-3-h-0 o16 {
    width: 25%;
    padding: 0 0 4.15% 0 !important; }
  .square-xs-3-h-0 o25 {
    width: 25%;
    padding: 0 0 6.25% 0 !important; }
  .square-xs-3-h-0 o33 {
    width: 25%;
    padding: 0 0 8.3% 0 !important; }
  .square-xs-3-h-0 o5 {
    width: 25%;
    padding: 0 0 12.5% 0 !important; }
  .square-xs-3-h-1 o5 {
    width: 25%;
    padding: 0 0 37.475% 0 !important; }
  .square-xs-3-h-2 {
    width: 25%;
    padding: 0 0 50% 0 !important; }

.square-xs-2 o4 {
  width: 20%;
  padding: 0 0 20% 0 !important; }
  .square-xs-2 o4-h-0 o16 {
    width: 20%;
    padding: 0 0 3.32% 0 !important; }
  .square-xs-2 o4-h-0 o25 {
    width: 20%;
    padding: 0 0 5% 0 !important; }
  .square-xs-2 o4-h-0 o33 {
    width: 20%;
    padding: 0 0 6.64% 0 !important; }
  .square-xs-2 o4-h-0 o5 {
    width: 20%;
    padding: 0 0 10% 0 !important; }
  .square-xs-2 o4-h-1 o5 {
    width: 20%;
    padding: 0 0 29.98% 0 !important; }
  .square-xs-2 o4-h-2 {
    width: 20%;
    padding: 0 0 40% 0 !important; }

.square-xs-2 {
  width: 16.66667%;
  padding: 0 0 16.66667% 0 !important; }
  .square-xs-2-h-0 o16 {
    width: 16.66667%;
    padding: 0 0 2.76667% 0 !important; }
  .square-xs-2-h-0 o25 {
    width: 16.66667%;
    padding: 0 0 4.16667% 0 !important; }
  .square-xs-2-h-0 o33 {
    width: 16.66667%;
    padding: 0 0 5.53333% 0 !important; }
  .square-xs-2-h-0 o5 {
    width: 16.66667%;
    padding: 0 0 8.33333% 0 !important; }
  .square-xs-2-h-1 o5 {
    width: 16.66667%;
    padding: 0 0 24.98333% 0 !important; }
  .square-xs-2-h-2 {
    width: 16.66667%;
    padding: 0 0 33.33333% 0 !important; }

.square-xs-1 o5 {
  width: 12.5%;
  padding: 0 0 12.5% 0 !important; }
  .square-xs-1 o5-h-0 o16 {
    width: 12.5%;
    padding: 0 0 2.075% 0 !important; }
  .square-xs-1 o5-h-0 o25 {
    width: 12.5%;
    padding: 0 0 3.125% 0 !important; }
  .square-xs-1 o5-h-0 o33 {
    width: 12.5%;
    padding: 0 0 4.15% 0 !important; }
  .square-xs-1 o5-h-0 o5 {
    width: 12.5%;
    padding: 0 0 6.25% 0 !important; }
  .square-xs-1 o5-h-1 o5 {
    width: 12.5%;
    padding: 0 0 18.7375% 0 !important; }
  .square-xs-1 o5-h-2 {
    width: 12.5%;
    padding: 0 0 25% 0 !important; }

.square-xs-1 o2 {
  width: 10%;
  padding: 0 0 10% 0 !important; }
  .square-xs-1 o2-h-0 o16 {
    width: 10%;
    padding: 0 0 1.66% 0 !important; }
  .square-xs-1 o2-h-0 o25 {
    width: 10%;
    padding: 0 0 2.5% 0 !important; }
  .square-xs-1 o2-h-0 o33 {
    width: 10%;
    padding: 0 0 3.32% 0 !important; }
  .square-xs-1 o2-h-0 o5 {
    width: 10%;
    padding: 0 0 5% 0 !important; }
  .square-xs-1 o2-h-1 o5 {
    width: 10%;
    padding: 0 0 14.99% 0 !important; }
  .square-xs-1 o2-h-2 {
    width: 10%;
    padding: 0 0 20% 0 !important; }

.square-xs-1 {
  width: 8.33333%;
  padding: 0 0 8.33333% 0 !important; }
  .square-xs-1-h-0 o16 {
    width: 8.33333%;
    padding: 0 0 1.38333% 0 !important; }
  .square-xs-1-h-0 o25 {
    width: 8.33333%;
    padding: 0 0 2.08333% 0 !important; }
  .square-xs-1-h-0 o33 {
    width: 8.33333%;
    padding: 0 0 2.76667% 0 !important; }
  .square-xs-1-h-0 o5 {
    width: 8.33333%;
    padding: 0 0 4.16667% 0 !important; }
  .square-xs-1-h-1 o5 {
    width: 8.33333%;
    padding: 0 0 12.49167% 0 !important; }
  .square-xs-1-h-2 {
    width: 8.33333%;
    padding: 0 0 16.66667% 0 !important; }

.tile-xs-12 {
  width: calc(100% - 0px);
  margin: 0px 0; }

.tile-xs-6 {
  width: calc(50% - 0px);
  margin: 0px; }

.tile-xs-4 {
  width: calc(33.33333% - 0px);
  margin: 0px; }

.tile-xs-3 {
  width: calc(25% - 0px);
  margin: 0px; }

.tile-xs-2 o4 {
  width: calc(20% - 0px);
  margin: 0px; }

.tile-xs-2 {
  width: calc(16.66667% - 0px);
  margin: 0px; }

.tile-xs-1 o5 {
  width: calc(12.5% - 0px);
  margin: 0px; }

.tile-xs-1 o2 {
  width: calc(10% - 0px);
  margin: 0px; }

.tile-xs-1 {
  width: calc(8.33333% - 0px);
  margin: 0px; }

.fixcol-xs-50 {
  width: calc(50px + 2 * 15px); }

.fixcol-xs-50-rest {
  width: calc(100% - (50px + 2 * 15px)); }

.fixcol-xs-100 {
  width: calc(100px + 2 * 15px); }

.fixcol-xs-100-rest {
  width: calc(100% - (100px + 2 * 15px)); }

.fixcol-xs-125 {
  width: calc(125px + 2 * 15px); }

.fixcol-xs-125-rest {
  width: calc(100% - (125px + 2 * 15px)); }

.fixcol-xs-150 {
  width: calc(150px + 2 * 15px); }

.fixcol-xs-150-rest {
  width: calc(100% - (150px + 2 * 15px)); }

.fixcol-xs-200 {
  width: calc(200px + 2 * 15px); }

.fixcol-xs-200-rest {
  width: calc(100% - (200px + 2 * 15px)); }

.fixcol-xs-250 {
  width: calc(250px + 2 * 15px); }

.fixcol-xs-250-rest {
  width: calc(100% - (250px + 2 * 15px)); }

@media (min-width: 768px) {
  .square-sm-12 {
    width: 100%;
    padding: 0 0 100% 0 !important; }
    .square-sm-12-h-0 o16 {
      width: 100%;
      padding: 0 0 16.6% 0 !important; }
    .square-sm-12-h-0 o25 {
      width: 100%;
      padding: 0 0 25% 0 !important; }
    .square-sm-12-h-0 o33 {
      width: 100%;
      padding: 0 0 33.2% 0 !important; }
    .square-sm-12-h-0 o5 {
      width: 100%;
      padding: 0 0 50% 0 !important; }
    .square-sm-12-h-1 o5 {
      width: 100%;
      padding: 0 0 149.9% 0 !important; }
    .square-sm-12-h-2 {
      width: 100%;
      padding: 0 0 200% 0 !important; }

  .square-sm-6 {
    width: 50%;
    padding: 0 0 50% 0 !important; }
    .square-sm-6-h-0 o16 {
      width: 50%;
      padding: 0 0 8.3% 0 !important; }
    .square-sm-6-h-0 o25 {
      width: 50%;
      padding: 0 0 12.5% 0 !important; }
    .square-sm-6-h-0 o33 {
      width: 50%;
      padding: 0 0 16.6% 0 !important; }
    .square-sm-6-h-0 o5 {
      width: 50%;
      padding: 0 0 25% 0 !important; }
    .square-sm-6-h-1 o5 {
      width: 50%;
      padding: 0 0 74.95% 0 !important; }
    .square-sm-6-h-2 {
      width: 50%;
      padding: 0 0 100% 0 !important; }

  .square-sm-4 {
    width: 33.33333%;
    padding: 0 0 33.33333% 0 !important; }
    .square-sm-4-h-0 o16 {
      width: 33.33333%;
      padding: 0 0 5.53333% 0 !important; }
    .square-sm-4-h-0 o25 {
      width: 33.33333%;
      padding: 0 0 8.33333% 0 !important; }
    .square-sm-4-h-0 o33 {
      width: 33.33333%;
      padding: 0 0 11.06667% 0 !important; }
    .square-sm-4-h-0 o5 {
      width: 33.33333%;
      padding: 0 0 16.66667% 0 !important; }
    .square-sm-4-h-1 o5 {
      width: 33.33333%;
      padding: 0 0 49.96667% 0 !important; }
    .square-sm-4-h-2 {
      width: 33.33333%;
      padding: 0 0 66.66667% 0 !important; }

  .square-sm-3 {
    width: 25%;
    padding: 0 0 25% 0 !important; }
    .square-sm-3-h-0 o16 {
      width: 25%;
      padding: 0 0 4.15% 0 !important; }
    .square-sm-3-h-0 o25 {
      width: 25%;
      padding: 0 0 6.25% 0 !important; }
    .square-sm-3-h-0 o33 {
      width: 25%;
      padding: 0 0 8.3% 0 !important; }
    .square-sm-3-h-0 o5 {
      width: 25%;
      padding: 0 0 12.5% 0 !important; }
    .square-sm-3-h-1 o5 {
      width: 25%;
      padding: 0 0 37.475% 0 !important; }
    .square-sm-3-h-2 {
      width: 25%;
      padding: 0 0 50% 0 !important; }

  .square-sm-2 o4 {
    width: 20%;
    padding: 0 0 20% 0 !important; }
    .square-sm-2 o4-h-0 o16 {
      width: 20%;
      padding: 0 0 3.32% 0 !important; }
    .square-sm-2 o4-h-0 o25 {
      width: 20%;
      padding: 0 0 5% 0 !important; }
    .square-sm-2 o4-h-0 o33 {
      width: 20%;
      padding: 0 0 6.64% 0 !important; }
    .square-sm-2 o4-h-0 o5 {
      width: 20%;
      padding: 0 0 10% 0 !important; }
    .square-sm-2 o4-h-1 o5 {
      width: 20%;
      padding: 0 0 29.98% 0 !important; }
    .square-sm-2 o4-h-2 {
      width: 20%;
      padding: 0 0 40% 0 !important; }

  .square-sm-2 {
    width: 16.66667%;
    padding: 0 0 16.66667% 0 !important; }
    .square-sm-2-h-0 o16 {
      width: 16.66667%;
      padding: 0 0 2.76667% 0 !important; }
    .square-sm-2-h-0 o25 {
      width: 16.66667%;
      padding: 0 0 4.16667% 0 !important; }
    .square-sm-2-h-0 o33 {
      width: 16.66667%;
      padding: 0 0 5.53333% 0 !important; }
    .square-sm-2-h-0 o5 {
      width: 16.66667%;
      padding: 0 0 8.33333% 0 !important; }
    .square-sm-2-h-1 o5 {
      width: 16.66667%;
      padding: 0 0 24.98333% 0 !important; }
    .square-sm-2-h-2 {
      width: 16.66667%;
      padding: 0 0 33.33333% 0 !important; }

  .square-sm-1 o5 {
    width: 12.5%;
    padding: 0 0 12.5% 0 !important; }
    .square-sm-1 o5-h-0 o16 {
      width: 12.5%;
      padding: 0 0 2.075% 0 !important; }
    .square-sm-1 o5-h-0 o25 {
      width: 12.5%;
      padding: 0 0 3.125% 0 !important; }
    .square-sm-1 o5-h-0 o33 {
      width: 12.5%;
      padding: 0 0 4.15% 0 !important; }
    .square-sm-1 o5-h-0 o5 {
      width: 12.5%;
      padding: 0 0 6.25% 0 !important; }
    .square-sm-1 o5-h-1 o5 {
      width: 12.5%;
      padding: 0 0 18.7375% 0 !important; }
    .square-sm-1 o5-h-2 {
      width: 12.5%;
      padding: 0 0 25% 0 !important; }

  .square-sm-1 o2 {
    width: 10%;
    padding: 0 0 10% 0 !important; }
    .square-sm-1 o2-h-0 o16 {
      width: 10%;
      padding: 0 0 1.66% 0 !important; }
    .square-sm-1 o2-h-0 o25 {
      width: 10%;
      padding: 0 0 2.5% 0 !important; }
    .square-sm-1 o2-h-0 o33 {
      width: 10%;
      padding: 0 0 3.32% 0 !important; }
    .square-sm-1 o2-h-0 o5 {
      width: 10%;
      padding: 0 0 5% 0 !important; }
    .square-sm-1 o2-h-1 o5 {
      width: 10%;
      padding: 0 0 14.99% 0 !important; }
    .square-sm-1 o2-h-2 {
      width: 10%;
      padding: 0 0 20% 0 !important; }

  .square-sm-1 {
    width: 8.33333%;
    padding: 0 0 8.33333% 0 !important; }
    .square-sm-1-h-0 o16 {
      width: 8.33333%;
      padding: 0 0 1.38333% 0 !important; }
    .square-sm-1-h-0 o25 {
      width: 8.33333%;
      padding: 0 0 2.08333% 0 !important; }
    .square-sm-1-h-0 o33 {
      width: 8.33333%;
      padding: 0 0 2.76667% 0 !important; }
    .square-sm-1-h-0 o5 {
      width: 8.33333%;
      padding: 0 0 4.16667% 0 !important; }
    .square-sm-1-h-1 o5 {
      width: 8.33333%;
      padding: 0 0 12.49167% 0 !important; }
    .square-sm-1-h-2 {
      width: 8.33333%;
      padding: 0 0 16.66667% 0 !important; }

  .tile-sm-12 {
    width: calc(100% - 0px);
    margin: 0px 0; }

  .tile-sm-6 {
    width: calc(50% - 0px);
    margin: 0px; }

  .tile-sm-4 {
    width: calc(33.33333% - 0px);
    margin: 0px; }

  .tile-sm-3 {
    width: calc(25% - 0px);
    margin: 0px; }

  .tile-sm-2 o4 {
    width: calc(20% - 0px);
    margin: 0px; }

  .tile-sm-2 {
    width: calc(16.66667% - 0px);
    margin: 0px; }

  .tile-sm-1 o5 {
    width: calc(12.5% - 0px);
    margin: 0px; }

  .tile-sm-1 o2 {
    width: calc(10% - 0px);
    margin: 0px; }

  .tile-sm-1 {
    width: calc(8.33333% - 0px);
    margin: 0px; }

  .fixcol-sm-50 {
    width: calc(50px + 2 * 15px); }

  .fixcol-sm-50-rest {
    width: calc(100% - (50px + 2 * 15px)); }

  .fixcol-sm-100 {
    width: calc(100px + 2 * 15px); }

  .fixcol-sm-100-rest {
    width: calc(100% - (100px + 2 * 15px)); }

  .fixcol-sm-125 {
    width: calc(125px + 2 * 15px); }

  .fixcol-sm-125-rest {
    width: calc(100% - (125px + 2 * 15px)); }

  .fixcol-sm-150 {
    width: calc(150px + 2 * 15px); }

  .fixcol-sm-150-rest {
    width: calc(100% - (150px + 2 * 15px)); }

  .fixcol-sm-200 {
    width: calc(200px + 2 * 15px); }

  .fixcol-sm-200-rest {
    width: calc(100% - (200px + 2 * 15px)); }

  .fixcol-sm-250 {
    width: calc(250px + 2 * 15px); }

  .fixcol-sm-250-rest {
    width: calc(100% - (250px + 2 * 15px)); } }
@media (min-width: 992px) {
  .square-md-12 {
    width: 100%;
    padding: 0 0 100% 0 !important; }
    .square-md-12-h-0 o16 {
      width: 100%;
      padding: 0 0 16.6% 0 !important; }
    .square-md-12-h-0 o25 {
      width: 100%;
      padding: 0 0 25% 0 !important; }
    .square-md-12-h-0 o33 {
      width: 100%;
      padding: 0 0 33.2% 0 !important; }
    .square-md-12-h-0 o5 {
      width: 100%;
      padding: 0 0 50% 0 !important; }
    .square-md-12-h-1 o5 {
      width: 100%;
      padding: 0 0 149.9% 0 !important; }
    .square-md-12-h-2 {
      width: 100%;
      padding: 0 0 200% 0 !important; }

  .square-md-6 {
    width: 50%;
    padding: 0 0 50% 0 !important; }
    .square-md-6-h-0 o16 {
      width: 50%;
      padding: 0 0 8.3% 0 !important; }
    .square-md-6-h-0 o25 {
      width: 50%;
      padding: 0 0 12.5% 0 !important; }
    .square-md-6-h-0 o33 {
      width: 50%;
      padding: 0 0 16.6% 0 !important; }
    .square-md-6-h-0 o5 {
      width: 50%;
      padding: 0 0 25% 0 !important; }
    .square-md-6-h-1 o5 {
      width: 50%;
      padding: 0 0 74.95% 0 !important; }
    .square-md-6-h-2 {
      width: 50%;
      padding: 0 0 100% 0 !important; }

  .square-md-4 {
    width: 33.33333%;
    padding: 0 0 33.33333% 0 !important; }
    .square-md-4-h-0 o16 {
      width: 33.33333%;
      padding: 0 0 5.53333% 0 !important; }
    .square-md-4-h-0 o25 {
      width: 33.33333%;
      padding: 0 0 8.33333% 0 !important; }
    .square-md-4-h-0 o33 {
      width: 33.33333%;
      padding: 0 0 11.06667% 0 !important; }
    .square-md-4-h-0 o5 {
      width: 33.33333%;
      padding: 0 0 16.66667% 0 !important; }
    .square-md-4-h-1 o5 {
      width: 33.33333%;
      padding: 0 0 49.96667% 0 !important; }
    .square-md-4-h-2 {
      width: 33.33333%;
      padding: 0 0 66.66667% 0 !important; }

  .square-md-3 {
    width: 25%;
    padding: 0 0 25% 0 !important; }
    .square-md-3-h-0 o16 {
      width: 25%;
      padding: 0 0 4.15% 0 !important; }
    .square-md-3-h-0 o25 {
      width: 25%;
      padding: 0 0 6.25% 0 !important; }
    .square-md-3-h-0 o33 {
      width: 25%;
      padding: 0 0 8.3% 0 !important; }
    .square-md-3-h-0 o5 {
      width: 25%;
      padding: 0 0 12.5% 0 !important; }
    .square-md-3-h-1 o5 {
      width: 25%;
      padding: 0 0 37.475% 0 !important; }
    .square-md-3-h-2 {
      width: 25%;
      padding: 0 0 50% 0 !important; }

  .square-md-2 o4 {
    width: 20%;
    padding: 0 0 20% 0 !important; }
    .square-md-2 o4-h-0 o16 {
      width: 20%;
      padding: 0 0 3.32% 0 !important; }
    .square-md-2 o4-h-0 o25 {
      width: 20%;
      padding: 0 0 5% 0 !important; }
    .square-md-2 o4-h-0 o33 {
      width: 20%;
      padding: 0 0 6.64% 0 !important; }
    .square-md-2 o4-h-0 o5 {
      width: 20%;
      padding: 0 0 10% 0 !important; }
    .square-md-2 o4-h-1 o5 {
      width: 20%;
      padding: 0 0 29.98% 0 !important; }
    .square-md-2 o4-h-2 {
      width: 20%;
      padding: 0 0 40% 0 !important; }

  .square-md-2 {
    width: 16.66667%;
    padding: 0 0 16.66667% 0 !important; }
    .square-md-2-h-0 o16 {
      width: 16.66667%;
      padding: 0 0 2.76667% 0 !important; }
    .square-md-2-h-0 o25 {
      width: 16.66667%;
      padding: 0 0 4.16667% 0 !important; }
    .square-md-2-h-0 o33 {
      width: 16.66667%;
      padding: 0 0 5.53333% 0 !important; }
    .square-md-2-h-0 o5 {
      width: 16.66667%;
      padding: 0 0 8.33333% 0 !important; }
    .square-md-2-h-1 o5 {
      width: 16.66667%;
      padding: 0 0 24.98333% 0 !important; }
    .square-md-2-h-2 {
      width: 16.66667%;
      padding: 0 0 33.33333% 0 !important; }

  .square-md-1 o5 {
    width: 12.5%;
    padding: 0 0 12.5% 0 !important; }
    .square-md-1 o5-h-0 o16 {
      width: 12.5%;
      padding: 0 0 2.075% 0 !important; }
    .square-md-1 o5-h-0 o25 {
      width: 12.5%;
      padding: 0 0 3.125% 0 !important; }
    .square-md-1 o5-h-0 o33 {
      width: 12.5%;
      padding: 0 0 4.15% 0 !important; }
    .square-md-1 o5-h-0 o5 {
      width: 12.5%;
      padding: 0 0 6.25% 0 !important; }
    .square-md-1 o5-h-1 o5 {
      width: 12.5%;
      padding: 0 0 18.7375% 0 !important; }
    .square-md-1 o5-h-2 {
      width: 12.5%;
      padding: 0 0 25% 0 !important; }

  .square-md-1 o2 {
    width: 10%;
    padding: 0 0 10% 0 !important; }
    .square-md-1 o2-h-0 o16 {
      width: 10%;
      padding: 0 0 1.66% 0 !important; }
    .square-md-1 o2-h-0 o25 {
      width: 10%;
      padding: 0 0 2.5% 0 !important; }
    .square-md-1 o2-h-0 o33 {
      width: 10%;
      padding: 0 0 3.32% 0 !important; }
    .square-md-1 o2-h-0 o5 {
      width: 10%;
      padding: 0 0 5% 0 !important; }
    .square-md-1 o2-h-1 o5 {
      width: 10%;
      padding: 0 0 14.99% 0 !important; }
    .square-md-1 o2-h-2 {
      width: 10%;
      padding: 0 0 20% 0 !important; }

  .square-md-1 {
    width: 8.33333%;
    padding: 0 0 8.33333% 0 !important; }
    .square-md-1-h-0 o16 {
      width: 8.33333%;
      padding: 0 0 1.38333% 0 !important; }
    .square-md-1-h-0 o25 {
      width: 8.33333%;
      padding: 0 0 2.08333% 0 !important; }
    .square-md-1-h-0 o33 {
      width: 8.33333%;
      padding: 0 0 2.76667% 0 !important; }
    .square-md-1-h-0 o5 {
      width: 8.33333%;
      padding: 0 0 4.16667% 0 !important; }
    .square-md-1-h-1 o5 {
      width: 8.33333%;
      padding: 0 0 12.49167% 0 !important; }
    .square-md-1-h-2 {
      width: 8.33333%;
      padding: 0 0 16.66667% 0 !important; }

  .tile-md-12 {
    width: calc(100% - 0px);
    margin: 0px 0; }

  .tile-md-6 {
    width: calc(50% - 0px);
    margin: 0px; }

  .tile-md-4 {
    width: calc(33.33333% - 0px);
    margin: 0px; }

  .tile-md-3 {
    width: calc(25% - 0px);
    margin: 0px; }

  .tile-md-2 o4 {
    width: calc(20% - 0px);
    margin: 0px; }

  .tile-md-2 {
    width: calc(16.66667% - 0px);
    margin: 0px; }

  .tile-md-1 o5 {
    width: calc(12.5% - 0px);
    margin: 0px; }

  .tile-md-1 o2 {
    width: calc(10% - 0px);
    margin: 0px; }

  .tile-md-1 {
    width: calc(8.33333% - 0px);
    margin: 0px; }

  .fixcol-md-50 {
    width: calc(50px + 2 * 15px); }

  .fixcol-md-50-rest {
    width: calc(100% - (50px + 2 * 15px)); }

  .fixcol-md-100 {
    width: calc(100px + 2 * 15px); }

  .fixcol-md-100-rest {
    width: calc(100% - (100px + 2 * 15px)); }

  .fixcol-md-125 {
    width: calc(125px + 2 * 15px); }

  .fixcol-md-125-rest {
    width: calc(100% - (125px + 2 * 15px)); }

  .fixcol-md-150 {
    width: calc(150px + 2 * 15px); }

  .fixcol-md-150-rest {
    width: calc(100% - (150px + 2 * 15px)); }

  .fixcol-md-200 {
    width: calc(200px + 2 * 15px); }

  .fixcol-md-200-rest {
    width: calc(100% - (200px + 2 * 15px)); }

  .fixcol-md-250 {
    width: calc(250px + 2 * 15px); }

  .fixcol-md-250-rest {
    width: calc(100% - (250px + 2 * 15px)); } }
@media (min-width: 1200px) {
  .square-lg-12 {
    width: 100%;
    padding: 0 0 100% 0 !important; }
    .square-lg-12-h-0 o16 {
      width: 100%;
      padding: 0 0 16.6% 0 !important; }
    .square-lg-12-h-0 o25 {
      width: 100%;
      padding: 0 0 25% 0 !important; }
    .square-lg-12-h-0 o33 {
      width: 100%;
      padding: 0 0 33.2% 0 !important; }
    .square-lg-12-h-0 o5 {
      width: 100%;
      padding: 0 0 50% 0 !important; }
    .square-lg-12-h-1 o5 {
      width: 100%;
      padding: 0 0 149.9% 0 !important; }
    .square-lg-12-h-2 {
      width: 100%;
      padding: 0 0 200% 0 !important; }

  .square-lg-6 {
    width: 50%;
    padding: 0 0 50% 0 !important; }
    .square-lg-6-h-0 o16 {
      width: 50%;
      padding: 0 0 8.3% 0 !important; }
    .square-lg-6-h-0 o25 {
      width: 50%;
      padding: 0 0 12.5% 0 !important; }
    .square-lg-6-h-0 o33 {
      width: 50%;
      padding: 0 0 16.6% 0 !important; }
    .square-lg-6-h-0 o5 {
      width: 50%;
      padding: 0 0 25% 0 !important; }
    .square-lg-6-h-1 o5 {
      width: 50%;
      padding: 0 0 74.95% 0 !important; }
    .square-lg-6-h-2 {
      width: 50%;
      padding: 0 0 100% 0 !important; }

  .square-lg-4 {
    width: 33.33333%;
    padding: 0 0 33.33333% 0 !important; }
    .square-lg-4-h-0 o16 {
      width: 33.33333%;
      padding: 0 0 5.53333% 0 !important; }
    .square-lg-4-h-0 o25 {
      width: 33.33333%;
      padding: 0 0 8.33333% 0 !important; }
    .square-lg-4-h-0 o33 {
      width: 33.33333%;
      padding: 0 0 11.06667% 0 !important; }
    .square-lg-4-h-0 o5 {
      width: 33.33333%;
      padding: 0 0 16.66667% 0 !important; }
    .square-lg-4-h-1 o5 {
      width: 33.33333%;
      padding: 0 0 49.96667% 0 !important; }
    .square-lg-4-h-2 {
      width: 33.33333%;
      padding: 0 0 66.66667% 0 !important; }

  .square-lg-3 {
    width: 25%;
    padding: 0 0 25% 0 !important; }
    .square-lg-3-h-0 o16 {
      width: 25%;
      padding: 0 0 4.15% 0 !important; }
    .square-lg-3-h-0 o25 {
      width: 25%;
      padding: 0 0 6.25% 0 !important; }
    .square-lg-3-h-0 o33 {
      width: 25%;
      padding: 0 0 8.3% 0 !important; }
    .square-lg-3-h-0 o5 {
      width: 25%;
      padding: 0 0 12.5% 0 !important; }
    .square-lg-3-h-1 o5 {
      width: 25%;
      padding: 0 0 37.475% 0 !important; }
    .square-lg-3-h-2 {
      width: 25%;
      padding: 0 0 50% 0 !important; }

  .square-lg-2 o4 {
    width: 20%;
    padding: 0 0 20% 0 !important; }
    .square-lg-2 o4-h-0 o16 {
      width: 20%;
      padding: 0 0 3.32% 0 !important; }
    .square-lg-2 o4-h-0 o25 {
      width: 20%;
      padding: 0 0 5% 0 !important; }
    .square-lg-2 o4-h-0 o33 {
      width: 20%;
      padding: 0 0 6.64% 0 !important; }
    .square-lg-2 o4-h-0 o5 {
      width: 20%;
      padding: 0 0 10% 0 !important; }
    .square-lg-2 o4-h-1 o5 {
      width: 20%;
      padding: 0 0 29.98% 0 !important; }
    .square-lg-2 o4-h-2 {
      width: 20%;
      padding: 0 0 40% 0 !important; }

  .square-lg-2 {
    width: 16.66667%;
    padding: 0 0 16.66667% 0 !important; }
    .square-lg-2-h-0 o16 {
      width: 16.66667%;
      padding: 0 0 2.76667% 0 !important; }
    .square-lg-2-h-0 o25 {
      width: 16.66667%;
      padding: 0 0 4.16667% 0 !important; }
    .square-lg-2-h-0 o33 {
      width: 16.66667%;
      padding: 0 0 5.53333% 0 !important; }
    .square-lg-2-h-0 o5 {
      width: 16.66667%;
      padding: 0 0 8.33333% 0 !important; }
    .square-lg-2-h-1 o5 {
      width: 16.66667%;
      padding: 0 0 24.98333% 0 !important; }
    .square-lg-2-h-2 {
      width: 16.66667%;
      padding: 0 0 33.33333% 0 !important; }

  .square-lg-1 o5 {
    width: 12.5%;
    padding: 0 0 12.5% 0 !important; }
    .square-lg-1 o5-h-0 o16 {
      width: 12.5%;
      padding: 0 0 2.075% 0 !important; }
    .square-lg-1 o5-h-0 o25 {
      width: 12.5%;
      padding: 0 0 3.125% 0 !important; }
    .square-lg-1 o5-h-0 o33 {
      width: 12.5%;
      padding: 0 0 4.15% 0 !important; }
    .square-lg-1 o5-h-0 o5 {
      width: 12.5%;
      padding: 0 0 6.25% 0 !important; }
    .square-lg-1 o5-h-1 o5 {
      width: 12.5%;
      padding: 0 0 18.7375% 0 !important; }
    .square-lg-1 o5-h-2 {
      width: 12.5%;
      padding: 0 0 25% 0 !important; }

  .square-lg-1 o2 {
    width: 10%;
    padding: 0 0 10% 0 !important; }
    .square-lg-1 o2-h-0 o16 {
      width: 10%;
      padding: 0 0 1.66% 0 !important; }
    .square-lg-1 o2-h-0 o25 {
      width: 10%;
      padding: 0 0 2.5% 0 !important; }
    .square-lg-1 o2-h-0 o33 {
      width: 10%;
      padding: 0 0 3.32% 0 !important; }
    .square-lg-1 o2-h-0 o5 {
      width: 10%;
      padding: 0 0 5% 0 !important; }
    .square-lg-1 o2-h-1 o5 {
      width: 10%;
      padding: 0 0 14.99% 0 !important; }
    .square-lg-1 o2-h-2 {
      width: 10%;
      padding: 0 0 20% 0 !important; }

  .square-lg-1 {
    width: 8.33333%;
    padding: 0 0 8.33333% 0 !important; }
    .square-lg-1-h-0 o16 {
      width: 8.33333%;
      padding: 0 0 1.38333% 0 !important; }
    .square-lg-1-h-0 o25 {
      width: 8.33333%;
      padding: 0 0 2.08333% 0 !important; }
    .square-lg-1-h-0 o33 {
      width: 8.33333%;
      padding: 0 0 2.76667% 0 !important; }
    .square-lg-1-h-0 o5 {
      width: 8.33333%;
      padding: 0 0 4.16667% 0 !important; }
    .square-lg-1-h-1 o5 {
      width: 8.33333%;
      padding: 0 0 12.49167% 0 !important; }
    .square-lg-1-h-2 {
      width: 8.33333%;
      padding: 0 0 16.66667% 0 !important; }

  .tile-lg-12 {
    width: calc(100% - 0px);
    margin: 0px 0; }

  .tile-lg-6 {
    width: calc(50% - 0px);
    margin: 0px; }

  .tile-lg-4 {
    width: calc(33.33333% - 0px);
    margin: 0px; }

  .tile-lg-3 {
    width: calc(25% - 0px);
    margin: 0px; }

  .tile-lg-2 o4 {
    width: calc(20% - 0px);
    margin: 0px; }

  .tile-lg-2 {
    width: calc(16.66667% - 0px);
    margin: 0px; }

  .tile-lg-1 o5 {
    width: calc(12.5% - 0px);
    margin: 0px; }

  .tile-lg-1 o2 {
    width: calc(10% - 0px);
    margin: 0px; }

  .tile-lg-1 {
    width: calc(8.33333% - 0px);
    margin: 0px; }

  .fixcol-lg-50 {
    width: calc(50px + 2 * 15px); }

  .fixcol-lg-50-rest {
    width: calc(100% - (50px + 2 * 15px)); }

  .fixcol-lg-100 {
    width: calc(100px + 2 * 15px); }

  .fixcol-lg-100-rest {
    width: calc(100% - (100px + 2 * 15px)); }

  .fixcol-lg-125 {
    width: calc(125px + 2 * 15px); }

  .fixcol-lg-125-rest {
    width: calc(100% - (125px + 2 * 15px)); }

  .fixcol-lg-150 {
    width: calc(150px + 2 * 15px); }

  .fixcol-lg-150-rest {
    width: calc(100% - (150px + 2 * 15px)); }

  .fixcol-lg-200 {
    width: calc(200px + 2 * 15px); }

  .fixcol-lg-200-rest {
    width: calc(100% - (200px + 2 * 15px)); }

  .fixcol-lg-250 {
    width: calc(250px + 2 * 15px); }

  .fixcol-lg-250-rest {
    width: calc(100% - (250px + 2 * 15px)); } }
/* Event calendar */
.ap-event {
  color: #555;
  margin-bottom: 20px; }
  .ap-event a.link {
    color: #555; }
  .ap-event a:hover {
    color: #ff264a;
    text-decoration: none; }
  .ap-event .teaser-body .teaser-date {
    font-style: italic; }
  .ap-event .calendar-sheet {
    min-width: 90px;
    border: 1px solid #e2e3e6;
    text-align: center;
    background: #f7f8f8; }
    .ap-event .calendar-sheet:before {
      border-top: 20px solid #b31b34;
      display: block;
      content: '';
      margin: 0 10%; }
    .ap-event .calendar-sheet .day,
    .ap-event .calendar-sheet .monthYear {
      padding: 10px; }
    .ap-event .calendar-sheet h3 {
      color: #b31b34;
      font-weight: 600; }
  .ap-event.ap-raise-animation:hover .animated-box {
    box-shadow: 5px 5px 8px -1px rgba(0, 0, 0, 0.3); }

.color-white .calendar-sheet:before {
  border-top: 20px solid #fff; }
.color-white .calendar-sheet h3 {
  color: #fff; }

.color-grey-lighter .calendar-sheet:before {
  border-top: 20px solid #f7f8f8; }
.color-grey-lighter .calendar-sheet h3 {
  color: #f7f8f8; }

.color-grey-light .calendar-sheet:before {
  border-top: 20px solid #e2e3e6; }
.color-grey-light .calendar-sheet h3 {
  color: #e2e3e6; }

.color-grey .calendar-sheet:before {
  border-top: 20px solid #a8adb4; }
.color-grey .calendar-sheet h3 {
  color: #a8adb4; }

.color-grey-dark .calendar-sheet:before {
  border-top: 20px solid #7f8691; }
.color-grey-dark .calendar-sheet h3 {
  color: #7f8691; }

.color-grey-darker .calendar-sheet:before {
  border-top: 20px solid #5a6069; }
.color-grey-darker .calendar-sheet h3 {
  color: #5a6069; }

.color-black .calendar-sheet:before {
  border-top: 20px solid #000; }
.color-black .calendar-sheet h3 {
  color: #000; }

.color-blue-light .calendar-sheet:before {
  border-top: 20px solid #2197db; }
.color-blue-light .calendar-sheet h3 {
  color: #2197db; }

.color-blue .calendar-sheet:before {
  border-top: 20px solid #144382; }
.color-blue .calendar-sheet h3 {
  color: #144382; }

.color-blue-dark .calendar-sheet:before {
  border-top: 20px solid #0d2c56; }
.color-blue-dark .calendar-sheet h3 {
  color: #0d2c56; }

.color-blue-darker .calendar-sheet:before {
  border-top: 20px solid #0a2140; }
.color-blue-darker .calendar-sheet h3 {
  color: #0a2140; }

.color-cyan .calendar-sheet:before {
  border-top: 20px solid #1bb39a; }
.color-cyan .calendar-sheet h3 {
  color: #1bb39a; }

.color-red-light .calendar-sheet:before {
  border-top: 20px solid #ff264a; }
.color-red-light .calendar-sheet h3 {
  color: #ff264a; }

.color-red .calendar-sheet:before {
  border-top: 20px solid #b31b34; }
.color-red .calendar-sheet h3 {
  color: #b31b34; }

.color-red-dark .calendar-sheet:before {
  border-top: 20px solid #8b1528; }
.color-red-dark .calendar-sheet h3 {
  color: #8b1528; }

.color-red-darker .calendar-sheet:before {
  border-top: 20px solid #560d19; }
.color-red-darker .calendar-sheet h3 {
  color: #560d19; }

.color-orange-light .calendar-sheet:before {
  border-top: 20px solid #ffcc26; }
.color-orange-light .calendar-sheet h3 {
  color: #ffcc26; }

.color-orange .calendar-sheet:before {
  border-top: 20px solid #ffa826; }
.color-orange .calendar-sheet h3 {
  color: #ffa826; }

.color-orange-dark .calendar-sheet:before {
  border-top: 20px solid #fc9700; }
.color-orange-dark .calendar-sheet h3 {
  color: #fc9700; }

.color-orange-darker .calendar-sheet:before {
  border-top: 20px solid #d37f00; }
.color-orange-darker .calendar-sheet h3 {
  color: #d37f00; }

.color-blue-cyan .calendar-sheet:before {
  border-top: 20px solid #187b8e; }
.color-blue-cyan .calendar-sheet h3 {
  color: #187b8e; }

/* Blog */
.ap-blog {
  color: #555;
  margin-bottom: 20px; }
  .ap-blog a.link {
    color: #555; }
  .ap-blog a:hover {
    color: #ff264a;
    text-decoration: none; }
  .ap-blog .teaser-body .teaser-date {
    font-style: italic; }

.ap-job.ap-job-detail {
  margin-bottom: 20px; }
  .ap-job.ap-job-detail .ap-job-headline-block .headline {
    margin-top: 0; }
    .ap-job.ap-job-detail .ap-job-headline-block .headline h2 {
      font-size: 32px;
      color: #b31b34;
      border-bottom: none; }
  .ap-job.ap-job-detail .ap-job-intro {
    margin-bottom: 20px; }

body {
  background-color: #fff;
  height: 100%; }
  body.modal.open .mariva-foreground {
    padding-right: 15px; }

#mariva-loader {
  position: absolute;
  left: 45%;
  top: 30%;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0b3e9c;
  border-bottom: 16px solid #0b3e9c;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; }
  @media (max-width: 992px) {
    #mariva-loader {
      left: 35%; } }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }
@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
.mariva-card {
  padding: 30px 30px 0;
  overflow-y: hidden;
  cursor: pointer; }
  .mariva-card .card-menu-item-bg {
    visibility: hidden; }
  .mariva-card .card-menu-item-title {
    position: absolute;
    top: 30px;
    text-transform: uppercase;
    color: #11419a;
    font-weight: normal;
    margin: 40px 0 30px; }
    @media (max-width: 992px) {
      .mariva-card .card-menu-item-title {
        display: none; } }
  .mariva-card .card-menu-item-main-content {
    margin-top: 100px; }
    .mariva-card .card-menu-item-main-content p {
      color: #000000; }
  .mariva-card .card-menu-item-secondary-content {
    margin-top: 100px; }
    .mariva-card .card-menu-item-secondary-content p {
      color: #151515; }
    @media (max-width: 992px) {
      .mariva-card .card-menu-item-secondary-content {
        margin-top: 30px;
        font-size: 16px; } }
  @media (min-width: 768px) {
    .mariva-card {
      height: 300px;
      transition: all .4s ease-in-out; }
      .mariva-card.grow {
        height: calc(300px + 80px);
        margin-top: -80px;
        padding-bottom: 30px;
        background-color: white;
        border-left: 1px solid rgba(128, 128, 128, 0.5); }
        .mariva-card.grow .card-menu-item-bg {
          visibility: visible;
          position: absolute;
          font-size: 15vw;
          opacity: .1;
          top: 0;
          left: 30%;
          line-height: 1; } }
      @media (min-width: 768px) and (max-width: 992px) {
        .mariva-card.grow .card-menu-item-bg {
          display: none; } }
  @media (min-width: 768px) {
        .mariva-card.grow .card-menu-item-title {
          color: #871719;
          transition-property: color;
          transition-timing-function: ease-out; }
        .mariva-card.grow.overflow {
          overflow-y: scroll;
          padding-right: -17px; }
      .mariva-card .card-menu-item-secondary-content {
        display: none; } }
  @media (max-width: 992px) {
    .mariva-card {
      padding: 30px 10px 0; } }
  @media (max-width: 768px) {
    .mariva-card {
      padding: 15px 35px 0;
      margin-bottom: 90px; } }

.mariva-area-foot {
  background: #f5f5f5;
  z-index: 10;
  text-align: center;
  font-size: 10px;
  padding-bottom: 0;
  padding-top: 5px; }
  @media (max-width: 768px) {
    .mariva-area-foot {
      position: relative;
      margin-top: 75px; } }
  .mariva-area-foot .ap-sec {
    margin-bottom: 0; }
  .mariva-area-foot p {
    padding: 0;
    margin-top: 5px; }
  .mariva-area-foot .footer-navigation .nav-pills {
    margin-left: 10%; }
    .mariva-area-foot .footer-navigation .nav-pills > li > a {
      padding: 0 5px; }
  .mariva-area-foot .footer-navigation a {
    color: #000000;
    font-size: 10px;
    text-decoration: underline;
    border-radius: 0;
    margin: 3px; }
    .mariva-area-foot .footer-navigation a:focus {
      text-decoration: underline;
      background-color: transparent; }
    .mariva-area-foot .footer-navigation a:hover {
      color: #fff;
      background: #000000;
      text-decoration: none; }

.mariva-area-head {
  width: 100%; }
  .mariva-area-head .navbar-header a.navbar-brand img {
    height: 35px; }
  .mariva-area-head nav.mariva-navbar {
    background: none;
    border: none; }
    .mariva-area-head nav.mariva-navbar .separator {
      pointer-events: none;
      cursor: default;
      margin-left: -13px;
      margin-right: -13px; }
    .mariva-area-head nav.mariva-navbar .navbar-nav > li > a {
      background: none;
      text-transform: uppercase; }
    .mariva-area-head nav.mariva-navbar .navbar-nav > li.broker-logo {
      padding: 0 !important; }
      .mariva-area-head nav.mariva-navbar .navbar-nav > li.broker-logo a {
        padding: 0; }
        .mariva-area-head nav.mariva-navbar .navbar-nav > li.broker-logo a:hover {
          background: none !important; }
      .mariva-area-head nav.mariva-navbar .navbar-nav > li.broker-logo img {
        height: 35px;
        margin: 7px 0 7px 14px; }
  .mariva-area-head .navigation-white nav.mariva-navbar .navbar-nav > li > a {
    color: #d9d9d9;
    font-size: 11px; }
    .mariva-area-head .navigation-white nav.mariva-navbar .navbar-nav > li > a:hover {
      color: #871719;
      background: none; }
  .mariva-area-head .navigation-white nav.mariva-navbar .navbar-nav > li.active > a {
    color: #871719; }
  .mariva-area-head .navigation-white nav.mariva-navbar .navbar-nav > li.broker-logo img.bk-logo-black {
    display: none; }
  @media (max-width: 768px) {
    .mariva-area-head .navigation-white .mariva-navbar {
      background-color: white; } }
  .mariva-area-head .navigation-black nav.mariva-navbar {
    background: white;
    transition: top 0.5s ease-in-out; }
  .mariva-area-head .navigation-black nav.mariva-navbar.nav-up {
    top: -60px; }
  .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav {
    margin-top: 20px; }
    .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li > a {
      padding: 1px 14px;
      font-size: 11px;
      color: #000000; }
      .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li > a:hover {
        color: #fff;
        background: #000000; }
    .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li.active > a {
      color: #fff;
      background: #000000; }
    .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li.lang-item.active > a {
      color: #000000;
      background: #fff;
      text-decoration: underline;
      font-weight: 500; }
    .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li.broker-logo img.bk-logo-white {
      display: none;
      margin: 0; }
    .mariva-area-head .navigation-black nav.mariva-navbar .navbar-nav > li.broker-logo img.bk-logo-black {
      margin: 0; }

.page-content {
  position: relative;
  left: 0; }
  .page-content.slide-active {
    padding-top: 0; }

.navbar-header {
  position: relative; }

@media (max-width: 767px) {
  .mariva-navbar {
    border-top: 0; }
    .mariva-navbar.slide-active {
      height: 100%; }
    .mariva-navbar .container {
      margin: 0;
      padding: 0 !important; }
    .mariva-navbar .navbar-header {
      margin: 0 auto;
      padding: 0 35px; }
      .mariva-navbar .navbar-header a.navbar-brand img {
        height: 30px; }
    .mariva-navbar #slidemenu {
      height: 100vh;
      left: -100%;
      width: 100vw;
      min-width: 0;
      position: fixed;
      padding-left: 0;
      z-index: 2;
      top: -8px;
      margin: 0; }
      .mariva-navbar #slidemenu .navbar-nav {
        min-width: 0;
        width: 100%;
        margin: 0; }
        .mariva-navbar #slidemenu .navbar-nav.nav-stacked {
          width: 80%;
          margin-top: 30px;
          padding-left: 10px; }
          .mariva-navbar #slidemenu .navbar-nav.nav-stacked img {
            margin-right: 10px;
            width: 14px; }
          .mariva-navbar #slidemenu .navbar-nav.nav-stacked .navbar-space {
            margin-top: 20px; }
          .mariva-navbar #slidemenu .navbar-nav.nav-stacked .side-menu-item-bigger a {
            margin-bottom: 15px; }
          .mariva-navbar #slidemenu .navbar-nav.nav-stacked .side-menu-item-smaller a {
            opacity: .7;
            font-size: 15px;
            padding: 4px 14px; }
        .mariva-navbar #slidemenu .navbar-nav a {
          font-size: 20px;
          color: #d9d9d9;
          padding: 10px 15px; }
          .mariva-navbar #slidemenu .navbar-nav a:hover {
            background: none;
            font-weight: bold; }
        .mariva-navbar #slidemenu .navbar-nav li.active > a {
          font-weight: bold;
          background: none; }
        .mariva-navbar #slidemenu .navbar-nav li.lang-item.active > a {
          background: none;
          color: black;
          font-weight: 500; }
      .mariva-navbar #slidemenu .mobile-sidebar-top-menu {
        width: 100%;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px; }
        .mariva-navbar #slidemenu .mobile-sidebar-top-menu img {
          margin-top: 5px;
          width: 20px;
          height: 20px; }
        .mariva-navbar #slidemenu .mobile-sidebar-top-menu > div {
          display: inline-block; }
          .mariva-navbar #slidemenu .mobile-sidebar-top-menu > div.mobile-language-options {
            float: right; }
            .mariva-navbar #slidemenu .mobile-sidebar-top-menu > div.mobile-language-options .lang-item a {
              color: #d9d9d9;
              text-transform: uppercase;
              font-size: 20px;
              margin-left: 15px; }
            .mariva-navbar #slidemenu .mobile-sidebar-top-menu > div.mobile-language-options .lang-item.active > a {
              text-decoration: underline;
              color: #000000; }
      .mariva-navbar #slidemenu .mobile-sidebar-bottom {
        position: absolute;
        bottom: 10%; }
        .mariva-navbar #slidemenu .mobile-sidebar-bottom p {
          color: #d9d9d9;
          margin-left: 15px;
          font-size: 11px; }
          .mariva-navbar #slidemenu .mobile-sidebar-bottom p a {
            color: #d9d9d9;
            font-weight: 500; }
        .mariva-navbar #slidemenu .mobile-sidebar-bottom img {
          width: 125px;
          margin-left: 15px; } }
    @media (max-width: 767px) and (max-width: 350px) {
      .mariva-navbar #slidemenu .navbar-nav a.side-menu-item-bigger {
        font-size: 15px; }
      .mariva-navbar #slidemenu .navbar-nav a.side-menu-item-smaller {
        font-size: 13px;
        padding: 3px 14px; }
      .mariva-navbar #slidemenu .navbar-nav .navbar-space {
        margin-top: 15px; }
      .mariva-navbar #slidemenu .mobile-sidebar-bottom img {
        width: 115px;
        margin-left: 10px; }
      .mariva-navbar #slidemenu .mobile-sidebar-bottom p {
        font-size: 9px; } }
@media (max-width: 767px) {
    .mariva-navbar #navbar-height-col {
      position: fixed;
      top: 0;
      height: 100%;
      width: 100vw;
      left: -100%;
      background: #11419a; }
    .mariva-navbar .navbar.slide-active {
      position: absolute;
      width: 80%;
      top: -1px;
      z-index: 1300; } }
@media (min-width: 768px) {
  .page-content {
    left: 0 !important; }

  .navbar-header {
    left: 0 !important; }

  .navbar.navbar-fixed-top.slide-active {
    position: fixed; } }
body.slide-active {
  overflow-x: hidden; }

.mariva-navbar .navbar-toggle {
  cursor: pointer;
  position: relative;
  line-height: 0;
  float: left;
  margin: 0;
  width: 30px;
  height: 40px;
  padding: 15px 0 0;
  border: 0;
  background: transparent;
  margin-right: 20%; }
  .mariva-navbar .navbar-toggle:focus, .mariva-navbar .navbar-toggle:hover {
    background-color: transparent; }
  .mariva-navbar .navbar-toggle > .icon-bar {
    width: 100%;
    background-color: #d9d9d9;
    display: block;
    height: 4px;
    margin: 5px 0 0; }

.navbar.navbar-fixed-top.slide-active {
  position: absolute; }

.mariva-foreground {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  pointer-events: none; }
  .mariva-foreground .container, .mariva-foreground .row, .mariva-foreground .fg-column {
    height: 100%; }
  .mariva-foreground .fg-column {
    border-left: 1px solid #bfbfbf; }
    .mariva-foreground .fg-column.last-column {
      border-right: 1px solid #bfbfbf; }

.nosotros-two-cols-text .ap-plain h1 {
  font-size: 43px;
  font-weight: 400;
  margin-bottom: 40px; }
.nosotros-two-cols-text .ap-plain p {
  font-size: 18px;
  margin-left: 5px;
  margin-right: 5px; }
@media (max-width: 992px) {
  .nosotros-two-cols-text .ap-plain p {
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px; } }
@media (min-width: 992px) {
  .nosotros-two-cols-text .ap-plain p {
    font-size: 15px;
    -webkit-column-gap: 50px;
    -moz-column-gap: 50px;
    column-gap: 50px;
    -webkit-column-count: 2;
    /* Chrome, Safari, Opera */
    -moz-column-count: 2;
    /* Firefox */
    column-count: 2; } }

.mariva-link-sequence-black {
  margin-bottom: 40px;
  margin-left: 25px; }
  .mariva-link-sequence-black li {
    margin-top: 15px;
    margin-bottom: 15px; }
  .mariva-link-sequence-black a {
    color: #000000; }
  @media (max-width: 768px) {
    .mariva-link-sequence-black {
      display: none; } }

@media (max-width: 768px) {
  .img-animated {
    display: none; } }

#clientsModal {
  text-align: center;
  margin-top: 40px; }
  @media (max-width: 768px) {
    #clientsModal {
      position: absolute; } }
  #clientsModal .modal-content {
    font-size: 22px;
    background: none;
    box-shadow: none;
    border: none; }
    #clientsModal .modal-content button.close {
      color: #d9d9d9;
      opacity: 0.8;
      float: none; }
      #clientsModal .modal-content button.close:focus, #clientsModal .modal-content button.close:hover {
        color: #d9d9d9;
        opacity: 0.8; }
    #clientsModal .modal-content h1 {
      color: #fff;
      margin-bottom: 20px; }
    #clientsModal .modal-content p {
      color: #fff;
      font-size: 16px; }
    #clientsModal .modal-content a.btn {
      border-radius: 40px;
      padding: 11px 50px;
      background-color: #11419a;
      text-transform: uppercase;
      font-size: 18px;
      margin-top: 20px; }
  #clientsModal button {
    font-size: 20px;
    font-weight: 300;
    border-bottom: 1px solid gray;
    margin-top: 20px; }
    @media (max-width: 768px) {
      #clientsModal button {
        left: 40%; } }

.modal-backdrop.in {
  opacity: 0.8; }

.mariva-sub-title h1 {
  font-size: 50px;
  font-weight: 400;
  margin-bottom: 40px; }
.mariva-sub-title p {
  color: black;
  font-size: 15px;
  margin-bottom: 25px; }
  .mariva-sub-title p strong {
    font-weight: 500; }
.mariva-sub-title .ap-plain p a.btn {
  display: none; }

.mariva-sub-title-nuestra h1 {
  font-size: 43px;
  font-weight: 400;
  margin-bottom: 0; }

.mariva-sub-title-comunidad h1 {
  font-size: 43px;
  font-weight: 300;
  margin-bottom: 20px; }

.mariva-nosotros-title-cm h1 {
  color: black;
  font-size: 50px;
  line-height: 45px;
  margin-bottom: 0;
  margin-top: 0; }
  .mariva-nosotros-title-cm h1 strong {
    font-weight: 400; }
@media (max-width: 768px) {
  .mariva-nosotros-title-cm {
    margin-left: 15px;
    margin-right: 15px; } }

.mariva-abs-top {
  margin-left: 15px;
  margin-right: 15px; }
  .mariva-abs-top .col-float-top {
    background-color: #fff;
    box-shadow: 0 0 44px rgba(0, 0, 0, 0.5);
    padding: 25px;
    margin-top: -55%;
    margin-left: 0;
    margin-right: 0;
    max-width: 45%;
    min-height: 350px; }
    @media (max-width: 768px) {
      .mariva-abs-top .col-float-top {
        max-width: 90%;
        min-height: 170px;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -20%; } }
  .mariva-abs-top .col-float-top-services {
    background-color: #fff;
    box-shadow: 0 0 44px rgba(0, 0, 0, 0.5);
    padding: 30px;
    margin-top: -50%;
    margin-left: 10%;
    margin-right: 10%;
    max-width: 45%;
    min-height: 350px; }
    .mariva-abs-top .col-float-top-services h3 {
      font-size: 25px;
      line-height: 32px; }
    @media (max-width: 768px) {
      .mariva-abs-top .col-float-top-services {
        max-width: 80%;
        min-height: 170px;
        margin-left: 10%;
        margin-right: 10%;
        margin-top: -20%; } }

.services-floating > div {
  background-color: #ffffff; }
  .services-floating > div.transparent {
    background: transparent; }
  @media (min-width: 768px) {
    .services-floating > div {
      margin-top: -250px;
      height: 600px; } }

.services-title .ap-plain h1 {
  font-size: 50px;
  line-height: 50px;
  color: black; }
  .services-title .ap-plain h1 strong {
    font-weight: 400;
    letter-spacing: 1px; }

.mariva-services h1 {
  font-size: 35px;
  line-height: 40px; }
.mariva-services p {
  font-size: 15px; }

.services-list-1 {
  margin-top: 50px;
  font-size: 15px; }
  .services-list-1 p {
    margin-bottom: 35px;
    margin-left: 30px; }
  .services-list-1 p::before {
    content: '';
    opacity: .5;
    background-image: url(../img/black-arrow.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    display: inline;
    width: 20px;
    height: 15px;
    position: absolute;
    left: 30px;
    margin-top: 7px; }
    @media (max-width: 768px) {
      .services-list-1 p::before {
        left: 25px; } }
  .services-list-1 img {
    opacity: .5; }
  @media (max-width: 768px) {
    .services-list-1 {
      margin-top: 30px; } }
  @media (min-width: 768px) {
    .services-list-1 {
      width: 200%;
      overflow: visible; } }

@media (max-width: 768px) {
  .services-image .image-outer-box {
    height: 200px;
    overflow: hidden; }
    .services-image .image-outer-box img {
      margin-top: -85px; } }

.services-list-2 img {
  opacity: .5; }
@media (max-width: 768px) {
  .services-list-2 {
    margin-top: 12px; } }

@media (max-width: 768px) {
  nav.mariva-navbar button.navbar-toggle {
    float: left; }
  nav.mariva-navbar a.navbar-brand img {
    width: auto;
    height: 30px; } }
.bottom-navigation {
  padding: 5px;
  position: fixed;
  border-top: 1px solid #f5f5f5;
  bottom: 0;
  z-index: 1500;
  color: #11419a;
  background: white;
  text-transform: uppercase; }
  .bottom-navigation.bottom-navigation-blur {
    box-shadow: 0px -20px 20px 20px rgba(255, 255, 255, 0.88); }
  .bottom-navigation .mariva-nav-tab {
    vertical-align: middle;
    text-align: center;
    font-size: 17px;
    margin-top: 10px; }
  .bottom-navigation .nav-left-arrow, .bottom-navigation .nav-right-arrow {
    cursor: pointer; }
    .bottom-navigation .nav-left-arrow img, .bottom-navigation .nav-right-arrow img {
      margin-top: 10px;
      height: 25px;
      width: auto; }

#WWUModal .overflow-hidden {
  margin: -15px; }
#WWUModal .modal-dialog {
  margin-top: 10%; }
  #WWUModal .modal-dialog .modal-content {
    border-radius: 0px !important; }
    #WWUModal .modal-dialog .modal-content .title h1 {
      font-size: 36px;
      color: black; }
#WWUModal .work-with-us-image-container-mobile img {
  width: 100%; }
#WWUModal .work-with-us-image-txt {
  padding-top: 15px;
  padding-right: 25px;
  padding-left: 25px; }
  #WWUModal .work-with-us-image-txt .title {
    font-size: 40px;
    color: #000000; }
    #WWUModal .work-with-us-image-txt .title strong {
      font-weight: 400; }
  #WWUModal .work-with-us-image-txt p {
    color: #6c737d; }
  #WWUModal .work-with-us-image-txt a {
    font-weight: 500;
    color: #144382;
    font-size: 18px; }
#WWUModal button {
  color: white;
  font-size: 20px;
  font-weight: 300;
  position: absolute;
  bottom: -40px;
  border-bottom: 1px solid white;
  left: 44%;
  opacity: 0.8; }

.work-with-us-page-image {
  margin: -15px; }
  .work-with-us-page-image img {
    width: 100%; }

.work-with-us-page-title {
  font-size: 3em;
  font-weight: 300;
  margin-left: 20px;
  color: #fff;
  line-height: .6em;
  margin-top: -70%;
  margin-bottom: 30%; }

.work-with-us-page-text {
  font-weight: 300;
  margin-left: -15px;
  margin-right: -15px;
  padding: 30px;
  font-size: 16px;
  background-color: #fff; }
  .work-with-us-page-text a {
    font-size: 1.5em; }

@media (min-width: 768px) {
  .nuestro-servicio-scroll-icon {
    float: right;
    margin-top: 0;
    margin-right: 0; } }
@media (max-width: 768px) {
  .black-mobile {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700; } }

@media (min-width: 768px) {
  .black-desk {
    font-size: 28px;
    line-height: 35px;
    font-weight: 700; } }

.privacy-polices {
  color: #000000;
  margin-bottom: 70px;
  margin-left: 5px; }
  .privacy-polices h3 {
    color: #000000;
    font-size: 18px;
    font-weight: 700; }
  .privacy-polices h1 {
    line-height: 1em;
    font-size: 54px;
    margin-bottom: 40px; }
    .privacy-polices h1 strong {
      color: black; }
  .privacy-polices p {
    font-size: 14px; }
  .privacy-polices img {
    opacity: 0.5; }
  @media (max-width: 768px) {
    .privacy-polices {
      padding-left: 15px;
      padding-right: 15px; }
      .privacy-polices h3 {
        font-size: 20px; }
      .privacy-polices p {
        font-size: 16px; } }

.mouse-scroll-policies {
  margin-top: 54vh;
  margin-left: 83%; }
  @media (max-width: 768px) {
    .mouse-scroll-policies {
      display: none; } }

.img-width-100 img {
  width: 100%;
  height: auto; }

.hidden {
  display: none; }

.nopadding {
  padding: 0 !important;
  margin: 0 !important; }

.container {
  margin-top: 0 !important; }

.mariva-black {
  color: #000000; }
  .mariva-black h1, .mariva-black h2, .mariva-black h3, .mariva-black h4, .mariva-black h5, .mariva-black h6, .mariva-black p {
    color: #000000; }

.opacity-arrow img {
  opacity: 0.5; }

.no-margin-top {
  margin-top: 0 !important; }

.mariva-red-text h1 {
  color: #871719;
  line-height: 40px;
  font-size: 30px;
  font-weight: 400; }

.margin-top-20 {
  margin-top: 20px; }

@media (min-width: 768px) {
  .margin-top-40 {
    margin-top: 40px; } }

@media (max-width: 768px) {
  .margin-top-40-mobile {
    margin-top: 40px; } }

.margin-top-60 {
  margin-top: 80px; }

.margin-top-80 {
  margin-top: 80px; }

.margin-top-160 {
  margin-top: 160px; }

.margin-bot-60 {
  margin-bottom: 60px; }

.p-text-15px {
  font-size: 15px; }

@media (max-width: 768px) {
  .inner-pad-15-mbl {
    padding-left: 15px;
    padding-right: 15px; }

  .inner-pad-20-mbl {
    padding-left: 20px;
    padding-right: 20px; }

  .margin-top-100-mbl {
    margin-top: 100px; }

  .font-15-mbl {
    font-size: 15px; } }
@media (min-width: 768px) {
  .margin-top-40-dkp {
    margin-top: 40px; }

  .margin-top-50-dkp {
    margin-top: 50px; }

  .margin-top-60-dkp {
    margin-top: 60px; }

  .margin-top-70-dkp {
    margin-top: 70px; }

  .margin-top-80-dkp {
    margin-top: 80px; }

  .margin-top-180-dkp {
    margin-top: 180px; }

  .margin-top-500-dkp {
    margin-top: 500px; }

  .margin-bot-50-dkp {
    margin-bottom: 50px; }

  .margin-bot-60-dkp {
    margin-bottom: 60px; }

  .nopadding-dkp {
    padding: 0 !important;
    margin: 0 !important; }

  .inner-pad-5-dkp {
    padding-left: 5px;
    padding-right: 5px; }

  .inner-pad-10-dkp {
    padding-left: 10px;
    padding-right: 10px; }

  .inner-pad-30-dkp {
    padding-left: 30px;
    padding-right: 30px; } }
.no-padding {
  padding: 0; }

.inner-pad-10 {
  padding-left: 10px;
  padding-right: 10px; }

.inner-pad-15 {
  padding-left: 15px;
  padding-right: 15px; }

.inner-pad-30 {
  padding-left: 30px;
  padding-right: 30px; }

.border-left {
  border-left: 1px solid #c1c1c1; }

.border-right {
  border-right: 1px solid #c1c1c1; }

@media (max-width: 768px) {
  #about-community, #about-history, #about-principles {
    display: none; }

  #about-history .timeline-mobile {
    position: absolute;
    transform: scale(0.8);
    top: -70px;
    right: 25px; }
  #about-history .history-text {
    margin-left: 5%; }
    #about-history .history-text p {
      font-size: 1.5em; }

  .about-navigation {
    background-color: #bababa;
    margin-top: 20px;
    border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #f5f5f5;
    bottom: 0;
    z-index: 1500;
    color: #000000; }
    .about-navigation .nav-center-label {
      margin: 15px 0; }
      .about-navigation .nav-center-label .mariva-about-nav-tab {
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
        font-size: 18px; }
    .about-navigation .arrow {
      margin: 15px 0;
      filter: brightness(0); }
      .about-navigation .arrow img {
        height: 35px; } }
#about-principles .title h1 {
  font-size: 20px;
  color: black; }
  #about-principles .title h1 strong {
    font-weight: 400; }
#about-principles .principles-list {
  color: black; }
  #about-principles .principles-list p {
    font-weight: 400;
    font-size: 13px; }
    #about-principles .principles-list p strong {
      font-size: 15px; }
    @media (max-width: 768px) {
      #about-principles .principles-list p {
        font-size: 15px;
        margin-left: 10px;
        margin-right: 10px; }
        #about-principles .principles-list p br {
          line-height: 2em; } }
  @media (min-width: 768px) {
    #about-principles .principles-list img {
      display: none; } }
  @media (max-width: 768px) {
    #about-principles .principles-list {
      padding: 15px;
      font-size: 20px; } }
#about-principles .floating-top-xs {
  font-size: 15px; }
  @media (max-width: 768px) {
    #about-principles .floating-top-xs {
      min-height: 100px;
      margin-top: -15%;
      margin-left: 10px;
      margin-right: 10px; } }
#about-principles .ap-image-section {
  margin-left: -15px;
  margin-right: -15px; }
@media (min-width: 768px) {
  #about-principles {
    padding-top: 75px; } }

#about-history .history-text h1 {
  color: black; }
  @media (max-width: 768px) {
    #about-history .history-text h1 {
      display: none; } }
#about-history .history-text img {
  width: 15px;
  margin-bottom: 30px; }
  @media (max-width: 768px) {
    #about-history .history-text img {
      width: 40px;
      margin-top: 30px; } }
@media (max-width: 768px) {
  #about-history .history-image img {
    min-width: 100%; } }
#about-history .timeline-desktop {
  position: absolute;
  top: 0;
  right: -40px; }
#about-history .about-padd-top {
  color: black; }
  @media (max-width: 768px) {
    #about-history .about-padd-top {
      font-size: 20px; } }
  @media (min-width: 768px) {
    #about-history .about-padd-top {
      padding-top: 85%; } }
@media (min-width: 768px) {
  #about-history {
    padding-top: 75px; } }

#about-community {
  color: black; }
  #about-community .title h1 {
    color: black;
    font-weight: 300;
    font-size: 50px; }
    #about-community .title h1 strong {
      font-weight: 400; }
  @media (min-width: 768px) {
    #about-community {
      padding-top: 75px; } }
  @media (max-width: 768px) {
    #about-community {
      font-size: 20px; }
      #about-community p.first {
        color: black; } }

.title-about h1 {
  margin-top: 0;
  margin-bottom: 5px;
  line-height: 45px;
  color: #000;
  font-weight: 300;
  font-size: 50px; }
.title-about strong {
  font-weight: 400; }

.mariva-logos-gris .row-margin {
  margin-top: -55px; }
  .mariva-logos-gris .row-margin .mariva-nosotros-logo:hover {
    filter: alpha(opacity=0.1);
    -moz-opacity: 0.1;
    opacity: 0.1; }

.mariva-nosotros-logo {
  display: inline-block; }
  @media (max-width: 768px) {
    .mariva-nosotros-logo {
      width: 45%;
      margin-right: 7px;
      margin-left: 7px; } }
  @media (min-width: 768px) {
    .mariva-nosotros-logo {
      margin-right: 15px; }
      .mariva-nosotros-logo img {
        height: 30px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .mariva-nosotros-logo .image-outer-box {
    height: 30px; }
    .mariva-nosotros-logo .image-outer-box img[src$=".svg"] {
      height: 100%; } }
.ie9 .mariva-nosotros-logo .image-outer-box {
  height: 30px; }
  .ie9 .mariva-nosotros-logo .image-outer-box img[src$=".svg"] {
    height: 100%; }

.ap-list-filterbox {
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
  padding: 15px;
  border: 1px solid #ddd; }

.ap-list-filter-labels-wrapper,
.ap-list-filter-archive {
  display: none; }

.ap-list-filterbox-search .input {
  margin: 0; }

.ap-list-filter-labels {
  margin-top: 0;
  padding-left: 0;
  list-style: outside none none; }

.ap-list-filter-labels-wrapper span.badge {
  margin-top: 2px; }
.ap-list-filter-labels-wrapper .active span.badge {
  font-weight: bold; }

.ap-list-filter-labels-wrapper ul {
  margin-top: 13px;
  margin-bottom: 0; }

.ap-list-filter-archive ul {
  margin-top: 0;
  margin-bottom: 0; }

.ap-list-filterbtn-year {
  margin-top: 15px; }
  .ap-list-filterbtn-year .fa {
    padding-top: 3px; }

.ap-list-filter-year {
  margin-top: 10px;
  padding-left: 0;
  list-style: outside none none; }
  .ap-list-filter-year > li {
    display: inline-block;
    padding-top: 5px;
    width: 16.666666%;
    padding-bottom: 5px;
    font-size: 12px;
    text-align: center; }
  .ap-list-filter-year li.active a {
    font-weight: bold; }

.ap-compact {
  color: #555; }
  .ap-compact.ap-teaser {
    margin-bottom: 10px; }
  .ap-compact .teaser-date {
    font-style: italic; }
  .ap-compact .teaser-head {
    margin-top: 5px;
    margin-bottom: 5px; }
  .ap-compact .teaser-text {
    margin-bottom: 5px; }
  .ap-compact .btn.teaser-btn {
    font-size: 12px;
    padding: 2px 10px;
    min-width: 0; }
  .ap-compact a:hover {
    color: #ff264a;
    text-decoration: none; }

/* Pagination
------------------------------------*/
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 0; }

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #b31b34;
  border-color: #b31b34; }

.pagination li a {
  color: #a8adb4;
  border-color: #e2e3e6;
  padding: 5px 15px; }

.pagination li a:hover {
  color: #fff;
  background: #ff264a;
  border-color: #ff264a; }

.list-option {
  min-height: 36px;
  margin-bottom: 20px; }
  .list-option .dropdown-menu {
    border-radius: 0; }
    .list-option .dropdown-menu .divider {
      margin: 5px 0; }

.color-white h3.teaser-head,
.color-white .teaser-head {
  color: #fff; }
.color-white a {
  color: #fff; }
.color-white .ap-teaser a:hover,
.color-white a:hover {
  color: #ff264a;
  text-decoration: none; }
.color-white .pagination > .active > a, .color-white .pagination > .active > span,
.color-white .pagination > .active > a:hover,
.color-white .pagination > .active > span:hover,
.color-white .pagination > .active > a:focus,
.color-white .pagination > .active > span:focus {
  background-color: #fff;
  border-color: #fff; }
.color-white .pagination li a:hover {
  background: #ff264a;
  border-color: #ff264a; }
.color-white .pagination > button {
  background-color: #fff;
  border-color: #fff; }
.color-white .list-options .btn,
.color-white .list-options .dropdown-menu > .active > a {
  background: #fff;
  border-color: #fff;
  color: #555; }
.color-white .list-options button:hover, .color-white .list-options button:focus {
  background: #ff264a;
  border-color: #ff264a; }
div .color-white.ap-list-filters .fa.ap-fa-label,
.color-white .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #555; }
div .color-white.ap-list-filters a,
.color-white .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-white.ap-list-filters button,
.color-white .ap-list-filters button {
  background: #fff;
  border-color: #fff;
  color: #555; }
  div .color-white.ap-list-filters button:hover, div .color-white.ap-list-filters button:focus,
  .color-white .ap-list-filters button:hover,
  .color-white .ap-list-filters button:focus {
    background: #ff264a;
    border-color: #ff264a; }
.color-white.ap-list-filter-labels-wrapper span.badge {
  color: #555;
  background: #fff; }
  .color-white.ap-list-filter-labels-wrapper span.badge:hover, .color-white.ap-list-filter-labels-wrapper span.badge:focus {
    background: #ff264a;
    border-color: #ff264a;
    color: #555 !important; }
.color-white.ap-list-filterbox .ap-list-filter-archive button {
  background: #fff;
  border-color: #fff;
  color: #555 !important; }
  .color-white.ap-list-filterbox .ap-list-filter-archive button:hover, .color-white.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #ff264a;
    border-color: #ff264a;
    color: #555 !important; }
.color-white.ap-list-filterbox li.active {
  font-weight: bold; }
.color-white.ap-list-filterbox .ap-list-filter-year > li:hover, .color-white.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #ff264a;
  border-color: #ff264a;
  color: #555 !important; }

.color-grey-lighter h3.teaser-head,
.color-grey-lighter .teaser-head {
  color: #f7f8f8; }
.color-grey-lighter a {
  color: #f7f8f8; }
.color-grey-lighter .ap-teaser a:hover,
.color-grey-lighter a:hover {
  color: #e2e3e6;
  text-decoration: none; }
.color-grey-lighter .pagination > .active > a, .color-grey-lighter .pagination > .active > span,
.color-grey-lighter .pagination > .active > a:hover,
.color-grey-lighter .pagination > .active > span:hover,
.color-grey-lighter .pagination > .active > a:focus,
.color-grey-lighter .pagination > .active > span:focus {
  background-color: #f7f8f8;
  border-color: #f7f8f8; }
.color-grey-lighter .pagination li a:hover {
  background: #e2e3e6;
  border-color: #e2e3e6; }
.color-grey-lighter .pagination > button {
  background-color: #f7f8f8;
  border-color: #f7f8f8; }
.color-grey-lighter .list-options .btn,
.color-grey-lighter .list-options .dropdown-menu > .active > a {
  background: #f7f8f8;
  border-color: #f7f8f8;
  color: #555; }
.color-grey-lighter .list-options button:hover, .color-grey-lighter .list-options button:focus {
  background: #e2e3e6;
  border-color: #e2e3e6; }
div .color-grey-lighter.ap-list-filters .fa.ap-fa-label,
.color-grey-lighter .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #555; }
div .color-grey-lighter.ap-list-filters a,
.color-grey-lighter .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-grey-lighter.ap-list-filters button,
.color-grey-lighter .ap-list-filters button {
  background: #f7f8f8;
  border-color: #f7f8f8;
  color: #555; }
  div .color-grey-lighter.ap-list-filters button:hover, div .color-grey-lighter.ap-list-filters button:focus,
  .color-grey-lighter .ap-list-filters button:hover,
  .color-grey-lighter .ap-list-filters button:focus {
    background: #e2e3e6;
    border-color: #e2e3e6; }
.color-grey-lighter.ap-list-filter-labels-wrapper span.badge {
  color: #555;
  background: #f7f8f8; }
  .color-grey-lighter.ap-list-filter-labels-wrapper span.badge:hover, .color-grey-lighter.ap-list-filter-labels-wrapper span.badge:focus {
    background: #e2e3e6;
    border-color: #e2e3e6;
    color: #555 !important; }
.color-grey-lighter.ap-list-filterbox .ap-list-filter-archive button {
  background: #f7f8f8;
  border-color: #f7f8f8;
  color: #555 !important; }
  .color-grey-lighter.ap-list-filterbox .ap-list-filter-archive button:hover, .color-grey-lighter.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #e2e3e6;
    border-color: #e2e3e6;
    color: #555 !important; }
.color-grey-lighter.ap-list-filterbox li.active {
  font-weight: bold; }
.color-grey-lighter.ap-list-filterbox .ap-list-filter-year > li:hover, .color-grey-lighter.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #e2e3e6;
  border-color: #e2e3e6;
  color: #555 !important; }

.color-grey-light h3.teaser-head,
.color-grey-light .teaser-head {
  color: #e2e3e6; }
.color-grey-light a {
  color: #e2e3e6; }
.color-grey-light .ap-teaser a:hover,
.color-grey-light a:hover {
  color: #f7f8f8;
  text-decoration: none; }
.color-grey-light .pagination > .active > a, .color-grey-light .pagination > .active > span,
.color-grey-light .pagination > .active > a:hover,
.color-grey-light .pagination > .active > span:hover,
.color-grey-light .pagination > .active > a:focus,
.color-grey-light .pagination > .active > span:focus {
  background-color: #e2e3e6;
  border-color: #e2e3e6; }
.color-grey-light .pagination li a:hover {
  background: #f7f8f8;
  border-color: #f7f8f8; }
.color-grey-light .pagination > button {
  background-color: #e2e3e6;
  border-color: #e2e3e6; }
.color-grey-light .list-options .btn,
.color-grey-light .list-options .dropdown-menu > .active > a {
  background: #e2e3e6;
  border-color: #e2e3e6;
  color: #555; }
.color-grey-light .list-options button:hover, .color-grey-light .list-options button:focus {
  background: #f7f8f8;
  border-color: #f7f8f8; }
div .color-grey-light.ap-list-filters .fa.ap-fa-label,
.color-grey-light .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #555; }
div .color-grey-light.ap-list-filters a,
.color-grey-light .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-grey-light.ap-list-filters button,
.color-grey-light .ap-list-filters button {
  background: #e2e3e6;
  border-color: #e2e3e6;
  color: #555; }
  div .color-grey-light.ap-list-filters button:hover, div .color-grey-light.ap-list-filters button:focus,
  .color-grey-light .ap-list-filters button:hover,
  .color-grey-light .ap-list-filters button:focus {
    background: #f7f8f8;
    border-color: #f7f8f8; }
.color-grey-light.ap-list-filter-labels-wrapper span.badge {
  color: #555;
  background: #e2e3e6; }
  .color-grey-light.ap-list-filter-labels-wrapper span.badge:hover, .color-grey-light.ap-list-filter-labels-wrapper span.badge:focus {
    background: #f7f8f8;
    border-color: #f7f8f8;
    color: #555 !important; }
.color-grey-light.ap-list-filterbox .ap-list-filter-archive button {
  background: #e2e3e6;
  border-color: #e2e3e6;
  color: #555 !important; }
  .color-grey-light.ap-list-filterbox .ap-list-filter-archive button:hover, .color-grey-light.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #f7f8f8;
    border-color: #f7f8f8;
    color: #555 !important; }
.color-grey-light.ap-list-filterbox li.active {
  font-weight: bold; }
.color-grey-light.ap-list-filterbox .ap-list-filter-year > li:hover, .color-grey-light.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #f7f8f8;
  border-color: #f7f8f8;
  color: #555 !important; }

.color-grey h3.teaser-head,
.color-grey .teaser-head {
  color: #a8adb4; }
.color-grey a {
  color: #a8adb4; }
.color-grey .ap-teaser a:hover,
.color-grey a:hover {
  color: #e2e3e6;
  text-decoration: none; }
.color-grey .pagination > .active > a, .color-grey .pagination > .active > span,
.color-grey .pagination > .active > a:hover,
.color-grey .pagination > .active > span:hover,
.color-grey .pagination > .active > a:focus,
.color-grey .pagination > .active > span:focus {
  background-color: #a8adb4;
  border-color: #a8adb4; }
.color-grey .pagination li a:hover {
  background: #e2e3e6;
  border-color: #e2e3e6; }
.color-grey .pagination > button {
  background-color: #a8adb4;
  border-color: #a8adb4; }
.color-grey .list-options .btn,
.color-grey .list-options .dropdown-menu > .active > a {
  background: #a8adb4;
  border-color: #a8adb4;
  color: #fff; }
.color-grey .list-options button:hover, .color-grey .list-options button:focus {
  background: #e2e3e6;
  border-color: #e2e3e6; }
div .color-grey.ap-list-filters .fa.ap-fa-label,
.color-grey .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-grey.ap-list-filters a,
.color-grey .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-grey.ap-list-filters button,
.color-grey .ap-list-filters button {
  background: #a8adb4;
  border-color: #a8adb4;
  color: #fff; }
  div .color-grey.ap-list-filters button:hover, div .color-grey.ap-list-filters button:focus,
  .color-grey .ap-list-filters button:hover,
  .color-grey .ap-list-filters button:focus {
    background: #e2e3e6;
    border-color: #e2e3e6; }
.color-grey.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #a8adb4; }
  .color-grey.ap-list-filter-labels-wrapper span.badge:hover, .color-grey.ap-list-filter-labels-wrapper span.badge:focus {
    background: #e2e3e6;
    border-color: #e2e3e6;
    color: #fff !important; }
.color-grey.ap-list-filterbox .ap-list-filter-archive button {
  background: #a8adb4;
  border-color: #a8adb4;
  color: #fff !important; }
  .color-grey.ap-list-filterbox .ap-list-filter-archive button:hover, .color-grey.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #e2e3e6;
    border-color: #e2e3e6;
    color: #fff !important; }
.color-grey.ap-list-filterbox li.active {
  font-weight: bold; }
.color-grey.ap-list-filterbox .ap-list-filter-year > li:hover, .color-grey.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #e2e3e6;
  border-color: #e2e3e6;
  color: #fff !important; }

.color-grey-dark h3.teaser-head,
.color-grey-dark .teaser-head {
  color: #7f8691; }
.color-grey-dark a {
  color: #7f8691; }
.color-grey-dark .ap-teaser a:hover,
.color-grey-dark a:hover {
  color: #a8adb4;
  text-decoration: none; }
.color-grey-dark .pagination > .active > a, .color-grey-dark .pagination > .active > span,
.color-grey-dark .pagination > .active > a:hover,
.color-grey-dark .pagination > .active > span:hover,
.color-grey-dark .pagination > .active > a:focus,
.color-grey-dark .pagination > .active > span:focus {
  background-color: #7f8691;
  border-color: #7f8691; }
.color-grey-dark .pagination li a:hover {
  background: #a8adb4;
  border-color: #a8adb4; }
.color-grey-dark .pagination > button {
  background-color: #7f8691;
  border-color: #7f8691; }
.color-grey-dark .list-options .btn,
.color-grey-dark .list-options .dropdown-menu > .active > a {
  background: #7f8691;
  border-color: #7f8691;
  color: #fff; }
.color-grey-dark .list-options button:hover, .color-grey-dark .list-options button:focus {
  background: #a8adb4;
  border-color: #a8adb4; }
div .color-grey-dark.ap-list-filters .fa.ap-fa-label,
.color-grey-dark .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-grey-dark.ap-list-filters a,
.color-grey-dark .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-grey-dark.ap-list-filters button,
.color-grey-dark .ap-list-filters button {
  background: #7f8691;
  border-color: #7f8691;
  color: #fff; }
  div .color-grey-dark.ap-list-filters button:hover, div .color-grey-dark.ap-list-filters button:focus,
  .color-grey-dark .ap-list-filters button:hover,
  .color-grey-dark .ap-list-filters button:focus {
    background: #a8adb4;
    border-color: #a8adb4; }
.color-grey-dark.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #7f8691; }
  .color-grey-dark.ap-list-filter-labels-wrapper span.badge:hover, .color-grey-dark.ap-list-filter-labels-wrapper span.badge:focus {
    background: #a8adb4;
    border-color: #a8adb4;
    color: #fff !important; }
.color-grey-dark.ap-list-filterbox .ap-list-filter-archive button {
  background: #7f8691;
  border-color: #7f8691;
  color: #fff !important; }
  .color-grey-dark.ap-list-filterbox .ap-list-filter-archive button:hover, .color-grey-dark.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #a8adb4;
    border-color: #a8adb4;
    color: #fff !important; }
.color-grey-dark.ap-list-filterbox li.active {
  font-weight: bold; }
.color-grey-dark.ap-list-filterbox .ap-list-filter-year > li:hover, .color-grey-dark.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #a8adb4;
  border-color: #a8adb4;
  color: #fff !important; }

.color-grey-darker h3.teaser-head,
.color-grey-darker .teaser-head {
  color: #5a6069; }
.color-grey-darker a {
  color: #5a6069; }
.color-grey-darker .ap-teaser a:hover,
.color-grey-darker a:hover {
  color: #7f8691;
  text-decoration: none; }
.color-grey-darker .pagination > .active > a, .color-grey-darker .pagination > .active > span,
.color-grey-darker .pagination > .active > a:hover,
.color-grey-darker .pagination > .active > span:hover,
.color-grey-darker .pagination > .active > a:focus,
.color-grey-darker .pagination > .active > span:focus {
  background-color: #5a6069;
  border-color: #5a6069; }
.color-grey-darker .pagination li a:hover {
  background: #7f8691;
  border-color: #7f8691; }
.color-grey-darker .pagination > button {
  background-color: #5a6069;
  border-color: #5a6069; }
.color-grey-darker .list-options .btn,
.color-grey-darker .list-options .dropdown-menu > .active > a {
  background: #5a6069;
  border-color: #5a6069;
  color: #fff; }
.color-grey-darker .list-options button:hover, .color-grey-darker .list-options button:focus {
  background: #7f8691;
  border-color: #7f8691; }
div .color-grey-darker.ap-list-filters .fa.ap-fa-label,
.color-grey-darker .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-grey-darker.ap-list-filters a,
.color-grey-darker .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-grey-darker.ap-list-filters button,
.color-grey-darker .ap-list-filters button {
  background: #5a6069;
  border-color: #5a6069;
  color: #fff; }
  div .color-grey-darker.ap-list-filters button:hover, div .color-grey-darker.ap-list-filters button:focus,
  .color-grey-darker .ap-list-filters button:hover,
  .color-grey-darker .ap-list-filters button:focus {
    background: #7f8691;
    border-color: #7f8691; }
.color-grey-darker.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #5a6069; }
  .color-grey-darker.ap-list-filter-labels-wrapper span.badge:hover, .color-grey-darker.ap-list-filter-labels-wrapper span.badge:focus {
    background: #7f8691;
    border-color: #7f8691;
    color: #fff !important; }
.color-grey-darker.ap-list-filterbox .ap-list-filter-archive button {
  background: #5a6069;
  border-color: #5a6069;
  color: #fff !important; }
  .color-grey-darker.ap-list-filterbox .ap-list-filter-archive button:hover, .color-grey-darker.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #7f8691;
    border-color: #7f8691;
    color: #fff !important; }
.color-grey-darker.ap-list-filterbox li.active {
  font-weight: bold; }
.color-grey-darker.ap-list-filterbox .ap-list-filter-year > li:hover, .color-grey-darker.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #7f8691;
  border-color: #7f8691;
  color: #fff !important; }

.color-black h3.teaser-head,
.color-black .teaser-head {
  color: #000; }
.color-black a {
  color: #000; }
.color-black .ap-teaser a:hover,
.color-black a:hover {
  color: #7f8691;
  text-decoration: none; }
.color-black .pagination > .active > a, .color-black .pagination > .active > span,
.color-black .pagination > .active > a:hover,
.color-black .pagination > .active > span:hover,
.color-black .pagination > .active > a:focus,
.color-black .pagination > .active > span:focus {
  background-color: #000;
  border-color: #000; }
.color-black .pagination li a:hover {
  background: #7f8691;
  border-color: #7f8691; }
.color-black .pagination > button {
  background-color: #000;
  border-color: #000; }
.color-black .list-options .btn,
.color-black .list-options .dropdown-menu > .active > a {
  background: #000;
  border-color: #000;
  color: #fff; }
.color-black .list-options button:hover, .color-black .list-options button:focus {
  background: #7f8691;
  border-color: #7f8691; }
div .color-black.ap-list-filters .fa.ap-fa-label,
.color-black .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-black.ap-list-filters a,
.color-black .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-black.ap-list-filters button,
.color-black .ap-list-filters button {
  background: #000;
  border-color: #000;
  color: #fff; }
  div .color-black.ap-list-filters button:hover, div .color-black.ap-list-filters button:focus,
  .color-black .ap-list-filters button:hover,
  .color-black .ap-list-filters button:focus {
    background: #7f8691;
    border-color: #7f8691; }
.color-black.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #000; }
  .color-black.ap-list-filter-labels-wrapper span.badge:hover, .color-black.ap-list-filter-labels-wrapper span.badge:focus {
    background: #7f8691;
    border-color: #7f8691;
    color: #fff !important; }
.color-black.ap-list-filterbox .ap-list-filter-archive button {
  background: #000;
  border-color: #000;
  color: #fff !important; }
  .color-black.ap-list-filterbox .ap-list-filter-archive button:hover, .color-black.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #7f8691;
    border-color: #7f8691;
    color: #fff !important; }
.color-black.ap-list-filterbox li.active {
  font-weight: bold; }
.color-black.ap-list-filterbox .ap-list-filter-year > li:hover, .color-black.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #7f8691;
  border-color: #7f8691;
  color: #fff !important; }

.color-blue-light h3.teaser-head,
.color-blue-light .teaser-head {
  color: #2197db; }
.color-blue-light a {
  color: #2197db; }
.color-blue-light .ap-teaser a:hover,
.color-blue-light a:hover {
  color: #144382;
  text-decoration: none; }
.color-blue-light .pagination > .active > a, .color-blue-light .pagination > .active > span,
.color-blue-light .pagination > .active > a:hover,
.color-blue-light .pagination > .active > span:hover,
.color-blue-light .pagination > .active > a:focus,
.color-blue-light .pagination > .active > span:focus {
  background-color: #2197db;
  border-color: #2197db; }
.color-blue-light .pagination li a:hover {
  background: #144382;
  border-color: #144382; }
.color-blue-light .pagination > button {
  background-color: #2197db;
  border-color: #2197db; }
.color-blue-light .list-options .btn,
.color-blue-light .list-options .dropdown-menu > .active > a {
  background: #2197db;
  border-color: #2197db;
  color: #fff; }
.color-blue-light .list-options button:hover, .color-blue-light .list-options button:focus {
  background: #144382;
  border-color: #144382; }
div .color-blue-light.ap-list-filters .fa.ap-fa-label,
.color-blue-light .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-blue-light.ap-list-filters a,
.color-blue-light .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-blue-light.ap-list-filters button,
.color-blue-light .ap-list-filters button {
  background: #2197db;
  border-color: #2197db;
  color: #fff; }
  div .color-blue-light.ap-list-filters button:hover, div .color-blue-light.ap-list-filters button:focus,
  .color-blue-light .ap-list-filters button:hover,
  .color-blue-light .ap-list-filters button:focus {
    background: #144382;
    border-color: #144382; }
.color-blue-light.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #2197db; }
  .color-blue-light.ap-list-filter-labels-wrapper span.badge:hover, .color-blue-light.ap-list-filter-labels-wrapper span.badge:focus {
    background: #144382;
    border-color: #144382;
    color: #fff !important; }
.color-blue-light.ap-list-filterbox .ap-list-filter-archive button {
  background: #2197db;
  border-color: #2197db;
  color: #fff !important; }
  .color-blue-light.ap-list-filterbox .ap-list-filter-archive button:hover, .color-blue-light.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #144382;
    border-color: #144382;
    color: #fff !important; }
.color-blue-light.ap-list-filterbox li.active {
  font-weight: bold; }
.color-blue-light.ap-list-filterbox .ap-list-filter-year > li:hover, .color-blue-light.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #144382;
  border-color: #144382;
  color: #fff !important; }

.color-blue h3.teaser-head,
.color-blue .teaser-head {
  color: #144382; }
.color-blue a {
  color: #144382; }
.color-blue .ap-teaser a:hover,
.color-blue a:hover {
  color: #2197db;
  text-decoration: none; }
.color-blue .pagination > .active > a, .color-blue .pagination > .active > span,
.color-blue .pagination > .active > a:hover,
.color-blue .pagination > .active > span:hover,
.color-blue .pagination > .active > a:focus,
.color-blue .pagination > .active > span:focus {
  background-color: #144382;
  border-color: #144382; }
.color-blue .pagination li a:hover {
  background: #2197db;
  border-color: #2197db; }
.color-blue .pagination > button {
  background-color: #144382;
  border-color: #144382; }
.color-blue .list-options .btn,
.color-blue .list-options .dropdown-menu > .active > a {
  background: #144382;
  border-color: #144382;
  color: #fff; }
.color-blue .list-options button:hover, .color-blue .list-options button:focus {
  background: #2197db;
  border-color: #2197db; }
div .color-blue.ap-list-filters .fa.ap-fa-label,
.color-blue .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-blue.ap-list-filters a,
.color-blue .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-blue.ap-list-filters button,
.color-blue .ap-list-filters button {
  background: #144382;
  border-color: #144382;
  color: #fff; }
  div .color-blue.ap-list-filters button:hover, div .color-blue.ap-list-filters button:focus,
  .color-blue .ap-list-filters button:hover,
  .color-blue .ap-list-filters button:focus {
    background: #2197db;
    border-color: #2197db; }
.color-blue.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #144382; }
  .color-blue.ap-list-filter-labels-wrapper span.badge:hover, .color-blue.ap-list-filter-labels-wrapper span.badge:focus {
    background: #2197db;
    border-color: #2197db;
    color: #fff !important; }
.color-blue.ap-list-filterbox .ap-list-filter-archive button {
  background: #144382;
  border-color: #144382;
  color: #fff !important; }
  .color-blue.ap-list-filterbox .ap-list-filter-archive button:hover, .color-blue.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #2197db;
    border-color: #2197db;
    color: #fff !important; }
.color-blue.ap-list-filterbox li.active {
  font-weight: bold; }
.color-blue.ap-list-filterbox .ap-list-filter-year > li:hover, .color-blue.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #2197db;
  border-color: #2197db;
  color: #fff !important; }

.color-blue-dark h3.teaser-head,
.color-blue-dark .teaser-head {
  color: #0d2c56; }
.color-blue-dark a {
  color: #0d2c56; }
.color-blue-dark .ap-teaser a:hover,
.color-blue-dark a:hover {
  color: #144382;
  text-decoration: none; }
.color-blue-dark .pagination > .active > a, .color-blue-dark .pagination > .active > span,
.color-blue-dark .pagination > .active > a:hover,
.color-blue-dark .pagination > .active > span:hover,
.color-blue-dark .pagination > .active > a:focus,
.color-blue-dark .pagination > .active > span:focus {
  background-color: #0d2c56;
  border-color: #0d2c56; }
.color-blue-dark .pagination li a:hover {
  background: #144382;
  border-color: #144382; }
.color-blue-dark .pagination > button {
  background-color: #0d2c56;
  border-color: #0d2c56; }
.color-blue-dark .list-options .btn,
.color-blue-dark .list-options .dropdown-menu > .active > a {
  background: #0d2c56;
  border-color: #0d2c56;
  color: #fff; }
.color-blue-dark .list-options button:hover, .color-blue-dark .list-options button:focus {
  background: #144382;
  border-color: #144382; }
div .color-blue-dark.ap-list-filters .fa.ap-fa-label,
.color-blue-dark .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-blue-dark.ap-list-filters a,
.color-blue-dark .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-blue-dark.ap-list-filters button,
.color-blue-dark .ap-list-filters button {
  background: #0d2c56;
  border-color: #0d2c56;
  color: #fff; }
  div .color-blue-dark.ap-list-filters button:hover, div .color-blue-dark.ap-list-filters button:focus,
  .color-blue-dark .ap-list-filters button:hover,
  .color-blue-dark .ap-list-filters button:focus {
    background: #144382;
    border-color: #144382; }
.color-blue-dark.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #0d2c56; }
  .color-blue-dark.ap-list-filter-labels-wrapper span.badge:hover, .color-blue-dark.ap-list-filter-labels-wrapper span.badge:focus {
    background: #144382;
    border-color: #144382;
    color: #fff !important; }
.color-blue-dark.ap-list-filterbox .ap-list-filter-archive button {
  background: #0d2c56;
  border-color: #0d2c56;
  color: #fff !important; }
  .color-blue-dark.ap-list-filterbox .ap-list-filter-archive button:hover, .color-blue-dark.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #144382;
    border-color: #144382;
    color: #fff !important; }
.color-blue-dark.ap-list-filterbox li.active {
  font-weight: bold; }
.color-blue-dark.ap-list-filterbox .ap-list-filter-year > li:hover, .color-blue-dark.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #144382;
  border-color: #144382;
  color: #fff !important; }

.color-blue-darker h3.teaser-head,
.color-blue-darker .teaser-head {
  color: #0a2140; }
.color-blue-darker a {
  color: #0a2140; }
.color-blue-darker .ap-teaser a:hover,
.color-blue-darker a:hover {
  color: #0d2c56;
  text-decoration: none; }
.color-blue-darker .pagination > .active > a, .color-blue-darker .pagination > .active > span,
.color-blue-darker .pagination > .active > a:hover,
.color-blue-darker .pagination > .active > span:hover,
.color-blue-darker .pagination > .active > a:focus,
.color-blue-darker .pagination > .active > span:focus {
  background-color: #0a2140;
  border-color: #0a2140; }
.color-blue-darker .pagination li a:hover {
  background: #0d2c56;
  border-color: #0d2c56; }
.color-blue-darker .pagination > button {
  background-color: #0a2140;
  border-color: #0a2140; }
.color-blue-darker .list-options .btn,
.color-blue-darker .list-options .dropdown-menu > .active > a {
  background: #0a2140;
  border-color: #0a2140;
  color: #fff; }
.color-blue-darker .list-options button:hover, .color-blue-darker .list-options button:focus {
  background: #0d2c56;
  border-color: #0d2c56; }
div .color-blue-darker.ap-list-filters .fa.ap-fa-label,
.color-blue-darker .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-blue-darker.ap-list-filters a,
.color-blue-darker .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-blue-darker.ap-list-filters button,
.color-blue-darker .ap-list-filters button {
  background: #0a2140;
  border-color: #0a2140;
  color: #fff; }
  div .color-blue-darker.ap-list-filters button:hover, div .color-blue-darker.ap-list-filters button:focus,
  .color-blue-darker .ap-list-filters button:hover,
  .color-blue-darker .ap-list-filters button:focus {
    background: #0d2c56;
    border-color: #0d2c56; }
.color-blue-darker.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #0a2140; }
  .color-blue-darker.ap-list-filter-labels-wrapper span.badge:hover, .color-blue-darker.ap-list-filter-labels-wrapper span.badge:focus {
    background: #0d2c56;
    border-color: #0d2c56;
    color: #fff !important; }
.color-blue-darker.ap-list-filterbox .ap-list-filter-archive button {
  background: #0a2140;
  border-color: #0a2140;
  color: #fff !important; }
  .color-blue-darker.ap-list-filterbox .ap-list-filter-archive button:hover, .color-blue-darker.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #0d2c56;
    border-color: #0d2c56;
    color: #fff !important; }
.color-blue-darker.ap-list-filterbox li.active {
  font-weight: bold; }
.color-blue-darker.ap-list-filterbox .ap-list-filter-year > li:hover, .color-blue-darker.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #0d2c56;
  border-color: #0d2c56;
  color: #fff !important; }

.color-cyan h3.teaser-head,
.color-cyan .teaser-head {
  color: #1bb39a; }
.color-cyan a {
  color: #1bb39a; }
.color-cyan .ap-teaser a:hover,
.color-cyan a:hover {
  color: #187b8e;
  text-decoration: none; }
.color-cyan .pagination > .active > a, .color-cyan .pagination > .active > span,
.color-cyan .pagination > .active > a:hover,
.color-cyan .pagination > .active > span:hover,
.color-cyan .pagination > .active > a:focus,
.color-cyan .pagination > .active > span:focus {
  background-color: #1bb39a;
  border-color: #1bb39a; }
.color-cyan .pagination li a:hover {
  background: #187b8e;
  border-color: #187b8e; }
.color-cyan .pagination > button {
  background-color: #1bb39a;
  border-color: #1bb39a; }
.color-cyan .list-options .btn,
.color-cyan .list-options .dropdown-menu > .active > a {
  background: #1bb39a;
  border-color: #1bb39a;
  color: #fff; }
.color-cyan .list-options button:hover, .color-cyan .list-options button:focus {
  background: #187b8e;
  border-color: #187b8e; }
div .color-cyan.ap-list-filters .fa.ap-fa-label,
.color-cyan .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-cyan.ap-list-filters a,
.color-cyan .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-cyan.ap-list-filters button,
.color-cyan .ap-list-filters button {
  background: #1bb39a;
  border-color: #1bb39a;
  color: #fff; }
  div .color-cyan.ap-list-filters button:hover, div .color-cyan.ap-list-filters button:focus,
  .color-cyan .ap-list-filters button:hover,
  .color-cyan .ap-list-filters button:focus {
    background: #187b8e;
    border-color: #187b8e; }
.color-cyan.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #1bb39a; }
  .color-cyan.ap-list-filter-labels-wrapper span.badge:hover, .color-cyan.ap-list-filter-labels-wrapper span.badge:focus {
    background: #187b8e;
    border-color: #187b8e;
    color: #fff !important; }
.color-cyan.ap-list-filterbox .ap-list-filter-archive button {
  background: #1bb39a;
  border-color: #1bb39a;
  color: #fff !important; }
  .color-cyan.ap-list-filterbox .ap-list-filter-archive button:hover, .color-cyan.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #187b8e;
    border-color: #187b8e;
    color: #fff !important; }
.color-cyan.ap-list-filterbox li.active {
  font-weight: bold; }
.color-cyan.ap-list-filterbox .ap-list-filter-year > li:hover, .color-cyan.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #187b8e;
  border-color: #187b8e;
  color: #fff !important; }

.color-red-light h3.teaser-head,
.color-red-light .teaser-head {
  color: #ff264a; }
.color-red-light a {
  color: #ff264a; }
.color-red-light .ap-teaser a:hover,
.color-red-light a:hover {
  color: #b31b34;
  text-decoration: none; }
.color-red-light .pagination > .active > a, .color-red-light .pagination > .active > span,
.color-red-light .pagination > .active > a:hover,
.color-red-light .pagination > .active > span:hover,
.color-red-light .pagination > .active > a:focus,
.color-red-light .pagination > .active > span:focus {
  background-color: #ff264a;
  border-color: #ff264a; }
.color-red-light .pagination li a:hover {
  background: #b31b34;
  border-color: #b31b34; }
.color-red-light .pagination > button {
  background-color: #ff264a;
  border-color: #ff264a; }
.color-red-light .list-options .btn,
.color-red-light .list-options .dropdown-menu > .active > a {
  background: #ff264a;
  border-color: #ff264a;
  color: #fff; }
.color-red-light .list-options button:hover, .color-red-light .list-options button:focus {
  background: #b31b34;
  border-color: #b31b34; }
div .color-red-light.ap-list-filters .fa.ap-fa-label,
.color-red-light .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-red-light.ap-list-filters a,
.color-red-light .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-red-light.ap-list-filters button,
.color-red-light .ap-list-filters button {
  background: #ff264a;
  border-color: #ff264a;
  color: #fff; }
  div .color-red-light.ap-list-filters button:hover, div .color-red-light.ap-list-filters button:focus,
  .color-red-light .ap-list-filters button:hover,
  .color-red-light .ap-list-filters button:focus {
    background: #b31b34;
    border-color: #b31b34; }
.color-red-light.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #ff264a; }
  .color-red-light.ap-list-filter-labels-wrapper span.badge:hover, .color-red-light.ap-list-filter-labels-wrapper span.badge:focus {
    background: #b31b34;
    border-color: #b31b34;
    color: #fff !important; }
.color-red-light.ap-list-filterbox .ap-list-filter-archive button {
  background: #ff264a;
  border-color: #ff264a;
  color: #fff !important; }
  .color-red-light.ap-list-filterbox .ap-list-filter-archive button:hover, .color-red-light.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #b31b34;
    border-color: #b31b34;
    color: #fff !important; }
.color-red-light.ap-list-filterbox li.active {
  font-weight: bold; }
.color-red-light.ap-list-filterbox .ap-list-filter-year > li:hover, .color-red-light.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #b31b34;
  border-color: #b31b34;
  color: #fff !important; }

.color-red h3.teaser-head,
.color-red .teaser-head {
  color: #b31b34; }
.color-red a {
  color: #b31b34; }
.color-red .ap-teaser a:hover,
.color-red a:hover {
  color: #ff264a;
  text-decoration: none; }
.color-red .pagination > .active > a, .color-red .pagination > .active > span,
.color-red .pagination > .active > a:hover,
.color-red .pagination > .active > span:hover,
.color-red .pagination > .active > a:focus,
.color-red .pagination > .active > span:focus {
  background-color: #b31b34;
  border-color: #b31b34; }
.color-red .pagination li a:hover {
  background: #ff264a;
  border-color: #ff264a; }
.color-red .pagination > button {
  background-color: #b31b34;
  border-color: #b31b34; }
.color-red .list-options .btn,
.color-red .list-options .dropdown-menu > .active > a {
  background: #b31b34;
  border-color: #b31b34;
  color: #fff; }
.color-red .list-options button:hover, .color-red .list-options button:focus {
  background: #ff264a;
  border-color: #ff264a; }
div .color-red.ap-list-filters .fa.ap-fa-label,
.color-red .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-red.ap-list-filters a,
.color-red .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-red.ap-list-filters button,
.color-red .ap-list-filters button {
  background: #b31b34;
  border-color: #b31b34;
  color: #fff; }
  div .color-red.ap-list-filters button:hover, div .color-red.ap-list-filters button:focus,
  .color-red .ap-list-filters button:hover,
  .color-red .ap-list-filters button:focus {
    background: #ff264a;
    border-color: #ff264a; }
.color-red.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #b31b34; }
  .color-red.ap-list-filter-labels-wrapper span.badge:hover, .color-red.ap-list-filter-labels-wrapper span.badge:focus {
    background: #ff264a;
    border-color: #ff264a;
    color: #fff !important; }
.color-red.ap-list-filterbox .ap-list-filter-archive button {
  background: #b31b34;
  border-color: #b31b34;
  color: #fff !important; }
  .color-red.ap-list-filterbox .ap-list-filter-archive button:hover, .color-red.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #ff264a;
    border-color: #ff264a;
    color: #fff !important; }
.color-red.ap-list-filterbox li.active {
  font-weight: bold; }
.color-red.ap-list-filterbox .ap-list-filter-year > li:hover, .color-red.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #ff264a;
  border-color: #ff264a;
  color: #fff !important; }

.color-red-dark h3.teaser-head,
.color-red-dark .teaser-head {
  color: #8b1528; }
.color-red-dark a {
  color: #8b1528; }
.color-red-dark .ap-teaser a:hover,
.color-red-dark a:hover {
  color: #b31b34;
  text-decoration: none; }
.color-red-dark .pagination > .active > a, .color-red-dark .pagination > .active > span,
.color-red-dark .pagination > .active > a:hover,
.color-red-dark .pagination > .active > span:hover,
.color-red-dark .pagination > .active > a:focus,
.color-red-dark .pagination > .active > span:focus {
  background-color: #8b1528;
  border-color: #8b1528; }
.color-red-dark .pagination li a:hover {
  background: #b31b34;
  border-color: #b31b34; }
.color-red-dark .pagination > button {
  background-color: #8b1528;
  border-color: #8b1528; }
.color-red-dark .list-options .btn,
.color-red-dark .list-options .dropdown-menu > .active > a {
  background: #8b1528;
  border-color: #8b1528;
  color: #fff; }
.color-red-dark .list-options button:hover, .color-red-dark .list-options button:focus {
  background: #b31b34;
  border-color: #b31b34; }
div .color-red-dark.ap-list-filters .fa.ap-fa-label,
.color-red-dark .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-red-dark.ap-list-filters a,
.color-red-dark .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-red-dark.ap-list-filters button,
.color-red-dark .ap-list-filters button {
  background: #8b1528;
  border-color: #8b1528;
  color: #fff; }
  div .color-red-dark.ap-list-filters button:hover, div .color-red-dark.ap-list-filters button:focus,
  .color-red-dark .ap-list-filters button:hover,
  .color-red-dark .ap-list-filters button:focus {
    background: #b31b34;
    border-color: #b31b34; }
.color-red-dark.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #8b1528; }
  .color-red-dark.ap-list-filter-labels-wrapper span.badge:hover, .color-red-dark.ap-list-filter-labels-wrapper span.badge:focus {
    background: #b31b34;
    border-color: #b31b34;
    color: #fff !important; }
.color-red-dark.ap-list-filterbox .ap-list-filter-archive button {
  background: #8b1528;
  border-color: #8b1528;
  color: #fff !important; }
  .color-red-dark.ap-list-filterbox .ap-list-filter-archive button:hover, .color-red-dark.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #b31b34;
    border-color: #b31b34;
    color: #fff !important; }
.color-red-dark.ap-list-filterbox li.active {
  font-weight: bold; }
.color-red-dark.ap-list-filterbox .ap-list-filter-year > li:hover, .color-red-dark.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #b31b34;
  border-color: #b31b34;
  color: #fff !important; }

.color-red-darker h3.teaser-head,
.color-red-darker .teaser-head {
  color: #560d19; }
.color-red-darker a {
  color: #560d19; }
.color-red-darker .ap-teaser a:hover,
.color-red-darker a:hover {
  color: #8b1528;
  text-decoration: none; }
.color-red-darker .pagination > .active > a, .color-red-darker .pagination > .active > span,
.color-red-darker .pagination > .active > a:hover,
.color-red-darker .pagination > .active > span:hover,
.color-red-darker .pagination > .active > a:focus,
.color-red-darker .pagination > .active > span:focus {
  background-color: #560d19;
  border-color: #560d19; }
.color-red-darker .pagination li a:hover {
  background: #8b1528;
  border-color: #8b1528; }
.color-red-darker .pagination > button {
  background-color: #560d19;
  border-color: #560d19; }
.color-red-darker .list-options .btn,
.color-red-darker .list-options .dropdown-menu > .active > a {
  background: #560d19;
  border-color: #560d19;
  color: #fff; }
.color-red-darker .list-options button:hover, .color-red-darker .list-options button:focus {
  background: #8b1528;
  border-color: #8b1528; }
div .color-red-darker.ap-list-filters .fa.ap-fa-label,
.color-red-darker .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-red-darker.ap-list-filters a,
.color-red-darker .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-red-darker.ap-list-filters button,
.color-red-darker .ap-list-filters button {
  background: #560d19;
  border-color: #560d19;
  color: #fff; }
  div .color-red-darker.ap-list-filters button:hover, div .color-red-darker.ap-list-filters button:focus,
  .color-red-darker .ap-list-filters button:hover,
  .color-red-darker .ap-list-filters button:focus {
    background: #8b1528;
    border-color: #8b1528; }
.color-red-darker.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #560d19; }
  .color-red-darker.ap-list-filter-labels-wrapper span.badge:hover, .color-red-darker.ap-list-filter-labels-wrapper span.badge:focus {
    background: #8b1528;
    border-color: #8b1528;
    color: #fff !important; }
.color-red-darker.ap-list-filterbox .ap-list-filter-archive button {
  background: #560d19;
  border-color: #560d19;
  color: #fff !important; }
  .color-red-darker.ap-list-filterbox .ap-list-filter-archive button:hover, .color-red-darker.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #8b1528;
    border-color: #8b1528;
    color: #fff !important; }
.color-red-darker.ap-list-filterbox li.active {
  font-weight: bold; }
.color-red-darker.ap-list-filterbox .ap-list-filter-year > li:hover, .color-red-darker.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #8b1528;
  border-color: #8b1528;
  color: #fff !important; }

.color-orange-light h3.teaser-head,
.color-orange-light .teaser-head {
  color: #ffcc26; }
.color-orange-light a {
  color: #ffcc26; }
.color-orange-light .ap-teaser a:hover,
.color-orange-light a:hover {
  color: #ffa826;
  text-decoration: none; }
.color-orange-light .pagination > .active > a, .color-orange-light .pagination > .active > span,
.color-orange-light .pagination > .active > a:hover,
.color-orange-light .pagination > .active > span:hover,
.color-orange-light .pagination > .active > a:focus,
.color-orange-light .pagination > .active > span:focus {
  background-color: #ffcc26;
  border-color: #ffcc26; }
.color-orange-light .pagination li a:hover {
  background: #ffa826;
  border-color: #ffa826; }
.color-orange-light .pagination > button {
  background-color: #ffcc26;
  border-color: #ffcc26; }
.color-orange-light .list-options .btn,
.color-orange-light .list-options .dropdown-menu > .active > a {
  background: #ffcc26;
  border-color: #ffcc26;
  color: #555; }
.color-orange-light .list-options button:hover, .color-orange-light .list-options button:focus {
  background: #ffa826;
  border-color: #ffa826; }
div .color-orange-light.ap-list-filters .fa.ap-fa-label,
.color-orange-light .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #555; }
div .color-orange-light.ap-list-filters a,
.color-orange-light .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-orange-light.ap-list-filters button,
.color-orange-light .ap-list-filters button {
  background: #ffcc26;
  border-color: #ffcc26;
  color: #555; }
  div .color-orange-light.ap-list-filters button:hover, div .color-orange-light.ap-list-filters button:focus,
  .color-orange-light .ap-list-filters button:hover,
  .color-orange-light .ap-list-filters button:focus {
    background: #ffa826;
    border-color: #ffa826; }
.color-orange-light.ap-list-filter-labels-wrapper span.badge {
  color: #555;
  background: #ffcc26; }
  .color-orange-light.ap-list-filter-labels-wrapper span.badge:hover, .color-orange-light.ap-list-filter-labels-wrapper span.badge:focus {
    background: #ffa826;
    border-color: #ffa826;
    color: #555 !important; }
.color-orange-light.ap-list-filterbox .ap-list-filter-archive button {
  background: #ffcc26;
  border-color: #ffcc26;
  color: #555 !important; }
  .color-orange-light.ap-list-filterbox .ap-list-filter-archive button:hover, .color-orange-light.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #ffa826;
    border-color: #ffa826;
    color: #555 !important; }
.color-orange-light.ap-list-filterbox li.active {
  font-weight: bold; }
.color-orange-light.ap-list-filterbox .ap-list-filter-year > li:hover, .color-orange-light.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #ffa826;
  border-color: #ffa826;
  color: #555 !important; }

.color-orange h3.teaser-head,
.color-orange .teaser-head {
  color: #ffa826; }
.color-orange a {
  color: #ffa826; }
.color-orange .ap-teaser a:hover,
.color-orange a:hover {
  color: #ffcc26;
  text-decoration: none; }
.color-orange .pagination > .active > a, .color-orange .pagination > .active > span,
.color-orange .pagination > .active > a:hover,
.color-orange .pagination > .active > span:hover,
.color-orange .pagination > .active > a:focus,
.color-orange .pagination > .active > span:focus {
  background-color: #ffa826;
  border-color: #ffa826; }
.color-orange .pagination li a:hover {
  background: #ffcc26;
  border-color: #ffcc26; }
.color-orange .pagination > button {
  background-color: #ffa826;
  border-color: #ffa826; }
.color-orange .list-options .btn,
.color-orange .list-options .dropdown-menu > .active > a {
  background: #ffa826;
  border-color: #ffa826;
  color: #fff; }
.color-orange .list-options button:hover, .color-orange .list-options button:focus {
  background: #ffcc26;
  border-color: #ffcc26; }
div .color-orange.ap-list-filters .fa.ap-fa-label,
.color-orange .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-orange.ap-list-filters a,
.color-orange .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-orange.ap-list-filters button,
.color-orange .ap-list-filters button {
  background: #ffa826;
  border-color: #ffa826;
  color: #fff; }
  div .color-orange.ap-list-filters button:hover, div .color-orange.ap-list-filters button:focus,
  .color-orange .ap-list-filters button:hover,
  .color-orange .ap-list-filters button:focus {
    background: #ffcc26;
    border-color: #ffcc26; }
.color-orange.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #ffa826; }
  .color-orange.ap-list-filter-labels-wrapper span.badge:hover, .color-orange.ap-list-filter-labels-wrapper span.badge:focus {
    background: #ffcc26;
    border-color: #ffcc26;
    color: #fff !important; }
.color-orange.ap-list-filterbox .ap-list-filter-archive button {
  background: #ffa826;
  border-color: #ffa826;
  color: #fff !important; }
  .color-orange.ap-list-filterbox .ap-list-filter-archive button:hover, .color-orange.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #ffcc26;
    border-color: #ffcc26;
    color: #fff !important; }
.color-orange.ap-list-filterbox li.active {
  font-weight: bold; }
.color-orange.ap-list-filterbox .ap-list-filter-year > li:hover, .color-orange.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #ffcc26;
  border-color: #ffcc26;
  color: #fff !important; }

.color-orange-dark h3.teaser-head,
.color-orange-dark .teaser-head {
  color: #fc9700; }
.color-orange-dark a {
  color: #fc9700; }
.color-orange-dark .ap-teaser a:hover,
.color-orange-dark a:hover {
  color: #ffa826;
  text-decoration: none; }
.color-orange-dark .pagination > .active > a, .color-orange-dark .pagination > .active > span,
.color-orange-dark .pagination > .active > a:hover,
.color-orange-dark .pagination > .active > span:hover,
.color-orange-dark .pagination > .active > a:focus,
.color-orange-dark .pagination > .active > span:focus {
  background-color: #fc9700;
  border-color: #fc9700; }
.color-orange-dark .pagination li a:hover {
  background: #ffa826;
  border-color: #ffa826; }
.color-orange-dark .pagination > button {
  background-color: #fc9700;
  border-color: #fc9700; }
.color-orange-dark .list-options .btn,
.color-orange-dark .list-options .dropdown-menu > .active > a {
  background: #fc9700;
  border-color: #fc9700;
  color: #fff; }
.color-orange-dark .list-options button:hover, .color-orange-dark .list-options button:focus {
  background: #ffa826;
  border-color: #ffa826; }
div .color-orange-dark.ap-list-filters .fa.ap-fa-label,
.color-orange-dark .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-orange-dark.ap-list-filters a,
.color-orange-dark .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-orange-dark.ap-list-filters button,
.color-orange-dark .ap-list-filters button {
  background: #fc9700;
  border-color: #fc9700;
  color: #fff; }
  div .color-orange-dark.ap-list-filters button:hover, div .color-orange-dark.ap-list-filters button:focus,
  .color-orange-dark .ap-list-filters button:hover,
  .color-orange-dark .ap-list-filters button:focus {
    background: #ffa826;
    border-color: #ffa826; }
.color-orange-dark.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #fc9700; }
  .color-orange-dark.ap-list-filter-labels-wrapper span.badge:hover, .color-orange-dark.ap-list-filter-labels-wrapper span.badge:focus {
    background: #ffa826;
    border-color: #ffa826;
    color: #fff !important; }
.color-orange-dark.ap-list-filterbox .ap-list-filter-archive button {
  background: #fc9700;
  border-color: #fc9700;
  color: #fff !important; }
  .color-orange-dark.ap-list-filterbox .ap-list-filter-archive button:hover, .color-orange-dark.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #ffa826;
    border-color: #ffa826;
    color: #fff !important; }
.color-orange-dark.ap-list-filterbox li.active {
  font-weight: bold; }
.color-orange-dark.ap-list-filterbox .ap-list-filter-year > li:hover, .color-orange-dark.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #ffa826;
  border-color: #ffa826;
  color: #fff !important; }

.color-orange-darker h3.teaser-head,
.color-orange-darker .teaser-head {
  color: #d37f00; }
.color-orange-darker a {
  color: #d37f00; }
.color-orange-darker .ap-teaser a:hover,
.color-orange-darker a:hover {
  color: #fc9700;
  text-decoration: none; }
.color-orange-darker .pagination > .active > a, .color-orange-darker .pagination > .active > span,
.color-orange-darker .pagination > .active > a:hover,
.color-orange-darker .pagination > .active > span:hover,
.color-orange-darker .pagination > .active > a:focus,
.color-orange-darker .pagination > .active > span:focus {
  background-color: #d37f00;
  border-color: #d37f00; }
.color-orange-darker .pagination li a:hover {
  background: #fc9700;
  border-color: #fc9700; }
.color-orange-darker .pagination > button {
  background-color: #d37f00;
  border-color: #d37f00; }
.color-orange-darker .list-options .btn,
.color-orange-darker .list-options .dropdown-menu > .active > a {
  background: #d37f00;
  border-color: #d37f00;
  color: #fff; }
.color-orange-darker .list-options button:hover, .color-orange-darker .list-options button:focus {
  background: #fc9700;
  border-color: #fc9700; }
div .color-orange-darker.ap-list-filters .fa.ap-fa-label,
.color-orange-darker .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-orange-darker.ap-list-filters a,
.color-orange-darker .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-orange-darker.ap-list-filters button,
.color-orange-darker .ap-list-filters button {
  background: #d37f00;
  border-color: #d37f00;
  color: #fff; }
  div .color-orange-darker.ap-list-filters button:hover, div .color-orange-darker.ap-list-filters button:focus,
  .color-orange-darker .ap-list-filters button:hover,
  .color-orange-darker .ap-list-filters button:focus {
    background: #fc9700;
    border-color: #fc9700; }
.color-orange-darker.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #d37f00; }
  .color-orange-darker.ap-list-filter-labels-wrapper span.badge:hover, .color-orange-darker.ap-list-filter-labels-wrapper span.badge:focus {
    background: #fc9700;
    border-color: #fc9700;
    color: #fff !important; }
.color-orange-darker.ap-list-filterbox .ap-list-filter-archive button {
  background: #d37f00;
  border-color: #d37f00;
  color: #fff !important; }
  .color-orange-darker.ap-list-filterbox .ap-list-filter-archive button:hover, .color-orange-darker.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #fc9700;
    border-color: #fc9700;
    color: #fff !important; }
.color-orange-darker.ap-list-filterbox li.active {
  font-weight: bold; }
.color-orange-darker.ap-list-filterbox .ap-list-filter-year > li:hover, .color-orange-darker.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #fc9700;
  border-color: #fc9700;
  color: #fff !important; }

.color-blue-cyan h3.teaser-head,
.color-blue-cyan .teaser-head {
  color: #187b8e; }
.color-blue-cyan a {
  color: #187b8e; }
.color-blue-cyan .ap-teaser a:hover,
.color-blue-cyan a:hover {
  color: #1bb39a;
  text-decoration: none; }
.color-blue-cyan .pagination > .active > a, .color-blue-cyan .pagination > .active > span,
.color-blue-cyan .pagination > .active > a:hover,
.color-blue-cyan .pagination > .active > span:hover,
.color-blue-cyan .pagination > .active > a:focus,
.color-blue-cyan .pagination > .active > span:focus {
  background-color: #187b8e;
  border-color: #187b8e; }
.color-blue-cyan .pagination li a:hover {
  background: #1bb39a;
  border-color: #1bb39a; }
.color-blue-cyan .pagination > button {
  background-color: #187b8e;
  border-color: #187b8e; }
.color-blue-cyan .list-options .btn,
.color-blue-cyan .list-options .dropdown-menu > .active > a {
  background: #187b8e;
  border-color: #187b8e;
  color: #fff; }
.color-blue-cyan .list-options button:hover, .color-blue-cyan .list-options button:focus {
  background: #1bb39a;
  border-color: #1bb39a; }
div .color-blue-cyan.ap-list-filters .fa.ap-fa-label,
.color-blue-cyan .ap-list-filters .fa.ap-fa-label {
  padding-top: 3px;
  color: #fff; }
div .color-blue-cyan.ap-list-filters a,
.color-blue-cyan .ap-list-filters a {
  color: inherit;
  text-decoration: none; }
div .color-blue-cyan.ap-list-filters button,
.color-blue-cyan .ap-list-filters button {
  background: #187b8e;
  border-color: #187b8e;
  color: #fff; }
  div .color-blue-cyan.ap-list-filters button:hover, div .color-blue-cyan.ap-list-filters button:focus,
  .color-blue-cyan .ap-list-filters button:hover,
  .color-blue-cyan .ap-list-filters button:focus {
    background: #1bb39a;
    border-color: #1bb39a; }
.color-blue-cyan.ap-list-filter-labels-wrapper span.badge {
  color: #fff;
  background: #187b8e; }
  .color-blue-cyan.ap-list-filter-labels-wrapper span.badge:hover, .color-blue-cyan.ap-list-filter-labels-wrapper span.badge:focus {
    background: #1bb39a;
    border-color: #1bb39a;
    color: #fff !important; }
.color-blue-cyan.ap-list-filterbox .ap-list-filter-archive button {
  background: #187b8e;
  border-color: #187b8e;
  color: #fff !important; }
  .color-blue-cyan.ap-list-filterbox .ap-list-filter-archive button:hover, .color-blue-cyan.ap-list-filterbox .ap-list-filter-archive button:focus {
    background: #1bb39a;
    border-color: #1bb39a;
    color: #fff !important; }
.color-blue-cyan.ap-list-filterbox li.active {
  font-weight: bold; }
.color-blue-cyan.ap-list-filterbox .ap-list-filter-year > li:hover, .color-blue-cyan.ap-list-filterbox .ap-list-filter-year > li:focus {
  background: #1bb39a;
  border-color: #1bb39a;
  color: #fff !important; }

/*Button Groups*/
.btn-group .dropdown-menu > li > a {
  padding: 3px 13px; }

.bg-white, .translucent-bg-white {
  background-color: #fff;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-white h1, .translucent-bg-white h1,
  .bg-white h2,
  .translucent-bg-white h2,
  .bg-white h3,
  .translucent-bg-white h3,
  .bg-white h4,
  .translucent-bg-white h4,
  .bg-white h5,
  .translucent-bg-white h5,
  .bg-white h6,
  .translucent-bg-white h6,
  .bg-white p,
  .translucent-bg-white p,
  .bg-white li,
  .translucent-bg-white li,
  .bg-white .fa,
  .translucent-bg-white .fa {
    color: #555; }
  .bg-white a, .translucent-bg-white a {
    color: #555;
    text-decoration: underline; }
    .bg-white a.btn, .translucent-bg-white a.btn, .bg-white a:hover, .translucent-bg-white a:hover {
      text-decoration: none; }
  .bg-white .headline, .translucent-bg-white .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-white {
  background-color: rgba(255, 255, 255, 0.7); }

.bg-grey-lighter, .translucent-bg-grey-lighter {
  background-color: #f7f8f8;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-grey-lighter h1, .translucent-bg-grey-lighter h1,
  .bg-grey-lighter h2,
  .translucent-bg-grey-lighter h2,
  .bg-grey-lighter h3,
  .translucent-bg-grey-lighter h3,
  .bg-grey-lighter h4,
  .translucent-bg-grey-lighter h4,
  .bg-grey-lighter h5,
  .translucent-bg-grey-lighter h5,
  .bg-grey-lighter h6,
  .translucent-bg-grey-lighter h6,
  .bg-grey-lighter p,
  .translucent-bg-grey-lighter p,
  .bg-grey-lighter li,
  .translucent-bg-grey-lighter li,
  .bg-grey-lighter .fa,
  .translucent-bg-grey-lighter .fa {
    color: #555; }
  .bg-grey-lighter a, .translucent-bg-grey-lighter a {
    color: #555;
    text-decoration: underline; }
    .bg-grey-lighter a.btn, .translucent-bg-grey-lighter a.btn, .bg-grey-lighter a:hover, .translucent-bg-grey-lighter a:hover {
      text-decoration: none; }
  .bg-grey-lighter .headline, .translucent-bg-grey-lighter .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-grey-lighter {
  background-color: rgba(247, 248, 248, 0.7); }

.bg-grey-light, .translucent-bg-grey-light {
  background-color: #e2e3e6;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-grey-light h1, .translucent-bg-grey-light h1,
  .bg-grey-light h2,
  .translucent-bg-grey-light h2,
  .bg-grey-light h3,
  .translucent-bg-grey-light h3,
  .bg-grey-light h4,
  .translucent-bg-grey-light h4,
  .bg-grey-light h5,
  .translucent-bg-grey-light h5,
  .bg-grey-light h6,
  .translucent-bg-grey-light h6,
  .bg-grey-light p,
  .translucent-bg-grey-light p,
  .bg-grey-light li,
  .translucent-bg-grey-light li,
  .bg-grey-light .fa,
  .translucent-bg-grey-light .fa {
    color: #555; }
  .bg-grey-light a, .translucent-bg-grey-light a {
    color: #555;
    text-decoration: underline; }
    .bg-grey-light a.btn, .translucent-bg-grey-light a.btn, .bg-grey-light a:hover, .translucent-bg-grey-light a:hover {
      text-decoration: none; }
  .bg-grey-light .headline, .translucent-bg-grey-light .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-grey-light {
  background-color: rgba(226, 227, 230, 0.7); }

.bg-grey, .translucent-bg-grey {
  background-color: #a8adb4;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-grey h1, .translucent-bg-grey h1,
  .bg-grey h2,
  .translucent-bg-grey h2,
  .bg-grey h3,
  .translucent-bg-grey h3,
  .bg-grey h4,
  .translucent-bg-grey h4,
  .bg-grey h5,
  .translucent-bg-grey h5,
  .bg-grey h6,
  .translucent-bg-grey h6,
  .bg-grey p,
  .translucent-bg-grey p,
  .bg-grey li,
  .translucent-bg-grey li,
  .bg-grey .fa,
  .translucent-bg-grey .fa {
    color: #fff; }
  .bg-grey a, .translucent-bg-grey a {
    color: #fff;
    text-decoration: underline; }
    .bg-grey a.btn, .translucent-bg-grey a.btn, .bg-grey a:hover, .translucent-bg-grey a:hover {
      text-decoration: none; }
  .bg-grey .headline, .translucent-bg-grey .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-grey {
  background-color: rgba(168, 173, 180, 0.7); }

.bg-grey-dark, .translucent-bg-grey-dark {
  background-color: #7f8691;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-grey-dark h1, .translucent-bg-grey-dark h1,
  .bg-grey-dark h2,
  .translucent-bg-grey-dark h2,
  .bg-grey-dark h3,
  .translucent-bg-grey-dark h3,
  .bg-grey-dark h4,
  .translucent-bg-grey-dark h4,
  .bg-grey-dark h5,
  .translucent-bg-grey-dark h5,
  .bg-grey-dark h6,
  .translucent-bg-grey-dark h6,
  .bg-grey-dark p,
  .translucent-bg-grey-dark p,
  .bg-grey-dark li,
  .translucent-bg-grey-dark li,
  .bg-grey-dark .fa,
  .translucent-bg-grey-dark .fa {
    color: #fff; }
  .bg-grey-dark a, .translucent-bg-grey-dark a {
    color: #fff;
    text-decoration: underline; }
    .bg-grey-dark a.btn, .translucent-bg-grey-dark a.btn, .bg-grey-dark a:hover, .translucent-bg-grey-dark a:hover {
      text-decoration: none; }
  .bg-grey-dark .headline, .translucent-bg-grey-dark .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-grey-dark {
  background-color: rgba(127, 134, 145, 0.7); }

.bg-grey-darker, .translucent-bg-grey-darker {
  background-color: #5a6069;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-grey-darker h1, .translucent-bg-grey-darker h1,
  .bg-grey-darker h2,
  .translucent-bg-grey-darker h2,
  .bg-grey-darker h3,
  .translucent-bg-grey-darker h3,
  .bg-grey-darker h4,
  .translucent-bg-grey-darker h4,
  .bg-grey-darker h5,
  .translucent-bg-grey-darker h5,
  .bg-grey-darker h6,
  .translucent-bg-grey-darker h6,
  .bg-grey-darker p,
  .translucent-bg-grey-darker p,
  .bg-grey-darker li,
  .translucent-bg-grey-darker li,
  .bg-grey-darker .fa,
  .translucent-bg-grey-darker .fa {
    color: #fff; }
  .bg-grey-darker a, .translucent-bg-grey-darker a {
    color: #fff;
    text-decoration: underline; }
    .bg-grey-darker a.btn, .translucent-bg-grey-darker a.btn, .bg-grey-darker a:hover, .translucent-bg-grey-darker a:hover {
      text-decoration: none; }
  .bg-grey-darker .headline, .translucent-bg-grey-darker .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-grey-darker {
  background-color: rgba(90, 96, 105, 0.7); }

.bg-black, .translucent-bg-black {
  background-color: #000;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-black h1, .translucent-bg-black h1,
  .bg-black h2,
  .translucent-bg-black h2,
  .bg-black h3,
  .translucent-bg-black h3,
  .bg-black h4,
  .translucent-bg-black h4,
  .bg-black h5,
  .translucent-bg-black h5,
  .bg-black h6,
  .translucent-bg-black h6,
  .bg-black p,
  .translucent-bg-black p,
  .bg-black li,
  .translucent-bg-black li,
  .bg-black .fa,
  .translucent-bg-black .fa {
    color: #fff; }
  .bg-black a, .translucent-bg-black a {
    color: #fff;
    text-decoration: underline; }
    .bg-black a.btn, .translucent-bg-black a.btn, .bg-black a:hover, .translucent-bg-black a:hover {
      text-decoration: none; }
  .bg-black .headline, .translucent-bg-black .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-black {
  background-color: rgba(0, 0, 0, 0.7); }

.bg-blue-light, .translucent-bg-blue-light {
  background-color: #2197db;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-blue-light h1, .translucent-bg-blue-light h1,
  .bg-blue-light h2,
  .translucent-bg-blue-light h2,
  .bg-blue-light h3,
  .translucent-bg-blue-light h3,
  .bg-blue-light h4,
  .translucent-bg-blue-light h4,
  .bg-blue-light h5,
  .translucent-bg-blue-light h5,
  .bg-blue-light h6,
  .translucent-bg-blue-light h6,
  .bg-blue-light p,
  .translucent-bg-blue-light p,
  .bg-blue-light li,
  .translucent-bg-blue-light li,
  .bg-blue-light .fa,
  .translucent-bg-blue-light .fa {
    color: #fff; }
  .bg-blue-light a, .translucent-bg-blue-light a {
    color: #fff;
    text-decoration: underline; }
    .bg-blue-light a.btn, .translucent-bg-blue-light a.btn, .bg-blue-light a:hover, .translucent-bg-blue-light a:hover {
      text-decoration: none; }
  .bg-blue-light .headline, .translucent-bg-blue-light .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue-light {
  background-color: rgba(33, 151, 219, 0.7); }

.bg-blue, .translucent-bg-blue {
  background-color: #144382;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-blue h1, .translucent-bg-blue h1,
  .bg-blue h2,
  .translucent-bg-blue h2,
  .bg-blue h3,
  .translucent-bg-blue h3,
  .bg-blue h4,
  .translucent-bg-blue h4,
  .bg-blue h5,
  .translucent-bg-blue h5,
  .bg-blue h6,
  .translucent-bg-blue h6,
  .bg-blue p,
  .translucent-bg-blue p,
  .bg-blue li,
  .translucent-bg-blue li,
  .bg-blue .fa,
  .translucent-bg-blue .fa {
    color: #fff; }
  .bg-blue a, .translucent-bg-blue a {
    color: #fff;
    text-decoration: underline; }
    .bg-blue a.btn, .translucent-bg-blue a.btn, .bg-blue a:hover, .translucent-bg-blue a:hover {
      text-decoration: none; }
  .bg-blue .headline, .translucent-bg-blue .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue {
  background-color: rgba(20, 67, 130, 0.7); }

.bg-blue-dark, .translucent-bg-blue-dark {
  background-color: #0d2c56;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-blue-dark h1, .translucent-bg-blue-dark h1,
  .bg-blue-dark h2,
  .translucent-bg-blue-dark h2,
  .bg-blue-dark h3,
  .translucent-bg-blue-dark h3,
  .bg-blue-dark h4,
  .translucent-bg-blue-dark h4,
  .bg-blue-dark h5,
  .translucent-bg-blue-dark h5,
  .bg-blue-dark h6,
  .translucent-bg-blue-dark h6,
  .bg-blue-dark p,
  .translucent-bg-blue-dark p,
  .bg-blue-dark li,
  .translucent-bg-blue-dark li,
  .bg-blue-dark .fa,
  .translucent-bg-blue-dark .fa {
    color: #fff; }
  .bg-blue-dark a, .translucent-bg-blue-dark a {
    color: #fff;
    text-decoration: underline; }
    .bg-blue-dark a.btn, .translucent-bg-blue-dark a.btn, .bg-blue-dark a:hover, .translucent-bg-blue-dark a:hover {
      text-decoration: none; }
  .bg-blue-dark .headline, .translucent-bg-blue-dark .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue-dark {
  background-color: rgba(13, 44, 86, 0.7); }

.bg-blue-darker, .translucent-bg-blue-darker {
  background-color: #0a2140;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-blue-darker h1, .translucent-bg-blue-darker h1,
  .bg-blue-darker h2,
  .translucent-bg-blue-darker h2,
  .bg-blue-darker h3,
  .translucent-bg-blue-darker h3,
  .bg-blue-darker h4,
  .translucent-bg-blue-darker h4,
  .bg-blue-darker h5,
  .translucent-bg-blue-darker h5,
  .bg-blue-darker h6,
  .translucent-bg-blue-darker h6,
  .bg-blue-darker p,
  .translucent-bg-blue-darker p,
  .bg-blue-darker li,
  .translucent-bg-blue-darker li,
  .bg-blue-darker .fa,
  .translucent-bg-blue-darker .fa {
    color: #fff; }
  .bg-blue-darker a, .translucent-bg-blue-darker a {
    color: #fff;
    text-decoration: underline; }
    .bg-blue-darker a.btn, .translucent-bg-blue-darker a.btn, .bg-blue-darker a:hover, .translucent-bg-blue-darker a:hover {
      text-decoration: none; }
  .bg-blue-darker .headline, .translucent-bg-blue-darker .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue-darker {
  background-color: rgba(10, 33, 64, 0.7); }

.bg-cyan, .translucent-bg-cyan {
  background-color: #1bb39a;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-cyan h1, .translucent-bg-cyan h1,
  .bg-cyan h2,
  .translucent-bg-cyan h2,
  .bg-cyan h3,
  .translucent-bg-cyan h3,
  .bg-cyan h4,
  .translucent-bg-cyan h4,
  .bg-cyan h5,
  .translucent-bg-cyan h5,
  .bg-cyan h6,
  .translucent-bg-cyan h6,
  .bg-cyan p,
  .translucent-bg-cyan p,
  .bg-cyan li,
  .translucent-bg-cyan li,
  .bg-cyan .fa,
  .translucent-bg-cyan .fa {
    color: #fff; }
  .bg-cyan a, .translucent-bg-cyan a {
    color: #fff;
    text-decoration: underline; }
    .bg-cyan a.btn, .translucent-bg-cyan a.btn, .bg-cyan a:hover, .translucent-bg-cyan a:hover {
      text-decoration: none; }
  .bg-cyan .headline, .translucent-bg-cyan .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-cyan {
  background-color: rgba(27, 179, 154, 0.7); }

.bg-red-light, .translucent-bg-red-light {
  background-color: #ff264a;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red-light h1, .translucent-bg-red-light h1,
  .bg-red-light h2,
  .translucent-bg-red-light h2,
  .bg-red-light h3,
  .translucent-bg-red-light h3,
  .bg-red-light h4,
  .translucent-bg-red-light h4,
  .bg-red-light h5,
  .translucent-bg-red-light h5,
  .bg-red-light h6,
  .translucent-bg-red-light h6,
  .bg-red-light p,
  .translucent-bg-red-light p,
  .bg-red-light li,
  .translucent-bg-red-light li,
  .bg-red-light .fa,
  .translucent-bg-red-light .fa {
    color: #fff; }
  .bg-red-light a, .translucent-bg-red-light a {
    color: #fff;
    text-decoration: underline; }
    .bg-red-light a.btn, .translucent-bg-red-light a.btn, .bg-red-light a:hover, .translucent-bg-red-light a:hover {
      text-decoration: none; }
  .bg-red-light .headline, .translucent-bg-red-light .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-light {
  background-color: rgba(255, 38, 74, 0.7); }

.bg-red, .translucent-bg-red {
  background-color: #b31b34;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red h1, .translucent-bg-red h1,
  .bg-red h2,
  .translucent-bg-red h2,
  .bg-red h3,
  .translucent-bg-red h3,
  .bg-red h4,
  .translucent-bg-red h4,
  .bg-red h5,
  .translucent-bg-red h5,
  .bg-red h6,
  .translucent-bg-red h6,
  .bg-red p,
  .translucent-bg-red p,
  .bg-red li,
  .translucent-bg-red li,
  .bg-red .fa,
  .translucent-bg-red .fa {
    color: #fff; }
  .bg-red a, .translucent-bg-red a {
    color: #fff;
    text-decoration: underline; }
    .bg-red a.btn, .translucent-bg-red a.btn, .bg-red a:hover, .translucent-bg-red a:hover {
      text-decoration: none; }
  .bg-red .headline, .translucent-bg-red .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red {
  background-color: rgba(179, 27, 52, 0.7); }

.bg-red-dark, .translucent-bg-red-dark {
  background-color: #8b1528;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red-dark h1, .translucent-bg-red-dark h1,
  .bg-red-dark h2,
  .translucent-bg-red-dark h2,
  .bg-red-dark h3,
  .translucent-bg-red-dark h3,
  .bg-red-dark h4,
  .translucent-bg-red-dark h4,
  .bg-red-dark h5,
  .translucent-bg-red-dark h5,
  .bg-red-dark h6,
  .translucent-bg-red-dark h6,
  .bg-red-dark p,
  .translucent-bg-red-dark p,
  .bg-red-dark li,
  .translucent-bg-red-dark li,
  .bg-red-dark .fa,
  .translucent-bg-red-dark .fa {
    color: #fff; }
  .bg-red-dark a, .translucent-bg-red-dark a {
    color: #fff;
    text-decoration: underline; }
    .bg-red-dark a.btn, .translucent-bg-red-dark a.btn, .bg-red-dark a:hover, .translucent-bg-red-dark a:hover {
      text-decoration: none; }
  .bg-red-dark .headline, .translucent-bg-red-dark .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-dark {
  background-color: rgba(139, 21, 40, 0.7); }

.bg-red-darker, .translucent-bg-red-darker {
  background-color: #560d19;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red-darker h1, .translucent-bg-red-darker h1,
  .bg-red-darker h2,
  .translucent-bg-red-darker h2,
  .bg-red-darker h3,
  .translucent-bg-red-darker h3,
  .bg-red-darker h4,
  .translucent-bg-red-darker h4,
  .bg-red-darker h5,
  .translucent-bg-red-darker h5,
  .bg-red-darker h6,
  .translucent-bg-red-darker h6,
  .bg-red-darker p,
  .translucent-bg-red-darker p,
  .bg-red-darker li,
  .translucent-bg-red-darker li,
  .bg-red-darker .fa,
  .translucent-bg-red-darker .fa {
    color: #fff; }
  .bg-red-darker a, .translucent-bg-red-darker a {
    color: #fff;
    text-decoration: underline; }
    .bg-red-darker a.btn, .translucent-bg-red-darker a.btn, .bg-red-darker a:hover, .translucent-bg-red-darker a:hover {
      text-decoration: none; }
  .bg-red-darker .headline, .translucent-bg-red-darker .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-darker {
  background-color: rgba(86, 13, 25, 0.7); }

.bg-orange-light, .translucent-bg-orange-light {
  background-color: #ffcc26;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-orange-light h1, .translucent-bg-orange-light h1,
  .bg-orange-light h2,
  .translucent-bg-orange-light h2,
  .bg-orange-light h3,
  .translucent-bg-orange-light h3,
  .bg-orange-light h4,
  .translucent-bg-orange-light h4,
  .bg-orange-light h5,
  .translucent-bg-orange-light h5,
  .bg-orange-light h6,
  .translucent-bg-orange-light h6,
  .bg-orange-light p,
  .translucent-bg-orange-light p,
  .bg-orange-light li,
  .translucent-bg-orange-light li,
  .bg-orange-light .fa,
  .translucent-bg-orange-light .fa {
    color: #555; }
  .bg-orange-light a, .translucent-bg-orange-light a {
    color: #555;
    text-decoration: underline; }
    .bg-orange-light a.btn, .translucent-bg-orange-light a.btn, .bg-orange-light a:hover, .translucent-bg-orange-light a:hover {
      text-decoration: none; }
  .bg-orange-light .headline, .translucent-bg-orange-light .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-orange-light {
  background-color: rgba(255, 204, 38, 0.7); }

.bg-orange, .translucent-bg-orange {
  background-color: #ffa826;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-orange h1, .translucent-bg-orange h1,
  .bg-orange h2,
  .translucent-bg-orange h2,
  .bg-orange h3,
  .translucent-bg-orange h3,
  .bg-orange h4,
  .translucent-bg-orange h4,
  .bg-orange h5,
  .translucent-bg-orange h5,
  .bg-orange h6,
  .translucent-bg-orange h6,
  .bg-orange p,
  .translucent-bg-orange p,
  .bg-orange li,
  .translucent-bg-orange li,
  .bg-orange .fa,
  .translucent-bg-orange .fa {
    color: #fff; }
  .bg-orange a, .translucent-bg-orange a {
    color: #fff;
    text-decoration: underline; }
    .bg-orange a.btn, .translucent-bg-orange a.btn, .bg-orange a:hover, .translucent-bg-orange a:hover {
      text-decoration: none; }
  .bg-orange .headline, .translucent-bg-orange .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-orange {
  background-color: rgba(255, 168, 38, 0.7); }

.bg-orange-dark, .translucent-bg-orange-dark {
  background-color: #fc9700;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-orange-dark h1, .translucent-bg-orange-dark h1,
  .bg-orange-dark h2,
  .translucent-bg-orange-dark h2,
  .bg-orange-dark h3,
  .translucent-bg-orange-dark h3,
  .bg-orange-dark h4,
  .translucent-bg-orange-dark h4,
  .bg-orange-dark h5,
  .translucent-bg-orange-dark h5,
  .bg-orange-dark h6,
  .translucent-bg-orange-dark h6,
  .bg-orange-dark p,
  .translucent-bg-orange-dark p,
  .bg-orange-dark li,
  .translucent-bg-orange-dark li,
  .bg-orange-dark .fa,
  .translucent-bg-orange-dark .fa {
    color: #fff; }
  .bg-orange-dark a, .translucent-bg-orange-dark a {
    color: #fff;
    text-decoration: underline; }
    .bg-orange-dark a.btn, .translucent-bg-orange-dark a.btn, .bg-orange-dark a:hover, .translucent-bg-orange-dark a:hover {
      text-decoration: none; }
  .bg-orange-dark .headline, .translucent-bg-orange-dark .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-orange-dark {
  background-color: rgba(252, 151, 0, 0.7); }

.bg-orange-darker, .translucent-bg-orange-darker {
  background-color: #d37f00;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-orange-darker h1, .translucent-bg-orange-darker h1,
  .bg-orange-darker h2,
  .translucent-bg-orange-darker h2,
  .bg-orange-darker h3,
  .translucent-bg-orange-darker h3,
  .bg-orange-darker h4,
  .translucent-bg-orange-darker h4,
  .bg-orange-darker h5,
  .translucent-bg-orange-darker h5,
  .bg-orange-darker h6,
  .translucent-bg-orange-darker h6,
  .bg-orange-darker p,
  .translucent-bg-orange-darker p,
  .bg-orange-darker li,
  .translucent-bg-orange-darker li,
  .bg-orange-darker .fa,
  .translucent-bg-orange-darker .fa {
    color: #fff; }
  .bg-orange-darker a, .translucent-bg-orange-darker a {
    color: #fff;
    text-decoration: underline; }
    .bg-orange-darker a.btn, .translucent-bg-orange-darker a.btn, .bg-orange-darker a:hover, .translucent-bg-orange-darker a:hover {
      text-decoration: none; }
  .bg-orange-darker .headline, .translucent-bg-orange-darker .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-orange-darker {
  background-color: rgba(211, 127, 0, 0.7); }

.bg-blue-cyan, .translucent-bg-blue-cyan {
  background-color: #187b8e;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-blue-cyan h1, .translucent-bg-blue-cyan h1,
  .bg-blue-cyan h2,
  .translucent-bg-blue-cyan h2,
  .bg-blue-cyan h3,
  .translucent-bg-blue-cyan h3,
  .bg-blue-cyan h4,
  .translucent-bg-blue-cyan h4,
  .bg-blue-cyan h5,
  .translucent-bg-blue-cyan h5,
  .bg-blue-cyan h6,
  .translucent-bg-blue-cyan h6,
  .bg-blue-cyan p,
  .translucent-bg-blue-cyan p,
  .bg-blue-cyan li,
  .translucent-bg-blue-cyan li,
  .bg-blue-cyan .fa,
  .translucent-bg-blue-cyan .fa {
    color: #fff; }
  .bg-blue-cyan a, .translucent-bg-blue-cyan a {
    color: #fff;
    text-decoration: underline; }
    .bg-blue-cyan a.btn, .translucent-bg-blue-cyan a.btn, .bg-blue-cyan a:hover, .translucent-bg-blue-cyan a:hover {
      text-decoration: none; }
  .bg-blue-cyan .headline, .translucent-bg-blue-cyan .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue-cyan {
  background-color: rgba(24, 123, 142, 0.7); }

.bg-blue-lighter, .translucent-bg-blue-lighter {
  background-color: #d9edf9;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-blue-lighter h1, .translucent-bg-blue-lighter h1,
  .bg-blue-lighter h2,
  .translucent-bg-blue-lighter h2,
  .bg-blue-lighter h3,
  .translucent-bg-blue-lighter h3,
  .bg-blue-lighter h4,
  .translucent-bg-blue-lighter h4,
  .bg-blue-lighter h5,
  .translucent-bg-blue-lighter h5,
  .bg-blue-lighter h6,
  .translucent-bg-blue-lighter h6,
  .bg-blue-lighter p,
  .translucent-bg-blue-lighter p,
  .bg-blue-lighter li,
  .translucent-bg-blue-lighter li,
  .bg-blue-lighter .fa,
  .translucent-bg-blue-lighter .fa {
    color: #555; }
  .bg-blue-lighter a, .translucent-bg-blue-lighter a {
    color: #555;
    text-decoration: underline; }
    .bg-blue-lighter a.btn, .translucent-bg-blue-lighter a.btn, .bg-blue-lighter a:hover, .translucent-bg-blue-lighter a:hover {
      text-decoration: none; }
  .bg-blue-lighter .headline, .translucent-bg-blue-lighter .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-blue-lighter {
  background-color: rgba(217, 237, 249, 0.7); }

.bg-red-lighter, .translucent-bg-red-lighter {
  background-color: #ffe3e7;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-red-lighter h1, .translucent-bg-red-lighter h1,
  .bg-red-lighter h2,
  .translucent-bg-red-lighter h2,
  .bg-red-lighter h3,
  .translucent-bg-red-lighter h3,
  .bg-red-lighter h4,
  .translucent-bg-red-lighter h4,
  .bg-red-lighter h5,
  .translucent-bg-red-lighter h5,
  .bg-red-lighter h6,
  .translucent-bg-red-lighter h6,
  .bg-red-lighter p,
  .translucent-bg-red-lighter p,
  .bg-red-lighter li,
  .translucent-bg-red-lighter li,
  .bg-red-lighter .fa,
  .translucent-bg-red-lighter .fa {
    color: #555; }
  .bg-red-lighter a, .translucent-bg-red-lighter a {
    color: #555;
    text-decoration: underline; }
    .bg-red-lighter a.btn, .translucent-bg-red-lighter a.btn, .bg-red-lighter a:hover, .translucent-bg-red-lighter a:hover {
      text-decoration: none; }
  .bg-red-lighter .headline, .translucent-bg-red-lighter .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-lighter {
  background-color: rgba(255, 227, 231, 0.7); }

.bg-orange-lighter, .translucent-bg-orange-lighter {
  background-color: #fff026;
  color: #555;
  overflow: hidden;
  padding: 20px; }
  .bg-orange-lighter h1, .translucent-bg-orange-lighter h1,
  .bg-orange-lighter h2,
  .translucent-bg-orange-lighter h2,
  .bg-orange-lighter h3,
  .translucent-bg-orange-lighter h3,
  .bg-orange-lighter h4,
  .translucent-bg-orange-lighter h4,
  .bg-orange-lighter h5,
  .translucent-bg-orange-lighter h5,
  .bg-orange-lighter h6,
  .translucent-bg-orange-lighter h6,
  .bg-orange-lighter p,
  .translucent-bg-orange-lighter p,
  .bg-orange-lighter li,
  .translucent-bg-orange-lighter li,
  .bg-orange-lighter .fa,
  .translucent-bg-orange-lighter .fa {
    color: #555; }
  .bg-orange-lighter a, .translucent-bg-orange-lighter a {
    color: #555;
    text-decoration: underline; }
    .bg-orange-lighter a.btn, .translucent-bg-orange-lighter a.btn, .bg-orange-lighter a:hover, .translucent-bg-orange-lighter a:hover {
      text-decoration: none; }
  .bg-orange-lighter .headline, .translucent-bg-orange-lighter .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-orange-lighter {
  background-color: rgba(255, 240, 38, 0.7); }

.bg-violet, .translucent-bg-violet {
  background-color: #642f5b;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-violet h1, .translucent-bg-violet h1,
  .bg-violet h2,
  .translucent-bg-violet h2,
  .bg-violet h3,
  .translucent-bg-violet h3,
  .bg-violet h4,
  .translucent-bg-violet h4,
  .bg-violet h5,
  .translucent-bg-violet h5,
  .bg-violet h6,
  .translucent-bg-violet h6,
  .bg-violet p,
  .translucent-bg-violet p,
  .bg-violet li,
  .translucent-bg-violet li,
  .bg-violet .fa,
  .translucent-bg-violet .fa {
    color: #fff; }
  .bg-violet a, .translucent-bg-violet a {
    color: #fff;
    text-decoration: underline; }
    .bg-violet a.btn, .translucent-bg-violet a.btn, .bg-violet a:hover, .translucent-bg-violet a:hover {
      text-decoration: none; }
  .bg-violet .headline, .translucent-bg-violet .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-violet {
  background-color: rgba(100, 47, 91, 0.7); }

.bg-brown, .translucent-bg-brown {
  background-color: #8a7654;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-brown h1, .translucent-bg-brown h1,
  .bg-brown h2,
  .translucent-bg-brown h2,
  .bg-brown h3,
  .translucent-bg-brown h3,
  .bg-brown h4,
  .translucent-bg-brown h4,
  .bg-brown h5,
  .translucent-bg-brown h5,
  .bg-brown h6,
  .translucent-bg-brown h6,
  .bg-brown p,
  .translucent-bg-brown p,
  .bg-brown li,
  .translucent-bg-brown li,
  .bg-brown .fa,
  .translucent-bg-brown .fa {
    color: #fff; }
  .bg-brown a, .translucent-bg-brown a {
    color: #fff;
    text-decoration: underline; }
    .bg-brown a.btn, .translucent-bg-brown a.btn, .bg-brown a:hover, .translucent-bg-brown a:hover {
      text-decoration: none; }
  .bg-brown .headline, .translucent-bg-brown .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-brown {
  background-color: rgba(138, 118, 84, 0.7); }

.bg-red-orange, .translucent-bg-red-orange {
  background-color: #d9622d;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red-orange h1, .translucent-bg-red-orange h1,
  .bg-red-orange h2,
  .translucent-bg-red-orange h2,
  .bg-red-orange h3,
  .translucent-bg-red-orange h3,
  .bg-red-orange h4,
  .translucent-bg-red-orange h4,
  .bg-red-orange h5,
  .translucent-bg-red-orange h5,
  .bg-red-orange h6,
  .translucent-bg-red-orange h6,
  .bg-red-orange p,
  .translucent-bg-red-orange p,
  .bg-red-orange li,
  .translucent-bg-red-orange li,
  .bg-red-orange .fa,
  .translucent-bg-red-orange .fa {
    color: #fff; }
  .bg-red-orange a, .translucent-bg-red-orange a {
    color: #fff;
    text-decoration: underline; }
    .bg-red-orange a.btn, .translucent-bg-red-orange a.btn, .bg-red-orange a:hover, .translucent-bg-red-orange a:hover {
      text-decoration: none; }
  .bg-red-orange .headline, .translucent-bg-red-orange .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-orange {
  background-color: rgba(217, 98, 45, 0.7); }

.bg-red-cyan, .translucent-bg-red-cyan {
  background-color: #676767;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-red-cyan h1, .translucent-bg-red-cyan h1,
  .bg-red-cyan h2,
  .translucent-bg-red-cyan h2,
  .bg-red-cyan h3,
  .translucent-bg-red-cyan h3,
  .bg-red-cyan h4,
  .translucent-bg-red-cyan h4,
  .bg-red-cyan h5,
  .translucent-bg-red-cyan h5,
  .bg-red-cyan h6,
  .translucent-bg-red-cyan h6,
  .bg-red-cyan p,
  .translucent-bg-red-cyan p,
  .bg-red-cyan li,
  .translucent-bg-red-cyan li,
  .bg-red-cyan .fa,
  .translucent-bg-red-cyan .fa {
    color: #fff; }
  .bg-red-cyan a, .translucent-bg-red-cyan a {
    color: #fff;
    text-decoration: underline; }
    .bg-red-cyan a.btn, .translucent-bg-red-cyan a.btn, .bg-red-cyan a:hover, .translucent-bg-red-cyan a:hover {
      text-decoration: none; }
  .bg-red-cyan .headline, .translucent-bg-red-cyan .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-red-cyan {
  background-color: rgba(103, 103, 103, 0.7); }

.bg-green, .translucent-bg-green {
  background-color: #8dae60;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-green h1, .translucent-bg-green h1,
  .bg-green h2,
  .translucent-bg-green h2,
  .bg-green h3,
  .translucent-bg-green h3,
  .bg-green h4,
  .translucent-bg-green h4,
  .bg-green h5,
  .translucent-bg-green h5,
  .bg-green h6,
  .translucent-bg-green h6,
  .bg-green p,
  .translucent-bg-green p,
  .bg-green li,
  .translucent-bg-green li,
  .bg-green .fa,
  .translucent-bg-green .fa {
    color: #fff; }
  .bg-green a, .translucent-bg-green a {
    color: #fff;
    text-decoration: underline; }
    .bg-green a.btn, .translucent-bg-green a.btn, .bg-green a:hover, .translucent-bg-green a:hover {
      text-decoration: none; }
  .bg-green .headline, .translucent-bg-green .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-green {
  background-color: rgba(141, 174, 96, 0.7); }

.bg-yellow, .translucent-bg-yellow {
  background-color: #f1c40f;
  color: #fff;
  overflow: hidden;
  padding: 20px; }
  .bg-yellow h1, .translucent-bg-yellow h1,
  .bg-yellow h2,
  .translucent-bg-yellow h2,
  .bg-yellow h3,
  .translucent-bg-yellow h3,
  .bg-yellow h4,
  .translucent-bg-yellow h4,
  .bg-yellow h5,
  .translucent-bg-yellow h5,
  .bg-yellow h6,
  .translucent-bg-yellow h6,
  .bg-yellow p,
  .translucent-bg-yellow p,
  .bg-yellow li,
  .translucent-bg-yellow li,
  .bg-yellow .fa,
  .translucent-bg-yellow .fa {
    color: #fff; }
  .bg-yellow a, .translucent-bg-yellow a {
    color: #fff;
    text-decoration: underline; }
    .bg-yellow a.btn, .translucent-bg-yellow a.btn, .bg-yellow a:hover, .translucent-bg-yellow a:hover {
      text-decoration: none; }
  .bg-yellow .headline, .translucent-bg-yellow .headline {
    border-bottom: 1px dotted #e2e3e6; }

.translucent-bg-yellow {
  background-color: rgba(241, 196, 15, 0.7); }

.fc-white {
  color: #fff; }
  .fc-white.a, .fc-white p, .fc-white h1, .fc-white h2, .fc-white h3, .fc-white h4, .fc-white .fa {
    color: #fff; }

.fc-grey-lighter {
  color: #f7f8f8; }
  .fc-grey-lighter.a, .fc-grey-lighter p, .fc-grey-lighter h1, .fc-grey-lighter h2, .fc-grey-lighter h3, .fc-grey-lighter h4, .fc-grey-lighter .fa {
    color: #f7f8f8; }

.fc-grey-light {
  color: #e2e3e6; }
  .fc-grey-light.a, .fc-grey-light p, .fc-grey-light h1, .fc-grey-light h2, .fc-grey-light h3, .fc-grey-light h4, .fc-grey-light .fa {
    color: #e2e3e6; }

.fc-grey {
  color: #a8adb4; }
  .fc-grey.a, .fc-grey p, .fc-grey h1, .fc-grey h2, .fc-grey h3, .fc-grey h4, .fc-grey .fa {
    color: #a8adb4; }

.fc-grey-dark {
  color: #7f8691; }
  .fc-grey-dark.a, .fc-grey-dark p, .fc-grey-dark h1, .fc-grey-dark h2, .fc-grey-dark h3, .fc-grey-dark h4, .fc-grey-dark .fa {
    color: #7f8691; }

.fc-grey-darker {
  color: #5a6069; }
  .fc-grey-darker.a, .fc-grey-darker p, .fc-grey-darker h1, .fc-grey-darker h2, .fc-grey-darker h3, .fc-grey-darker h4, .fc-grey-darker .fa {
    color: #5a6069; }

.fc-black {
  color: #000; }
  .fc-black.a, .fc-black p, .fc-black h1, .fc-black h2, .fc-black h3, .fc-black h4, .fc-black .fa {
    color: #000; }

.fc-blue-light {
  color: #2197db; }
  .fc-blue-light.a, .fc-blue-light p, .fc-blue-light h1, .fc-blue-light h2, .fc-blue-light h3, .fc-blue-light h4, .fc-blue-light .fa {
    color: #2197db; }

.fc-blue {
  color: #144382; }
  .fc-blue.a, .fc-blue p, .fc-blue h1, .fc-blue h2, .fc-blue h3, .fc-blue h4, .fc-blue .fa {
    color: #144382; }

.fc-blue-dark {
  color: #0d2c56; }
  .fc-blue-dark.a, .fc-blue-dark p, .fc-blue-dark h1, .fc-blue-dark h2, .fc-blue-dark h3, .fc-blue-dark h4, .fc-blue-dark .fa {
    color: #0d2c56; }

.fc-blue-darker {
  color: #0a2140; }
  .fc-blue-darker.a, .fc-blue-darker p, .fc-blue-darker h1, .fc-blue-darker h2, .fc-blue-darker h3, .fc-blue-darker h4, .fc-blue-darker .fa {
    color: #0a2140; }

.fc-cyan {
  color: #1bb39a; }
  .fc-cyan.a, .fc-cyan p, .fc-cyan h1, .fc-cyan h2, .fc-cyan h3, .fc-cyan h4, .fc-cyan .fa {
    color: #1bb39a; }

.fc-red-light {
  color: #ff264a; }
  .fc-red-light.a, .fc-red-light p, .fc-red-light h1, .fc-red-light h2, .fc-red-light h3, .fc-red-light h4, .fc-red-light .fa {
    color: #ff264a; }

.fc-red {
  color: #b31b34; }
  .fc-red.a, .fc-red p, .fc-red h1, .fc-red h2, .fc-red h3, .fc-red h4, .fc-red .fa {
    color: #b31b34; }

.fc-red-dark {
  color: #8b1528; }
  .fc-red-dark.a, .fc-red-dark p, .fc-red-dark h1, .fc-red-dark h2, .fc-red-dark h3, .fc-red-dark h4, .fc-red-dark .fa {
    color: #8b1528; }

.fc-red-darker {
  color: #560d19; }
  .fc-red-darker.a, .fc-red-darker p, .fc-red-darker h1, .fc-red-darker h2, .fc-red-darker h3, .fc-red-darker h4, .fc-red-darker .fa {
    color: #560d19; }

.fc-orange-light {
  color: #ffcc26; }
  .fc-orange-light.a, .fc-orange-light p, .fc-orange-light h1, .fc-orange-light h2, .fc-orange-light h3, .fc-orange-light h4, .fc-orange-light .fa {
    color: #ffcc26; }

.fc-orange {
  color: #ffa826; }
  .fc-orange.a, .fc-orange p, .fc-orange h1, .fc-orange h2, .fc-orange h3, .fc-orange h4, .fc-orange .fa {
    color: #ffa826; }

.fc-orange-dark {
  color: #fc9700; }
  .fc-orange-dark.a, .fc-orange-dark p, .fc-orange-dark h1, .fc-orange-dark h2, .fc-orange-dark h3, .fc-orange-dark h4, .fc-orange-dark .fa {
    color: #fc9700; }

.fc-orange-darker {
  color: #d37f00; }
  .fc-orange-darker.a, .fc-orange-darker p, .fc-orange-darker h1, .fc-orange-darker h2, .fc-orange-darker h3, .fc-orange-darker h4, .fc-orange-darker .fa {
    color: #d37f00; }

.fc-blue-cyan {
  color: #187b8e; }
  .fc-blue-cyan.a, .fc-blue-cyan p, .fc-blue-cyan h1, .fc-blue-cyan h2, .fc-blue-cyan h3, .fc-blue-cyan h4, .fc-blue-cyan .fa {
    color: #187b8e; }

.fs-6 {
  font-size: 6px; }

.fs-7 {
  font-size: 7px; }

.fs-8 {
  font-size: 8px; }

.fs-9 {
  font-size: 9px; }

.fs-10 {
  font-size: 10px; }

.fs-11 {
  font-size: 11px; }

.fs-12 {
  font-size: 12px; }

.fs-13 {
  font-size: 13px; }

.fs-14 {
  font-size: 14px; }

.fs-15 {
  font-size: 15px; }

.fs-16 {
  font-size: 16px; }

.fs-18 {
  font-size: 18px; }

.fs-20 {
  font-size: 20px; }

.fs-22 {
  font-size: 22px; }

.fs-24 {
  font-size: 24px; }

.fs-26 {
  font-size: 26px; }

.fs-28 {
  font-size: 28px; }

.fs-30 {
  font-size: 30px; }

.fs-36 {
  font-size: 36px; }

.fs-48 {
  font-size: 48px; }

.fs-72 {
  font-size: 72px; }

.lh-5 {
  line-height: 0.5; }

.lh-6 {
  line-height: 0.6; }

.lh-7 {
  line-height: 0.7; }

.lh-8 {
  line-height: 0.8; }

.lh-9 {
  line-height: 0.9; }

.lh-10 {
  line-height: 1; }

.lh-11 {
  line-height: 1.1; }

.lh-12 {
  line-height: 1.2; }

.lh-13 {
  line-height: 1.3; }

.lh-14 {
  line-height: 1.4; }

.lh-15 {
  line-height: 1.5; }

.lh-16 {
  line-height: 1.6; }

.lh-17 {
  line-height: 1.7; }

.lh-18 {
  line-height: 1.8; }

.lh-19 {
  line-height: 1.9; }

.lh-20 {
  line-height: 2; }

.fa-left {
  text-align: left; }

.fa-center {
  text-align: center; }

.fa-right {
  text-align: right; }

a.no-underline:hover,
a.no-underline {
  text-decoration: none; }

.bo-radius-0 {
  border: 0 solid;
  border-radius: 0px; }

.bo-radius-2 {
  border: 0 solid;
  border-radius: 2px; }

.bo-radius-4 {
  border: 0 solid;
  border-radius: 4px; }

.bo-radius-5 {
  border: 0 solid;
  border-radius: 5px; }

.bo-radius-10 {
  border: 0 solid;
  border-radius: 10px; }

.bo-radius-15 {
  border: 0 solid;
  border-radius: 15px; }

.bo-radius-20 {
  border: 0 solid;
  border-radius: 20px; }

.bo-radius-30 {
  border: 0 solid;
  border-radius: 30px; }

.bo-radius-25 p {
  border: 0 solid;
  border-radius: 25%; }

.bo-radius-50 p {
  border: 0 solid;
  border-radius: 50%; }

.bo-1 {
  border: 1px solid #b31b34;
  padding: 20px; }

.bo-2 {
  border: 2px solid #b31b34;
  padding: 20px; }

.bo-bottom-1 {
  border-bottom: 1px solid #b31b34;
  padding: 20px; }

.bo-bottom-2 {
  border-bottom: 2px solid #b31b34;
  padding: 20px; }

.bo-bottom-grey-lightest {
  border-bottom-color: #eee; }

.bo-bottom-grey-light {
  border-bottom-color: #e2e3e6; }

.bo-bottom-grey {
  border-bottom-color: #a8adb4; }

.bo-bottom-blue {
  border-bottom-color: #144382; }

.bo-bottom-red {
  border-bottom-color: #b31b34; }

.bo-bottom-cyan {
  border-bottom-color: #1bb39a; }

.bo-bottom-orange {
  border-bottom-color: #ffa826; }

.bo-bottom-none {
  border-bottom-style: none; }

.bo-bottom-solid {
  border-bottom-style: solid; }

.bo-bottom-dotted {
  border-bottom-style: dotted; }

.bo-bottom-dashed {
  border-bottom-style: dashed; }

.bo-bottom-hidden {
  border-bottom-style: hidden; }

.bo-top-1 {
  border-top: 1px solid #b31b34;
  padding: 20px; }

.bo-top-2 {
  border-top: 2px solid #b31b34;
  padding: 20px; }

.bo-top-grey-lightest {
  border-top-color: #eee; }

.bo-top-grey-light {
  border-top-color: #e2e3e6; }

.bo-top-grey {
  border-top-color: #a8adb4; }

.bo-top-blue {
  border-top-color: #144382; }

.bo-top-red {
  border-top-color: #b31b34; }

.bo-top-cyan {
  border-top-color: #1bb39a; }

.bo-top-orange {
  border-top-color: #ffa826; }

.bo-top-none {
  border-top-style: none; }

.bo-top-solid {
  border-top-style: solid; }

.bo-top-dotted {
  border-top-style: dotted; }

.bo-top-dashed {
  border-top-style: dashed; }

.bo-top-hidden {
  border-top-style: hidden; }

.bo-left-1 {
  border-left: 1px solid #b31b34;
  padding: 20px; }

.bo-left-2 {
  border-left: 2px solid #b31b34;
  padding: 20px; }

.bo-left-grey-lightest {
  border-left-color: #eee; }

.bo-left-grey-light {
  border-left-color: #e2e3e6; }

.bo-left-grey {
  border-left-color: #a8adb4; }

.bo-left-blue {
  border-left-color: #144382; }

.bo-left-red {
  border-left-color: #b31b34; }

.bo-left-cyan {
  border-left-color: #1bb39a; }

.bo-left-orange {
  border-left-color: #ffa826; }

.bo-left-none {
  border-left-style: none; }

.bo-left-solid {
  border-left-style: solid; }

.bo-left-dotted {
  border-left-style: dotted; }

.bo-left-dashed {
  border-left-style: dashed; }

.bo-left-hidden {
  border-left-style: hidden; }

.bo-right-1 {
  border-right: 1px solid #b31b34;
  padding: 20px; }

.bo-right-2 {
  border-right: 2px solid #b31b34;
  padding: 20px; }

.bo-right-grey-lightest {
  border-right-color: #eee; }

.bo-right-grey-light {
  border-right-color: #e2e3e6; }

.bo-right-grey {
  border-right-color: #a8adb4; }

.bo-right-blue {
  border-right-color: #144382; }

.bo-right-red {
  border-right-color: #b31b34; }

.bo-right-cyan {
  border-right-color: #1bb39a; }

.bo-right-orange {
  border-right-color: #ffa826; }

.bo-right-none {
  border-right-style: none; }

.bo-right-solid {
  border-right-style: solid; }

.bo-right-dotted {
  border-right-style: dotted; }

.bo-right-dashed {
  border-right-style: dashed; }

.bo-right-hidden {
  border-right-style: hidden; }

.bo-grey-lightest {
  border-color: #eee; }

.bo-grey-light {
  border-color: #e2e3e6; }

.bo-grey {
  border-color: #a8adb4; }

.bo-blue {
  border-color: #144382; }

.bo-red {
  border-color: #b31b34; }

.bo-cyan {
  border-color: #1bb39a; }

.bo-orange {
  border-color: #ffa826; }

.bo-none {
  border-style: none; }

.bo-solid {
  border-style: solid; }

.bo-dotted {
  border-style: dotted; }

.bo-dashed {
  border-style: dashed; }

.bo-hidden {
  border-style: hidden; }

.badge {
  border-radius: 4px !important; }

span.badge {
  color: #fff;
  font-weight: 400;
  padding: 4px 7px; }

span.badge-white {
  color: #555;
  background: #fff; }

span.badge-grey-lighter {
  color: #555;
  background: #f7f8f8; }

span.badge-grey-light {
  color: #555;
  background: #e2e3e6; }

span.badge-grey {
  color: #fff;
  background: #a8adb4; }

span.badge-grey-dark {
  color: #fff;
  background: #7f8691; }

span.badge-grey-darker {
  color: #fff;
  background: #5a6069; }

span.badge-black {
  color: #fff;
  background: #000; }

span.badge-blue-light {
  color: #fff;
  background: #2197db; }

span.badge-blue {
  color: #fff;
  background: #144382; }

span.badge-blue-dark {
  color: #fff;
  background: #0d2c56; }

span.badge-blue-darker {
  color: #fff;
  background: #0a2140; }

span.badge-cyan {
  color: #fff;
  background: #1bb39a; }

span.badge-red-light {
  color: #fff;
  background: #ff264a; }

span.badge-red {
  color: #fff;
  background: #b31b34; }

span.badge-red-dark {
  color: #fff;
  background: #8b1528; }

span.badge-red-darker {
  color: #fff;
  background: #560d19; }

span.badge-orange-light {
  color: #555;
  background: #ffcc26; }

span.badge-orange {
  color: #fff;
  background: #ffa826; }

span.badge-orange-dark {
  color: #fff;
  background: #fc9700; }

span.badge-orange-darker {
  color: #fff;
  background: #d37f00; }

span.badge-blue-cyan {
  color: #fff;
  background: #187b8e; }

.disp-i {
  display: inline; }

.disp-ib {
  display: inline-block; }

.disp-b {
  display: block; }

.pos-abs {
  position: absolute; }

.pos-rel {
  position: relative; }

.pos-stat {
  position: static; }

.va-middle {
  vertical-align: middle; }

.ap-shadow {
  box-shadow: 0 10px 6px -6px #bbb;
  -moz-box-shadow: 0 10px 6px -6px #bbb;
  -webkit-box-shadow: 0 10px 6px -6px #bbb; }

.p-0 {
  padding: 0px; }

.pv-0 {
  padding-top: 0px;
  padding-bottom: 0px; }

.ph-0 {
  padding-left: 0px;
  padding-right: 0px; }

.pl-0 {
  padding-left: 0px; }

.pr-0 {
  padding-right: 0px; }

.pt-0 {
  padding-top: 0px; }

.pb-0 {
  padding-bottom: 0px; }

.m-0 {
  margin: 0px; }

.mv-0 {
  margin-top: 0px;
  margin-bottom: 0px; }

.mh-0 {
  margin-left: 0px;
  margin-right: 0px; }

.ml-0 {
  margin-left: 0px; }

.mr-0 {
  margin-right: 0px; }

.mt-0 {
  margin-top: 0px; }

.mb-0 {
  margin-bottom: 0px; }

.p-1 {
  padding: 1px; }

.pv-1 {
  padding-top: 1px;
  padding-bottom: 1px; }

.ph-1 {
  padding-left: 1px;
  padding-right: 1px; }

.pl-1 {
  padding-left: 1px; }

.pr-1 {
  padding-right: 1px; }

.pt-1 {
  padding-top: 1px; }

.pb-1 {
  padding-bottom: 1px; }

.m-1 {
  margin: 1px; }

.mv-1 {
  margin-top: 1px;
  margin-bottom: 1px; }

.mh-1 {
  margin-left: 1px;
  margin-right: 1px; }

.ml-1 {
  margin-left: 1px; }

.mr-1 {
  margin-right: 1px; }

.mt-1 {
  margin-top: 1px; }

.mb-1 {
  margin-bottom: 1px; }

.p-2 {
  padding: 2px; }

.pv-2 {
  padding-top: 2px;
  padding-bottom: 2px; }

.ph-2 {
  padding-left: 2px;
  padding-right: 2px; }

.pl-2 {
  padding-left: 2px; }

.pr-2 {
  padding-right: 2px; }

.pt-2 {
  padding-top: 2px; }

.pb-2 {
  padding-bottom: 2px; }

.m-2 {
  margin: 2px; }

.mv-2 {
  margin-top: 2px;
  margin-bottom: 2px; }

.mh-2 {
  margin-left: 2px;
  margin-right: 2px; }

.ml-2 {
  margin-left: 2px; }

.mr-2 {
  margin-right: 2px; }

.mt-2 {
  margin-top: 2px; }

.mb-2 {
  margin-bottom: 2px; }

.p-3 {
  padding: 3px; }

.pv-3 {
  padding-top: 3px;
  padding-bottom: 3px; }

.ph-3 {
  padding-left: 3px;
  padding-right: 3px; }

.pl-3 {
  padding-left: 3px; }

.pr-3 {
  padding-right: 3px; }

.pt-3 {
  padding-top: 3px; }

.pb-3 {
  padding-bottom: 3px; }

.m-3 {
  margin: 3px; }

.mv-3 {
  margin-top: 3px;
  margin-bottom: 3px; }

.mh-3 {
  margin-left: 3px;
  margin-right: 3px; }

.ml-3 {
  margin-left: 3px; }

.mr-3 {
  margin-right: 3px; }

.mt-3 {
  margin-top: 3px; }

.mb-3 {
  margin-bottom: 3px; }

.p-4 {
  padding: 4px; }

.pv-4 {
  padding-top: 4px;
  padding-bottom: 4px; }

.ph-4 {
  padding-left: 4px;
  padding-right: 4px; }

.pl-4 {
  padding-left: 4px; }

.pr-4 {
  padding-right: 4px; }

.pt-4 {
  padding-top: 4px; }

.pb-4 {
  padding-bottom: 4px; }

.m-4 {
  margin: 4px; }

.mv-4 {
  margin-top: 4px;
  margin-bottom: 4px; }

.mh-4 {
  margin-left: 4px;
  margin-right: 4px; }

.ml-4 {
  margin-left: 4px; }

.mr-4 {
  margin-right: 4px; }

.mt-4 {
  margin-top: 4px; }

.mb-4 {
  margin-bottom: 4px; }

.p-5 {
  padding: 5px; }

.pv-5 {
  padding-top: 5px;
  padding-bottom: 5px; }

.ph-5 {
  padding-left: 5px;
  padding-right: 5px; }

.pl-5 {
  padding-left: 5px; }

.pr-5 {
  padding-right: 5px; }

.pt-5 {
  padding-top: 5px; }

.pb-5 {
  padding-bottom: 5px; }

.m-5 {
  margin: 5px; }

.mv-5 {
  margin-top: 5px;
  margin-bottom: 5px; }

.mh-5 {
  margin-left: 5px;
  margin-right: 5px; }

.ml-5 {
  margin-left: 5px; }

.mr-5 {
  margin-right: 5px; }

.mt-5 {
  margin-top: 5px; }

.mb-5 {
  margin-bottom: 5px; }

.p-10 {
  padding: 10px; }

.pv-10 {
  padding-top: 10px;
  padding-bottom: 10px; }

.ph-10 {
  padding-left: 10px;
  padding-right: 10px; }

.pl-10 {
  padding-left: 10px; }

.pr-10 {
  padding-right: 10px; }

.pt-10 {
  padding-top: 10px; }

.pb-10 {
  padding-bottom: 10px; }

.m-10 {
  margin: 10px; }

.mv-10 {
  margin-top: 10px;
  margin-bottom: 10px; }

.mh-10 {
  margin-left: 10px;
  margin-right: 10px; }

.ml-10 {
  margin-left: 10px; }

.mr-10 {
  margin-right: 10px; }

.mt-10 {
  margin-top: 10px; }

.mb-10 {
  margin-bottom: 10px; }

.p-15 {
  padding: 15px; }

.pv-15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.ph-15 {
  padding-left: 15px;
  padding-right: 15px; }

.pl-15 {
  padding-left: 15px; }

.pr-15 {
  padding-right: 15px; }

.pt-15 {
  padding-top: 15px; }

.pb-15 {
  padding-bottom: 15px; }

.m-15 {
  margin: 15px; }

.mv-15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mh-15 {
  margin-left: 15px;
  margin-right: 15px; }

.ml-15 {
  margin-left: 15px; }

.mr-15 {
  margin-right: 15px; }

.mt-15 {
  margin-top: 15px; }

.mb-15 {
  margin-bottom: 15px; }

.p-20 {
  padding: 20px; }

.pv-20 {
  padding-top: 20px;
  padding-bottom: 20px; }

.ph-20 {
  padding-left: 20px;
  padding-right: 20px; }

.pl-20 {
  padding-left: 20px; }

.pr-20 {
  padding-right: 20px; }

.pt-20 {
  padding-top: 20px; }

.pb-20 {
  padding-bottom: 20px; }

.m-20 {
  margin: 20px; }

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

.mh-20 {
  margin-left: 20px;
  margin-right: 20px; }

.ml-20 {
  margin-left: 20px; }

.mr-20 {
  margin-right: 20px; }

.mt-20 {
  margin-top: 20px; }

.mb-20 {
  margin-bottom: 20px; }

.p-25 {
  padding: 25px; }

.pv-25 {
  padding-top: 25px;
  padding-bottom: 25px; }

.ph-25 {
  padding-left: 25px;
  padding-right: 25px; }

.pl-25 {
  padding-left: 25px; }

.pr-25 {
  padding-right: 25px; }

.pt-25 {
  padding-top: 25px; }

.pb-25 {
  padding-bottom: 25px; }

.m-25 {
  margin: 25px; }

.mv-25 {
  margin-top: 25px;
  margin-bottom: 25px; }

.mh-25 {
  margin-left: 25px;
  margin-right: 25px; }

.ml-25 {
  margin-left: 25px; }

.mr-25 {
  margin-right: 25px; }

.mt-25 {
  margin-top: 25px; }

.mb-25 {
  margin-bottom: 25px; }

.p-30 {
  padding: 30px; }

.pv-30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.ph-30 {
  padding-left: 30px;
  padding-right: 30px; }

.pl-30 {
  padding-left: 30px; }

.pr-30 {
  padding-right: 30px; }

.pt-30 {
  padding-top: 30px; }

.pb-30 {
  padding-bottom: 30px; }

.m-30 {
  margin: 30px; }

.mv-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mh-30 {
  margin-left: 30px;
  margin-right: 30px; }

.ml-30 {
  margin-left: 30px; }

.mr-30 {
  margin-right: 30px; }

.mt-30 {
  margin-top: 30px; }

.mb-30 {
  margin-bottom: 30px; }

.p-40 {
  padding: 40px; }

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.ph-40 {
  padding-left: 40px;
  padding-right: 40px; }

.pl-40 {
  padding-left: 40px; }

.pr-40 {
  padding-right: 40px; }

.pt-40 {
  padding-top: 40px; }

.pb-40 {
  padding-bottom: 40px; }

.m-40 {
  margin: 40px; }

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px; }

.mh-40 {
  margin-left: 40px;
  margin-right: 40px; }

.ml-40 {
  margin-left: 40px; }

.mr-40 {
  margin-right: 40px; }

.mt-40 {
  margin-top: 40px; }

.mb-40 {
  margin-bottom: 40px; }

.p-50 {
  padding: 50px; }

.pv-50 {
  padding-top: 50px;
  padding-bottom: 50px; }

.ph-50 {
  padding-left: 50px;
  padding-right: 50px; }

.pl-50 {
  padding-left: 50px; }

.pr-50 {
  padding-right: 50px; }

.pt-50 {
  padding-top: 50px; }

.pb-50 {
  padding-bottom: 50px; }

.m-50 {
  margin: 50px; }

.mv-50 {
  margin-top: 50px;
  margin-bottom: 50px; }

.mh-50 {
  margin-left: 50px;
  margin-right: 50px; }

.ml-50 {
  margin-left: 50px; }

.mr-50 {
  margin-right: 50px; }

.mt-50 {
  margin-top: 50px; }

.mb-50 {
  margin-bottom: 50px; }

.p-75 {
  padding: 75px; }

.pv-75 {
  padding-top: 75px;
  padding-bottom: 75px; }

.ph-75 {
  padding-left: 75px;
  padding-right: 75px; }

.pl-75 {
  padding-left: 75px; }

.pr-75 {
  padding-right: 75px; }

.pt-75 {
  padding-top: 75px; }

.pb-75 {
  padding-bottom: 75px; }

.m-75 {
  margin: 75px; }

.mv-75 {
  margin-top: 75px;
  margin-bottom: 75px; }

.mh-75 {
  margin-left: 75px;
  margin-right: 75px; }

.ml-75 {
  margin-left: 75px; }

.mr-75 {
  margin-right: 75px; }

.mt-75 {
  margin-top: 75px; }

.mb-75 {
  margin-bottom: 75px; }

.p-100 {
  padding: 100px; }

.pv-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.ph-100 {
  padding-left: 100px;
  padding-right: 100px; }

.pl-100 {
  padding-left: 100px; }

.pr-100 {
  padding-right: 100px; }

.pt-100 {
  padding-top: 100px; }

.pb-100 {
  padding-bottom: 100px; }

.m-100 {
  margin: 100px; }

.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px; }

.mh-100 {
  margin-left: 100px;
  margin-right: 100px; }

.ml-100 {
  margin-left: 100px; }

.mr-100 {
  margin-right: 100px; }

.mt-100 {
  margin-top: 100px; }

.mb-100 {
  margin-bottom: 100px; }

.p-500 {
  padding: 500px; }

.pv-500 {
  padding-top: 500px;
  padding-bottom: 500px; }

.ph-500 {
  padding-left: 500px;
  padding-right: 500px; }

.pl-500 {
  padding-left: 500px; }

.pr-500 {
  padding-right: 500px; }

.pt-500 {
  padding-top: 500px; }

.pb-500 {
  padding-bottom: 500px; }

.m-500 {
  margin: 500px; }

.mv-500 {
  margin-top: 500px;
  margin-bottom: 500px; }

.mh-500 {
  margin-left: 500px;
  margin-right: 500px; }

.ml-500 {
  margin-left: 500px; }

.mr-500 {
  margin-right: 500px; }

.mt-500 {
  margin-top: 500px; }

.mb-500 {
  margin-bottom: 500px; }

.clearfix:after {
  content: '';
  display: table;
  clear: both; }

/*# sourceMappingURL=style-red.css.map */
